import React, { useContext, useEffect, useState } from 'react';
import {
    ButtonBase,
    Dialog,
    makeStyles,
    Typography,
    SwipeableDrawerProps,
} from '@material-ui/core';
import clsx from 'clsx';
import { eventMetricsMap, useEventsReporter, useRouter } from 'src/hooks';
import COLORS from 'src/lib/colors';
import ShippingInfoContext from 'src/lib/contexts/shipping-info-context';
import ROUTES from 'src/lib/routes';
import { addShipmentTracking } from 'src/lib/api-client/shipment-services';
import { Body, Button, Heading, SwipeableDrawer } from '../../UI';
import { IconCornerSplash, IconScanShipping } from '../../Icons';
import styles from './styles.module.scss';
import Brcd from '../../BarcodeScanner/Brcd';
import LoadingOverlay from '../../loading-overlay';

const useStyles = makeStyles(() => ({
    linkCta: {
        fontWeight: 500,
        marginTop: '15px',
        color: '#086DD2',
        fontSize: '14px',
        lineHeight: '22px',
        marginBottom: '32px',
    },
    startShipment: {
        height: '40px',
        width: '100%',
        borderRadius: '20px',
        background: '#CC0000',
        marginBottom: '10px',
        '& span': {
            fontSize: '14px',
            fontWeight: 'normal',
            lineHeight: '17px',
            color: '#FFFFFF',
            textAlign: 'center',
        },
        '&:hover': {
            opacity: 0.8,
        },
    },
    paper: { borderRadius: '15px', margin: '24px', padding: '10px 0 0 0' },
    dialogTitle: {
        fontSize: '18px',
        color: '#000',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '-0.2px',
        margin: '10px 0 10px 0px',
    },
    dialogWrapper: {
        padding: '10px 20px 30px 30px',
        textAlign: 'center',
        color: COLORS.secondaryNeutralBlack,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    dialogMessage: {
        marginBottom: '30px',
        fontSize: '14px',
        fontWeight: 400,
    },
    closeIcon: {
        position: 'absolute',
        top: '-3px',
        right: '10px',
        backgroundColor: 'rgba(0,0,0,0)',
        minWidth: 'initial',
        padding: '0px',
    },
    closeButton: {
        position: 'relative',
        textAlign: 'center',
    },
    anotherPackage: {
        margin: '0px',
    },
    successIcon: {
        width: '50px',
        marginBottom: '5px',
    },
    'MuiSwipeableDrawer-root': {
        position: 'relative',
    },
}));

function ShippingRewardsInfoDialog(props: SwipeableDrawerProps) {
    const classes = useStyles();
    const [isBarcodeScannerOpen, setIsBarcodeScannerOpen] = useState(false);
    const [scannerData, setScannerData] = useState('');
    const [scanMessage, setScanMessage] = useState({
        title: 'Scan successful!',
        description:
            'Visit Labels and tracking to view its current ' +
            'status or scan another package.',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [trackingError, setTrackingError] = useState(false);
    const [isScanCompleted, setIsScanCompleted] = useState(false);

    const { push } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const {
        updateFetchSenderInfo,
        updateReviewStage,
        openScanToTrack,
        updateOpenScanToTrack,
        updateShippingTab,
    } = useContext(ShippingInfoContext);

    const handleClose = () => {
        setIsScanCompleted(false);
        setTrackingError(false);
        setScannerData('');
    };

    const handleSavedLabelShipment = (state: string) => {
        if (state) {
            updateShippingTab(state);
        }
        push(ROUTES.shippingServicesLabelShipment);
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'saved labels',
            element_location: 'main shipping page',
        });
    };

    const handleStartShipment = () => {
        updateFetchSenderInfo(true);
        updateReviewStage(false);
        push({
            pathname: ROUTES.createShippingInformation,
            search: '?step=createShipmentSenderInfo',
        });
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'start a shipment',
            element_location: 'main shipping page',
        });
    };

    const handleScan = () => {
        setIsBarcodeScannerOpen(true);
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'scan to track',
            element_location: 'main shipping page',
        });
    };

    const getShipmentTrackingDetails = async (trackingnumber: any) => {
        setIsLoading(true);
        const response = await addShipmentTracking(trackingnumber);
        setScannerData('');
        setIsLoading(false);
        if (response?.issuccess) {
            setTrackingError(true);
            if (response?.status === 'SUCCESS') {
                setTrackingError(false);
                setIsScanCompleted(true);
                setScanMessage({
                    title: 'Scan successful!',
                    description:
                        'Visit Labels and tracking to view its current ' +
                        'status or scan another package.',
                });
            } else if (response?.status === 'Fail') {
                setScanMessage({
                    title: 'Your package couldn’t be found',
                    description:
                        'We couldn’t find your package at this time. Please try again later.',
                });
            } else if (response?.status === 'DUPLICATE_TRACKING') {
                setScanMessage({
                    title: 'Duplicate label',
                    description:
                        'This label has been previously scanned ' +
                        'and is already in the system.',
                });
            } else if (response?.status === 'INVALID_TRACKING') {
                setScanMessage({
                    title: `Your package couldn't be found`,
                    description:
                        'Make sure you have a UPS label and ' +
                        'shipment number to start tracking.',
                });
            } else if (response?.status === 'NONUS_TRACKING') {
                setScanMessage({
                    title: 'Want to ship internationally?',
                    description:
                        'Bring your package to your local Staples store ' +
                        'and we’ll ship it anywhere in the world.',
                });
            } else {
                setScanMessage({
                    title: 'Label couldn’t be scanned',
                    description:
                        'We couldn’t scan your label at this time. Please try again later.',
                });
            }
            setIsScanCompleted(true);
            setScannerData('');
        } else {
            setTrackingError(true);
            setIsScanCompleted(true);
            setScanMessage({
                title: 'Your package couldn’t be found',
                description:
                    'We couldn’t find your package at this time. Please try again later.',
            });
        }
    };

    useEffect(() => {
        if (scannerData) {
            getShipmentTrackingDetails(scannerData);
        }
    }, [scannerData]);

    return (
        <>
            {isLoading && (
                <LoadingOverlay
                    variant="wordmark"
                    theme="transparent-light"
                    open={isLoading}
                />
            )}
            <SwipeableDrawer
                anchor="bottom"
                classes={{
                    root: classes['MuiSwipeableDrawer-root'],
                }}
                open={props.open}
                onClose={props.onClose}
                onOpen={props.onOpen}
                drawerAdornment={
                    <span className={styles.drawerAdornment}>
                        <IconCornerSplash height={59} width={91} />
                    </span>
                }
            >
                <div className={styles.content}>
                    <div>
                        <Heading
                            type="h1"
                            color="textPurple"
                            size={32}
                            weight="bold"
                            textAlign="textCenter"
                        >
                            50 points
                        </Heading>
                        <Body size={16}>when you ship a package</Body>
                    </div>
                    <Button
                        bgColor="bgBlack"
                        color="textWhite"
                        variant="contained-rounded"
                        onClick={handleStartShipment}
                    >
                        Start a shipment
                    </Button>
                    <Button
                        variant="icon"
                        color="textLinkBlue"
                        onClick={handleScan}
                    >
                        <span className={styles.buttonContent}>
                            <IconScanShipping /> Scan to track
                        </span>
                    </Button>
                    <Body size={10} weight="light">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Voluptatum, earum itaque. Repudiandae debitis
                        numquam laborum. Odit aliquam architecto corporis labore
                        hic, excepturi similique, soluta dicta, laborum unde ea
                        in dolorum numquam veniam amet necessitatibus! Placeat
                        velit cumque mollitia minima alias, provident,
                        praesentium similique, quasi quia illo quibusdam
                        sapiente harum voluptates commodi neque reiciendis
                        cupiditate porro fuga laudantium quo maxime omnis.
                    </Body>
                </div>
            </SwipeableDrawer>
            {isBarcodeScannerOpen && (
                <Brcd
                    isScannerOpen={isBarcodeScannerOpen}
                    shippingScan
                    setScannerData={setScannerData}
                    onClose={() => setIsBarcodeScannerOpen(false)}
                />
            )}
            {isScanCompleted && (
                <Dialog
                    open={isScanCompleted}
                    classes={{
                        paper: classes.paper,
                    }}
                >
                    <div className={classes.closeButton}>
                        <Button
                            onClick={handleClose}
                            className={classes.closeIcon}
                            id="close-button"
                        >
                            <img
                                src={require('../../../assets/icons/iconCloseBlack.svg')}
                                alt="close icon"
                            />
                        </Button>
                    </div>
                    <div className={classes.dialogWrapper}>
                        <Typography
                            variant="h3"
                            className={classes.dialogTitle}
                        >
                            {scanMessage.title}
                        </Typography>
                        {!trackingError && (
                            <img
                                className={classes.successIcon}
                                src={require('../../../assets/icons/icon_success.svg')}
                                alt="scan successful"
                            />
                        )}

                        <Typography className={classes.dialogMessage}>
                            {scanMessage.description}
                        </Typography>
                        <ButtonBase
                            className={classes.startShipment}
                            onClick={() => handleSavedLabelShipment('shipped')}
                        >
                            <span>Go to labels and tracking</span>
                        </ButtonBase>

                        <ButtonBase
                            className={clsx(
                                classes.linkCta,
                                classes.anotherPackage
                            )}
                            onClick={handleScan}
                        >
                            <span>Scan another package </span>
                        </ButtonBase>
                    </div>
                </Dialog>
            )}
        </>
    );
}

export default ShippingRewardsInfoDialog;
