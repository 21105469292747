import { ButtonBase, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import FullWidthCard from 'src/components/cards/full-width-card';
import { IconCornerSplash } from 'src/components/Icons';
import styles from './style.module.scss';
import ShippingRewardsInfoDialog from '../ShippingRewardsInfoDialog';
import chevronBlue from '../../../assets/icons/iconChevronBlue.svg';

function ShippingRewards({ noMargin }: { noMargin?: boolean }) {
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const handleOpen = () => {
        setOpenInfoDialog(true);
    };

    const handleClose = () => {
        setOpenInfoDialog(false);
    };

    return (
        <>
            <FullWidthCard
                noPadding
                hasManualShadow={false}
                id="shipping-rewards-container"
                className={
                    !noMargin
                        ? styles.rewardingShipping
                        : styles.rewardingShippingFullWidth
                }
                elevation={0}
                onClick={handleOpen}
            >
                <div className={styles.cardHeader}>
                    <div className={styles.tileAdorment} aria-hidden="true">
                        <IconCornerSplash width={50} />
                    </div>

                    <div className={styles.description}>
                        <div className={styles.helperTextDsc}>
                            <h2 className={styles.pointsHighlighted}>
                                50 points
                            </h2>

                            <Typography>when you ship a package.</Typography>
                        </div>
                        <ButtonBase
                            id="shipping_rewards_learn-more"
                            className={styles.learnMore}
                        >
                            Learn more{' '}
                            <img
                                aria-label="Learn More"
                                aria-hidden="true"
                                src={chevronBlue}
                                alt="learn more icon"
                            />
                        </ButtonBase>
                    </div>
                </div>
            </FullWidthCard>
            <ShippingRewardsInfoDialog
                onClose={handleClose}
                onOpen={handleOpen}
                open={openInfoDialog}
            />
        </>
    );
}

export default ShippingRewards;
