/* eslint-disable max-len */
/* eslint-disable  import/no-cycle */
import axios, { AxiosInstance } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import {
    SessionManager, callApi, getUserToken, useRefreshToken,
    getMinutes, COMMON_HEADERS, axiosInstance,
} from './sessionManager';
import { akamaiTelemetryHeader } from '../utils/app-utils';


const createUserInstance: AxiosInstance = axios.create({
    headers: {
        // always add the api key (needed for API Management)
        'ocp-apim-subscription-key': process.env.REACT_APP_PUBLIC_API_KEY || '',
        'content-type': 'application/json',
        'Cache-Control': 'no-cache',
    },
});

const checkUserMigratedInstance: AxiosInstance = axios.create({
    headers: {
        // always add the api key (needed for API Management)
        'ocp-apim-subscription-key': process.env.REACT_APP_IS_USERMIGRATED_API_KEY || '',
        'content-type': 'application/json',
        'Cache-Control': 'no-cache',
    },
})



/**
 * Attempt to add the token stored in localstorage to all axios requests.
 * Adjust this method we ever chsange our storage policy for our AuthService
 */


interface ILogin {
    "access_token": string;

    "token_type": string;

    "expires_in": string;

    "refresh_token": string;

    "refresh_token_expires_in": string;

    "refresh_token_issued_at": number;

    "refresh_token_status": string;

    sub: string;

    tenant: string;

    "access_token_issued_at"?: number;

    msg?: string;

    userName?: string;

    nudataPayload?: string;
    captchaResponse: {
        captchaResponseHtml: string;
    }

    "ssoToken": string;

}

/* eslint camelcase: ["error", {"properties": "never", ignoreDestructuring: true}] */
export const getSubscriptionKey = async (id: string): Promise<any> => {
    const getSubscriptionKeyInstance: AxiosInstance = axios.create({
        headers: {
            // always add the api key (needed for API Management)
            'ocp-apim-subscription-key': process.env.REACT_APP_IS_USERMIGRATED_API_KEY || '',
            'content-type': 'application/json',
            'Cache-Control': 'no-cache',
        },
    })
    const res = await getSubscriptionKeyInstance.get(
        `${process.env.REACT_APP_GET_SUBSCRIPTION_URL?.replace(
            '{id}',
            id
        )}`
    );
    if (res.data?.subscriptionKey) {
        return res.data?.subscriptionKey;
    }
    return null
}


const checkIsUserMigrated = async (userName: string) => {
    if (!process.env.REACT_APP_CHECK_MIGRATED) {
        return { status: false }
    }

    try {
        const URL = `${process.env.REACT_APP_CHECK_MIGRATED?.replace(
            '{migrationemail}',
            userName
        )}`
        const { status, data } = await checkUserMigratedInstance.get(URL);
        if (status !== 200) {
            return { status: false }
        }
        let isMigrated = false;
        let message = '';

        switch (data.accountMigrateStatus) {
            case 'user_migrated':
                isMigrated = true;
                message = '';
                break;
            case 'user_not_migrated':
                isMigrated = false;
                message = 'User Not Migrated';
                break;
            case 'user_not_available':
                isMigrated = true;
                message = 'User Not Available';
                break;
            default:
                isMigrated = false;
                message = ''
        }
        return { status: true, isMigrated, message }
    } catch (error) {
        return { status: false }
    }

}


export async function loginUser({
    userName,
    key,
    rememberMe,
    payload,
    nuCaptchaAnswer,
    nuCaptchaToken,
    stplSessionId,
    access_token,
    fromCreateAccount = false }: any) {
    const subscriptionKey = await getSubscriptionKey("StaplesIDP");
    if (!subscriptionKey) {
        return console.log('subscription key service failed');
    };
    if (!process.env.REACT_APP_LOGIN) {
        return false;
    }
    const data = {
        "username": userName,
        "password": key,
        "requestUrl": "app/sso/login",
        "stplSessionId": stplSessionId,
        "placement": "LoginWebSC",
        "ndsPageModeData": payload,
        "rememberMe": rememberMe,
        "answer": nuCaptchaAnswer,
        token: nuCaptchaToken,
        channel: 'SWeb',
    }
    axiosInstance.interceptors.request.use((config: any) => ({
        ...config,
        headers: {
            ...config.headers, ...COMMON_HEADERS,
            'client_id': subscriptionKey, // '0246ccc48a7c4e599347dda584d5d32d',
            'X-stpl-feature': 'SLP',
            ...akamaiTelemetryHeader,
        },
    }
    ))

    const userAlreadyMigrated = SessionManager?.isUserMigrated && (userName === SessionManager.userName);
    try {
        const instance: any = await axiosInstance.post<ILogin>(process.env.REACT_APP_LOGIN, data)
        if (!instance?.data?.access_token) {
            console.log(instance.data?.msg);
            const captchaInRes = instance.data?.captchaResponse?.captchaResponseHtml;
            if (captchaInRes) {
                if (fromCreateAccount) {
                    return { status: false, data: instance.data, captchaRes: true };
                }
                return { status: false, data: instance.data };
            }
            if (rememberMe && !userAlreadyMigrated) {
                const { status, isMigrated, message } = await checkIsUserMigrated(userName);
                if (status && !isMigrated) {
                    // route to new screen
                    SessionManager.setIsUserMigrated({ isUserMigrated: false, userName });
                    return { status: false, message }
                }
                if (!status) {
                    return { status: false, message }
                }
            }
            return { status: false, message: instance.data?.msg };
        }
        const loginInstance = instance.data;
        if (!loginInstance) {
            return { status: false, data: instance.data };
        }
        if (!instance?.data?.captchaResponse?.captchaResponseHtml) {
            SessionManager.setIsUserMigrated({ isUserMigrated: true, userName });
            loginInstance.userName = userName;
            loginInstance.access_token_issued_at = new Date().valueOf();
            // setLoginDetails(loginInstance);
            SessionManager.setTokens(loginInstance);
        }

        const userTokenResponse = await getUserToken();
        Cookies.set('SMSESSION', loginInstance?.ssoToken,
            { domain: process.env.REACT_APP_Domain });
        Cookies.set('SOMNIAPP', 'Y',
            {
                domain: process.env.REACT_APP_Domain,
                expires: 54 * 60 * 60 * 24 * 7,
            });
        if (!userTokenResponse.status) {
            return { status: false, data: instance?.data };
        }
        SessionManager.isLoggedIn = true;
        // if refresh token expires, refresh rmTokem
        useRefreshToken(getMinutes(+loginInstance.expires_in) - 2, new Date().valueOf())
        console.log("SignOn component");
        return { status: true, data: instance?.data };
    }
    catch (error: any) {
        if (rememberMe && !userAlreadyMigrated) {
            const { status, isMigrated, message } = await checkIsUserMigrated(userName);
            if (status && !isMigrated) {
                // route to new screen
                SessionManager.setIsUserMigrated({ isUserMigrated: false, userName });
                return { status: false, message, error }
            }
            if (!status) {
                return { status: false, message, error }
            }
        }
        return { status: false, message: error?.response?.data?.msg, error };
    }
}

export async function createAccount(
    data: any,
    setLoading: any
) {
    const payload = (document?.getElementsByName('nds-pmd')[0] as HTMLInputElement)?.value;
    const nuCaptchaAnswer = (document.getElementById('nucaptcha-answer') as HTMLInputElement) ?
        (document.getElementById('nucaptcha-answer') as HTMLInputElement).value : '';
    if (!process.env.REACT_APP_CREATE_ACCOUNT) {
        setLoading(false)
        return { status: false, message: null };
    }
    const subscriptionKey = await getSubscriptionKey("StaplesIDP");
    if (!subscriptionKey) {
        return console.log('subscription key service failed');
    };
    try {
        createUserInstance.interceptors.request.use((config: any) => ({
            ...config,
            headers: {
                ...config.headers, ...COMMON_HEADERS,
                'client_id': subscriptionKey,
                'X-stpl-feature': 'SLP',
                ...akamaiTelemetryHeader,
            },
        }))
        const res = await createUserInstance.post(
            process.env.REACT_APP_CREATE_ACCOUNT, data
        );
        if (res?.data.res === "user created") {
            const loginData: any = await loginUser({
                userName: data.user.username,
                key: data.password,
                rememberMe: true,
                channel: 'SWeb',
                placement: "LoginWebSC",
                requestUrl: "app/sso/login",
                stplSessionId: uuidv4(),
                ndsPageModeData: payload,
                answer: nuCaptchaAnswer,
                fromCreateAccount: true,
            });
            setLoading(false)
            localStorage.setItem('newUser', 'true')
            const coachmarksData = { completed: false, screen: 0};
            localStorage.setItem('coachmarks', JSON.stringify(coachmarksData))
            if (loginData.captchaRes) {
                return { message: res.data.res, status: true, data: res?.data, loginRes: loginData.data };
            }
            return { message: res.data.res, status: true, data: res?.data };
        }
        return { status: false, data: res?.data };
    } catch (error: any) {
        setLoading(false)
        return { status: false, message: error?.response?.data?.resp?.error?.erroritems?.[0]?.message };
    }
}