import React from 'react';
import IconInterface from './IconInterface';


function IconClose({ width = 16, height = 16 }: IconInterface) {
    return (
        <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.15976 0.159696C0.372773 -0.0533135 0.718136 -0.0533135 0.931149 0.159696L6 5.22822L11.0688 0.159696C11.2625 -0.033949 11.5655 -0.051553 11.7791 0.106884L11.8402 0.159696C12.0533 0.372707 12.0533 0.718064 11.8402 0.931074L6.77164 5.99985L11.8402 11.0686C12.0339 11.2623 12.0515 11.5653 11.8931 11.7788L11.8402 11.84C11.6272 12.053 11.2819 12.053 11.0688 11.84L6 6.77147L0.931149 11.84C0.737501 12.0336 0.434473 12.0513 0.220937 11.8928L0.15976 11.84C-0.0532533 11.627 -0.0532533 11.2816 0.15976 11.0686L5.22836 5.99985L0.15976 0.931074C-0.0338885 0.737429 -0.0514928 0.434405 0.106947 0.220873L0.15976 0.159696Z" fill="white"/>
</svg>
    );
}

export default IconClose;