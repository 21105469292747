import React from 'react'
import { Box, makeStyles } from '@material-ui/core';
import ROUTES from 'src/lib/routes';
import { useRouter } from 'src/hooks';
import { Body } from './UI';

const useStyles = makeStyles(() => ({
    container:{
        display: 'flex', 
        alignItems: 'center', 
        margin: '0.8rem',
    },
}))

export default function StoreModeNotificationHeader() {
    const classes = useStyles();
    const{push} = useRouter();
    const handleStoreModeClose = () => {
        sessionStorage.setItem('storeModeDismissed', 'true');
          push(ROUTES.home);  
      };
    return (
        <Box className={classes.container} onClick={()=> handleStoreModeClose()}>
            <Box style={{display: 'flex', alignItems: 'center', marginRight: 'auto'}}>
                <img src={require("../assets/icons/icon_home.svg")} style={{marginRight: '1rem'}} width={38.4} height={40} alt="icon-home"/>
                <Box>
                    <Body size={13} weight='light' lineHeight={16}>You're browsing in store mode.</Body>
                    <Body size={13} weight='bold' lineHeight={16} color="textLinkBlue">Just tap to return (home).</Body>
                </Box>
            </Box> 
            <img src={require("../assets/icons/close-icon.svg")} width={14} height={14} alt="close-icon"/>
        </Box>
    )
}