import { createContext, useState, useContext, useRef } from 'react';

const CartCountContext = createContext({
  cartCount: 0,
  activeIframeId: '',
  isWebViewLoading: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateCartCount: (value: number) => { },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateIframeId: (value: string) => { },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateWebViewLoading: (value: boolean) => { },
});


export function useCartCountContextState() {
  const [cartCount, setCartCount] = useState(0);
  const [activeIframeId, setIframeId] = useState('');
  const [isWebViewLoading, setWebViewLoading] = useState(false);
  const updateCartCount = (value: number) => setCartCount(value);
  const updateIframeId = (value: string) => setIframeId(value);
  const updateWebViewLoading = (value: boolean) => setWebViewLoading(value);
  return { cartCount, activeIframeId, updateCartCount, updateIframeId, isWebViewLoading, updateWebViewLoading };
}

export function useCartCountContext() {
  return useContext(CartCountContext);
}

export default CartCountContext;