const eventMetricsEventsList = [
    'screen_view',
    'madePurchase',
    'customPageView',
    'associated_SRW',
    'giving_school_search',
    'favorited',
    'invite_educator_clicked',
    'educator_claimed_school',
    'admin_claimed_school',
    'educator_funds_recieved',
    'invite_supporter_clicked',
    'list_created',
    'list_imported',
    'added_product',
    'checked_off_product',
    'searched_product',
    'login_success',
    'home',
    'classroom_rewards',
    'lists_tapped',
    'give_tapped',
    'give_success',
    'give_close',
    'share_tapped',
    'change_location_tapped',
    'change_location_close',
    'search_complete',
    'store_set',
    'stores_tapped',
    'store_selected',
    'store_visit',
    'profile_tapped',
    'manage_profile',
    'PageView',
    'share_list_clicked',
    'share_list_saved',
    'share_list_discard',
    'share_list_opened',
    'wallet_tapped',
    'wallet_tophatnotification_tapped',
    'wallet_staplesrewards_toggleselected',
    'wallet_classroomrewards_toggleselected',
    'wallet_staplesrewards_joinnow',
    'wallet_classroomrewards_givebacknow',
    'wallet_staplesrewards_learnmore',
    'wallet_classroomrewards_learnmore',
    'wallet_classroomrewards_redeemclassroomrewards',
    'wallet_classroomrewards_givenow',
    'wallet_classroomrewards_schoolsearch',
    'wallet_classroomrewards_givesuccess',
    'wallet_tapcoupon',
    'wallet_swipecoupon',
    'Wallet_Hero_ViewAllDigitalCoupons',
    'Wallet_DigitalCoupons_toggleselected',
    'Wallet_CheckAvailableCoupons',
    'Wallet_Hero_ViewWeeklyAd',
    'save_reward',
    'recycle_planfirstvisit',
    'recycle_plannextvisit',
    'recycle_reward_learnmore',
    'recycle_impact_clickfordetail',
    'recycle_comm_learnmore',
    'recycle_rewards_getstarted',
    'store_nearby',
    'store_details',
    'recycle_signintoenroll',
    'eReceipt_tapped',
    'eReceipt_open',
    'eReceipt_buyagain',
    'eReceipt_addtolist',
    'navigation',
    'home',
    'top_hat',
    'wallet',
    'lists',
    'account_info',
    'recycling',
    'classroom_rewards_enroll',
    'product_lists',
    'product_details',
    'classroom_rewards',
    'product_search',
    'stores',
    'ereceipts',
    'outbound',
    'pop_up',
    'scan',
    'account_creation',
    'sign_in_page',
    'password_reset',
    'shipping',
    'start_shipment',
    'shipping_info',
    'scm_madePurchase',
    'scm_customPageView',
    'scm_associated_SRW',
    'scm_giving_school_search',
    'scm_favorited',
    'scm_invite_educator_clicked',
    'scm_educator_claimed_school',
    'scm_admin_claimed_school',
    'scm_educator_funds_recieved',
    'scm_invite_supporter_clicked',
    'scm_list_created',
    'scm_list_imported',
    'scm_added_product',
    'scm_checked_off_product',
    'scm_searched_product',
    'scm_login_success',
    'scm_home',
    'scm_classroom_rewards',
    'scm_lists_tapped',
    'scm_give_tapped',
    'scm_give_success',
    'scm_give_close',
    'scm_share_tapped',
    'scm_change_location_tapped',
    'scm_change_location_close',
    'scm_search_complete',
    'scm_store_set',
    'scm_stores_tapped',
    'scm_store_selected',
    'scm_profile_tapped',
    'scm_manage_profile',
    'scm_PageView',
    'scm_share_list_clicked',
    'scm_share_list_saved',
    'scm_share_list_discard',
    'scm_share_list_opened',
    'scm_wallet_tapped',
    'scm_wallet_tophatnotification_tapped',
    'scm_wallet_staplesrewards_toggleselected',
    'scm_wallet_classroomrewards_toggleselected',
    'scm_wallet_staplesrewards_joinnow',
    'scm_wallet_classroomrewards_givebacknow',
    'scm_wallet_staplesrewards_learnmore',
    'scm_wallet_classroomrewards_learnmore',
    'scm_wallet_classroomrewards_redeemclassroomrewards',
    'scm_wallet_classroomrewards_givenow',
    'scm_wallet_classroomrewards_schoolsearch',
    'scm_wallet_classroomrewards_givesuccess',
    'scm_wallet_tapcoupon',
    'scm_wallet_swipecoupon',
    'scm_Wallet_Hero_ViewAllDigitalCoupons',
    'scm_Wallet_DigitalCoupons_toggleselected',
    'scm_Wallet_CheckAvailableCoupons',
    'scm_Wallet_Hero_ViewWeeklyAd',
    'scm_save_reward',
    'scm_recycle_planfirstvisit',
    'scm_recycle_plannextvisit',
    'scm_recycle_reward_learnmore',
    'scm_recycle_impact_clickfordetail',
    'scm_recycle_comm_learnmore',
    'scm_recycle_rewards_getstarted',
    'scm_store_nearby',
    'scm_store_details',
    'scm_recycle_signintoenroll',
    'scm_eReceipt_tapped',
    'scm_eReceipt_open',
    'scm_eReceipt_buyagain',
    'scm_eReceipt_addtolist',
    'scm_navigation',
    'scm_home',
    'scm_top_hat',
    'scm_wallet',
    'scm_lists',
    'scm_account_info',
    'scm_recycling',
    'scm_classroom_rewards_enroll',
    'scm_product_lists',
    'scm_product_details',
    'scm_classroom_rewards',
    'scm_product_search',
    'scm_stores',
    'scm_ereceipts',
    'scm_outbound',
    'scm_pop_up',
    'scm_scan',
    'shop',
    'coupons & rewards',
    'stores',
    'account',
    'easy_rewards',
    'add_to_cart',
    'Purchase',
    'Page_view',
    'Config',
    'user_type',
    'shopping_location',
    'shopping_location_changed',
    'easy_rewards_enroll',
    'user_info',
    'login_flow',
    'sticky_notification_accept_terms',
    'app_update_modal',
    'coachmarks',
    'continue_as_guest',
    'qrcode',
    'login_search',
    'app_guest_checkout',
    'tech_services',
    'new_loyalty_member',
    'enable_notifications',
] as const;
export type eventMetricsEventsType = (typeof eventMetricsEventsList)[number];
type eventMetricsMapType = {
    [key in eventMetricsEventsType]: eventMetricsEventsType;
};
const eventMetricsMap: eventMetricsMapType = eventMetricsEventsList.reduce(
    (acc, el) => {
        acc[el] = el;
        return acc;
    },
    {} as eventMetricsMapType
);

export default eventMetricsMap;
