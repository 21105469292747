/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/aria-role */
import React, { useEffect } from 'react';
import {
    Box,
    makeStyles,
    ButtonBase,
    Badge,
    IconButton,
} from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import useStoreData from 'src/hooks/useStoreData';
import COLORS from 'src/lib/colors';
import NavTop from 'src/components/nav-top';
import WelcomeMessage from 'src/components/welcome-message';
import SearchForProduct from 'src/components/search-for-product-home';
import ROUTES from 'src/lib/routes';
import { useRouter } from 'src/hooks';
import { useCartCountContext } from 'src/lib/contexts/cart-count-context';
import { setPreviousPath } from 'src/lib/utils/setPreviousPath';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';

const useStyles = makeStyles({
    headerTopWrapper: {
        display: 'flex',
        padding: '16px 16px 0px 24px',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: '100%',
    },
    headerTopSubWrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    searchForProductWrapper: {
        display: 'flex',
        padding: '18px',
        width: '100%',
        columnGap: '0.8rem',
        alignItems: 'center',
    },
    homeIcons: {
        display: 'flex',
        width: '60px',
        justifyContent: 'space-between',
    },
    backIcon: {
        fontSize: '1.5rem',
        color: COLORS.whiteSmoke,
    },
    button: {
        background: 'none',
        border: 'none',
        cursor: 'pointer',
    },
    badge: {
        right: 3,
        background: 'none',
        '& .MuiBadge-badge': {
            color: COLORS.homeBlack,
            backgroundColor: COLORS.primaryWhite,
            lineHeight: '21px',
        },
    },
});

type HeaderProps = {
    position?: string;
    headerFull?: boolean;
    backButton?: boolean;
    onClickHandler?: () => void;
};

export default function Header({
    position,
    headerFull,
    backButton,
    onClickHandler,
}: HeaderProps) {
    const classes = useStyles();
    const { mainStore } = useStoreData();
    const { push, location } = useRouter();
    const { cartCount } = useCartCountContext();
    const { analyticsCustomEvent } = useEventsReporter();
    const { fromPath, setFromPath } = useProductBrowseContext();

    const handlePush = (route: any) => {
        setFromPath(
            ROUTES.productDetailsPage === location.pathname ||
            ROUTES.previousOrders === location.pathname
                ? fromPath
                : location.pathname
        );
        return push(route, setPreviousPath(location, fromPath));
    };

    const handleNavigate = () => {
        analyticsCustomEvent(eventMetricsMap.home, {
            click_text: 'cart',
        });
        if (cartCount > 0) {
            return handlePush(ROUTES.cart);
        }
        return handlePush(ROUTES.emptyCart);
    };

    const handleReciept = () => {
        setFromPath(
            ROUTES.productDetailsPage === location.pathname ||
            ROUTES.previousOrders === location.pathname
                ? fromPath
                : location.pathname
        );

        push(ROUTES.previousOrders, setPreviousPath(location, fromPath));
        analyticsCustomEvent(eventMetricsMap.home, {
            click_text: 'purchases',
        });
    };

    return (
        <NavTop
            position={position}
            withTopScroll
            bgColor={COLORS.brandRed}
            bgImageSrc={require('../assets/bgs/header-bg.svg')}
            noBorderRadius
            minHeight="0"
        >
            {headerFull && (
                <div className={classes.headerTopWrapper}>
                    <div className={classes.headerTopSubWrapper}>
                        <img
                            src={require('../assets/icons/iconStaplesLogo.svg')}
                            alt="staples connect logo"
                        />
                        <WelcomeMessage />
                    </div>
                </div>
            )}
            <div className={classes.searchForProductWrapper}>
                {backButton && (
                    <ButtonBase onClick={() => onClickHandler?.()} role="button"
                    aria-label="Go back">
                        <ArrowBackIosRoundedIcon className={classes.backIcon} />
                    </ButtonBase>
                )}
                <SearchForProduct decoy mainStore={mainStore} />
                <div className={classes.homeIcons}>
                    <button
                        type="button"
                        onClick={handleReciept}
                        role="button"
                        aria-label="Receipt"
                        aria-roledescription="View past purchases in store and online Button"
                        className={classes.button}
                    >
                        <img
                            src={require('../assets/icons/e-reciept.svg')}
                            alt="Receipt"
                        />
                    </button>

                    <IconButton
                        onClick={handleNavigate}
                        role="button"
                        aria-label={`View cart, over ${cartCount > 99 ? 99 : cartCount} ${cartCount === 1 ? 'item' : 'items'}`}
                    >
                        <Badge
                            badgeContent={cartCount}
                            className={classes.badge}
                        >
                            <img
                                src={require('../assets/icons/e-cart.svg')}
                                alt="View cart"
                            />
                        </Badge>
                    </IconButton>

                    {/* <button type="button" onClick={() => (push(ROUTES.cart))} className={classes.button}>
            <Badge badgeContent={2}>
              <img src={require('../assets/icons/e-cart.svg')} alt="staples icon" />
            </Badge>
          </button> */}
                </div>
            </div>
        </NavTop>
    );
}
