/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable @typescript-eslint/no-empty-function */

import React from 'react';
import { Dialog } from '@material-ui/core';
import { Body, Heading, Button } from 'src/components/UI';
import { eventMetricsMap, useEventsReporter } from 'src/hooks';
import styles from './styles.module.scss';
import '../../../assets/animate/animate.css';

const EarningAndSaving = ({ handleSkipAll, handleNext }: any) => {
    const animationClass =
        'animate__animated animate__fadeIn animate__delay-0.8s';

    const { analyticsCustomEvent } = useEventsReporter();
    const handleNextScreen = () => {
        handleNext(3)
        analyticsCustomEvent(eventMetricsMap.coachmarks, {
            click_text: `next`,
        });
    }
    const handleSkip = () => {
        handleSkipAll();
        analyticsCustomEvent(eventMetricsMap.coachmarks, {
            click_text: `skip all`,
        });
    }

    return (
        <div className={animationClass}>
            <div className={styles.mainConatiner}>
                <div className={styles.absContainer}>
                    <div className={styles.cardImg}>
                        <div className={styles.wrapper} aria-label='Earn points and Store coupons tabs.'>
                            <div
                                className={`${styles.leftBtnWrapper} ${styles.leftActive}`}
                            >
                                <Button
                                    variant="text"
                                    className={styles.leftActive}
                                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                                    onClick={() => { }}
                                >
                                    <Body
                                        size={16}
                                        textAlign="textCenter"
                                        weight="bold"
                                    >
                                        Earn points
                                    </Body>
                                </Button>
                            </div>
                            <div className={`${styles.rightBtnWrapper}`}>
                                <Button
                                    variant="text"
                                    className={`${styles.textBlack}`}
                                    onClick={() => { }}
                                >
                                    <Body
                                        size={16}
                                        textAlign="textCenter"
                                        weight="bold"
                                    >
                                        Store coupons
                                    </Body>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.boxConatiner}>
                        <span className={styles.arrowIcon}>
                            <img
                                src={require('../../Icons/arrow-icon.svg')}
                                alt="img"
                            />
                        </span>
                        <Body
                            size={12}
                            className={styles.alignCount}
                            weight="light"
                            lineHeight={16}
                            ariaLabel='Two out of five'
                        >
                            2/5
                        </Body>
                        <Heading
                            type="h2"
                            weight="bold"
                            lineHeight={32}
                            size={26}
                            className={styles.alignheaderDsc}
                            ariaLabel='Earning and saving'
                        >
                            Earning and saving
                        </Heading>
                        <Body size={16} weight="light" lineHeight={20} ariaLabel='Toggle to see your Easy Rewards offers and the latest digital coupons.'>
                            {
                                'Toggle to see your Easy Rewards offers and the latest digital coupons.'
                            }
                        </Body>
                        <div className={styles.btnConatiner}>
                            <Button
                                variant="text"
                                onClick={handleSkip}
                                className={styles.Link}
                                fontWeight="bold"
                                ariaLabel='Skip all'
                            >
                                Skip all
                            </Button>
                            <Button
                                onClick={handleNextScreen}
                                bgColor="bgRed"
                                className={styles.btnAlign}
                                ariaLabel='Next'
                                role='Button'
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={styles.alignImg}>
                    <img
                        src={
                            'https://de-app.staplesconnect.com/images/CoachMarks/cm_img_earning_and_savings_sm.png'
                        }
                        alt="img"
                        className={styles.alignImg}
                    />
                </div>
            </div>
        </div>
    );
};

export default EarningAndSaving;
