/* eslint-disable object-shorthand */
import React, { useState } from 'react';
import {
    Box,
    ButtonBase,
    FormControl,
    makeStyles,
    Typography,
    Button,
} from '@material-ui/core';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
// import { sendEmailToUser } from "src/lib/api-client/sign-on";
import COLORS from 'src/lib/colors';
import ROUTES from 'src/lib/routes';
import useRouter from 'src/hooks/useRouter';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import { getSubscriptionKey } from 'src/lib/api-client/sign-on';
import LoadingButton from 'src/components/loading-button';
import { SessionManager } from 'src/lib/api-client/sessionManager';
import { akamaiTelemetryHeader } from 'src/lib/utils/app-utils';
import FullWidthCard from "../cards/full-width-card";
import LoadingOverlay from "../loading-overlay";
import NavTop from "../nav-top";
import AppLink from '../link';

const useStyles = makeStyles({
    screenload: {
        flexGrow: 1,
        background: COLORS.whiteSmoke,
        '& #pwd-screen': {
            display: 'flex',
            alignItems: 'baseline',
            borderRadius: 0,
            height: '17px',
        },
    },
    ctaBtn: {
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
        fontWeight: 500,
    },
    container: {
        minHeight: '20.5rem',
        borderRadius: '15px',
        padding: '24px',
        margin: '-5rem 1.5rem 0px',
    },
    headerErrorText: {
        width: '70%',
        margin: '15px',
        color: COLORS.whiteSmoke,
        fontSize: '17px',
        lineHeight: '22px',
        letterspacing: '-0.22499999403953552px',
        textAlign: 'center',
    },
    headerSubTitleFound: {
        width: '240px',
        margin: '15px 28px 30px 28px',
        color: COLORS.textBlack,
        fontSize: '17px',
        lineHeight: '22px',
        letterSpacing: '-0.22px',
        textAlign: 'center',
    },
    acknowledgeTermsOfService: {
        width: '240px',
        margin: '15px 28px 50px 28px',
        color: COLORS.textBlack,
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '-0.22px',
        textAlign: 'center',
    },
    bottomButton: {
        color: COLORS.brandCyan,
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '-0.408px',
        marginTop: '1rem',
        textAlign: 'center',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    headerTitle: {
        color: COLORS.whiteSmoke,
        fontSize: '24px',
        fontWeight: 700,
        marginBottom: '100px',
        lineHeight: '22px',
        letterspacing: '-0.22499999403953552px',
        textAlign: 'center',
    },
    headerLogo: {
        marginTop: '2.4rem',
        alignSelf: 'self-start',
    },

    contentContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
    },
    backIcon: {
        marginLeft: '24px',
        marginTop: '2.4rem',
        marginBottom: '1.8rem',
        alignSelf: 'self-start',
        color: '#ffffff',
    },
    backButton: {
        alignSelf: 'start',
    },
});
export const sendEmailToUser = async (
    userName: string,
    stplSessionId?: any,
    payload?: any,
    nuCaptchaAnswer?: string,
    nuCaptchaToken?: string
) => {
    const subscriptionKey = await getSubscriptionKey('StaplesIDP');
    if (!subscriptionKey) {
        console.log('subscription key service failed');
        return { status: false };
    }
    if (process.env.REACT_APP_FORGOT_PASSWORD) {
        const axiosInstance: AxiosInstance = axios.create({
            headers: {
                // always add the api key (needed for API Management)
                'ocp-apim-subscription-key':
                    process.env.REACT_APP_PUBLIC_API_KEY || '',
                'content-type': 'application/json',
                client_id: subscriptionKey,
                'Cache-Control': 'no-cache',
                ...akamaiTelemetryHeader,
            },
        });
        try {
            const { status, data } = await axiosInstance.post(
                process.env.REACT_APP_FORGOT_PASSWORD,
                JSON.stringify({
                    email: userName,
                    stplSessionId: stplSessionId,
                    placement: 'PasswordResetWebSC',
                    ndsPageModeData: payload,
                    answer: nuCaptchaAnswer,
                    nuCaptchaToken: nuCaptchaToken,
                    channel: 'SWeb',
                    requestUrl: 'app/forgotpassword',
                })
            );
            return { status: true, message: data };
        } catch (e: any) {
            return { status: false, message: e.message };
        }
    }
    return { status: false };
};
const PasswordReset = () => {
    const classes = useStyles();
    const { history } = useRouter();
    const [isLoading, setIsLoading] = useState(false);
    const [failedStatus, setFailedStatus] = useState(false);
    const { analyticsCustomEvent } = useEventsReporter();
    const sendMail = async () => {
        setIsLoading(true);
        const { status } = await sendEmailToUser(SessionManager.userName);
        analyticsCustomEvent(eventMetricsMap.password_reset, {
            click_text: 'Continue',
            element_location: 'password reset request',
        });
        if (!status) {
            setIsLoading(false);
            setFailedStatus(true);
            return;
            // eslint-disable-next-line
        } else {
            setIsLoading(false);
            setFailedStatus(false);
        }
        SessionManager.isForgotPassword = false;
        // SessionManager.setIsUserMigrated({ isUserMigrated: true,
        //      userName: SessionManager.userName });
        history.push({
            pathname: ROUTES.passwordResetEmail,
            search: '?type=easySignOn',
        });
    };
    const goBack = () => {
        history.push(ROUTES.login);
    };

    return (
        <>
            {isLoading ? (
                <LoadingOverlay
                    variant="wordmark"
                    theme="transparent-light"
                    open={isLoading}
                />
            ) : null}
            <NavTop
                maxHeight="17rem"
                bgImageSrc={require('../../assets/bgs/loginBg.svg')}
                id="pwd-screen"
            >
                <ButtonBase onClick={goBack} className={classes.backButton}>
                    <img
                        className={classes.backIcon}
                        src={require('../../assets/icons/iconBackWhite.svg')}
                        alt="staples connect logo"
                    />
                </ButtonBase>
                {failedStatus && (
                    <Typography className={classes.headerErrorText}>
                        we're unable to send the password reset email. please
                        make sure you have entered correct email.
                    </Typography>
                )}
                <Typography className={classes.headerTitle}>
                    Easy single login
                </Typography>
                <FullWidthCard
                    cardContainerClassName={classes.container}
                    noPadding
                    hasManualShadow
                    id="password-reset-card"
                >
                    <div
                        className={classes.contentContainer}
                        id="content-container"
                    >
                        <Typography className={classes.headerSubTitleFound}>
                            <div>It’s time to reset your password!</div>
                            <br />
                            This will create one login for both the app and
                            Staples.com, making it easier than ever to work,
                            learn and save!
                        </Typography>
                    </div>
                    <LoadingButton
                        fullWidth
                        id="step2-next-button"
                        variant="contained"
                        classes={{
                            root: classes.ctaBtn,
                        }}
                        type="submit"
                        loading={false}
                        onClick={() => sendMail()}
                    >
                        Continue
                    </LoadingButton>
                    <Typography className={classes.acknowledgeTermsOfService}>
                        <span>
                            By selecting "Continue" you acknowledge the{' '}
                        </span>
                        <AppLink
                            id="user-profile-california-policy-button"
                            className={classes.bottomButton}
                            href="https://www.staples.com/hc?id=52e40651-0852-4ad7-a532-45017c287d50"
                            target="_blank"
                        >
                            Terms & Conditions
                        </AppLink>
                    </Typography>
                </FullWidthCard>
            </NavTop>
        </>
    );
};
export default PasswordReset;
