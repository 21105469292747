/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-template */
/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import FullWidthCard from 'src/components/cards/full-width-card';
import { Grid, Typography } from '@material-ui/core';
import Button from 'src/components/UI/Button';
import { IconSmallCheckmark } from 'src/components/Icons';
import { format } from 'date-fns';
import ROUTES from 'src/lib/routes';
import { makeSearchString } from 'src/lib/utils/url-utils';
import {
    eventMetricsMap,
    useEventsReporter,
    useRouter,
    useUserInfo,
} from 'src/hooks';
import { Body } from 'src/components/UI';
import { enrollInRewards } from 'src/services/easyRewardsServices';
import { REWARDS_ENROLLMENT_ROUTES } from 'src/routers/routes';
import { validateUserInfo } from 'src/utils/userUtils';
import { RewardsEnrollErrorDialog } from 'src/components/EasyRewards';
import { getExpDate } from '../FeaturedList/FeaturedList';
import styles from './styles.module.scss';

interface OfferListProps {
    offers: any[];
    tabName?: string;
    redirection?: any;
    offerActivate: (data: any) => void;
}

function CouponList(props: OfferListProps) {
    const { offers: data, tabName, redirection, offerActivate } = props;
    const [, setOpenState] = React.useState(false);
    const [, setOfferdisclaimer] = React.useState('');
    const [offerList] = useState<any>(data);
    const [loading] = useState(false);
    const [enrollmentError, setEnrollmentError] = useState<{
        errorCode: string;
        errorDialogMessage: string;
    } | null>(null);
    const { userInfo, refetchUserInfo } = useUserInfo();
    const { analyticsCustomEvent } = useEventsReporter();

    const { mutateAsync: enroll } = enrollInRewards({
        onSuccess: () => refetchUserInfo(),
    });
    const { push } = useRouter();
    const toggleInfoDrawer = (item: any) => {
        setOfferdisclaimer(item.offerdisclaimer);
        setOpenState(true);
    };
    const handleActive = (offer: any, index: number) => {
        if (offerList[index].couponInfo.isSaved !== true) {
            offerActivate(offer);
            offerList[index].couponInfo.isSaved = true;
        } else {
            push({
                pathname: ROUTES.coupons,
                search: makeSearchString({
                    couponNumber: offer.couponNumber,
                    deepLink: false,
                }),
            });
        }
    };
    useEffect(() => {
        const handleScroll = () => {
            // setLoading(true)
            if (
                document.documentElement.scrollTop >=
                document.documentElement.scrollHeight -
                    document.documentElement.clientHeight -
                    100
            ) {
                // loadData();
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loading]);

    return (
        <>
            {offerList.length ? (
                <>
                    {offerList.map((offer: any, index: number) => (
                        <FullWidthCard
                            className={styles.mainCard}
                            id={`offer_box_${index}`}
                            key={`offer_box_${index}`}
                            onClick={() => {
                                push({
                                    pathname: ROUTES.coupons,
                                    search: makeSearchString({
                                        couponNumber: offer.couponNumber,
                                        deepLink: false,
                                    }),
                                });
                                const offerdescription = `${offer?.couponInfo?.descriptionText1} ${offer?.couponInfo?.descriptionText2}`;

                                analyticsCustomEvent(
                                    eventMetricsMap.easy_rewards,
                                    {
                                        click_text: 'tap to use',
                                        element_location: 'get coupons',
                                        offer_description:
                                            offerdescription?.substring(0, 50),
                                        coupon_number: offer?.couponNumber,
                                        event_rewards_number:
                                            userInfo?.rewardsNumber?.padStart(
                                                10,
                                                '0'
                                            ) || '',
                                    }
                                );
                            }}
                        >
                            <Grid container className={styles.gridContainer}>
                                {offer?.couponInfo?.isLimitedTime && (
                                    <div
                                        className={styles.multiUse}
                                        onClick={() =>
                                            toggleInfoDrawer(offer?.couponInfo)
                                        }
                                    >
                                        LIMITED TIME ONLY
                                    </div>
                                )}
                                <Grid item xs={5}>
                                    <div className={styles.imgSection}>
                                        <img
                                            src={
                                                offer?.couponInfo?.imageURL ||
                                                require('../../assets/icons/placeholderImage.svg')
                                            }
                                            alt="offer"
                                        />
                                        {!offer?.couponInfo?.isLimitedTime ? (
                                            <Typography
                                                className={styles.dateText}
                                            >
                                                {getExpDate(
                                                    offer?.couponInfo
                                                        ?.expirationDate,
                                                    offer?.couponInfo
                                                        ?.validForRetail &&
                                                        offer?.couponInfo
                                                            ?.validForDotcom
                                                        ? ''
                                                        : offer?.couponInfo
                                                              ?.validForRetail
                                                        ? 'retail'
                                                        : 'dotcom',
                                                    'M/d/yy'
                                                )}
                                            </Typography>
                                        ) : (
                                            <Typography
                                                className={styles.dateText}
                                            >
                                                {getExpDate(
                                                    offer?.couponInfo
                                                        ?.expirationDate,
                                                    offer?.couponInfo
                                                        ?.validForRetail &&
                                                        offer?.couponInfo
                                                            ?.validForDotcom
                                                        ? ''
                                                        : offer?.couponInfo
                                                              ?.validForRetail
                                                        ? 'retail'
                                                        : 'dotcom',
                                                    'M/d/yy'
                                                )}
                                            </Typography>
                                        )}
                                    </div>
                                </Grid>
                                <Grid item xs={7}>
                                    <div className={styles.contentSection}>
                                        <div>
                                            <Body
                                                size={22}
                                                weight="bold"
                                                className={styles.headerText}
                                            >
                                                {
                                                    offer?.couponInfo
                                                        ?.descriptionText1
                                                }
                                            </Body>
                                            <Body className={styles.desText}>
                                                {
                                                    offer?.couponInfo
                                                        ?.descriptionText2
                                                }
                                            </Body>
                                        </div>
                                        <div className={styles.btnWrapper}>
                                            {/* <Player
                                        // onEvent={animationPlaying}
                                        autoplay
                                        src="/lotties/rewards-enrollment/activate_button.json"
                                    // style={{ marginTop: `-${CIRCULAR_OVERHANG + 1}rem` }}
                                    /> */}
                                            {!offer?.couponInfo
                                                ?.isLimitedTime && (
                                                <Button
                                                    fontSize={12}
                                                    onClick={(
                                                        event: React.MouseEvent
                                                    ) => {
                                                        // Stops the event from bubbling up
                                                        event.stopPropagation();
                                                        const offerdescription = `${offer?.couponInfo?.descriptionText1} ${offer?.couponInfo?.descriptionText2}`;

                                                        analyticsCustomEvent(
                                                            eventMetricsMap.easy_rewards,
                                                            {
                                                                click_text:
                                                                    offer
                                                                        ?.couponInfo
                                                                        ?.isSaved
                                                                        ? 'tap to use'
                                                                        : 'Save & Extend',
                                                                element_location:
                                                                    'get coupons',
                                                                offer_description:
                                                                    offerdescription?.substring(
                                                                        0,
                                                                        50
                                                                    ),
                                                                coupon_number:
                                                                    offer?.couponNumber,
                                                                event_rewards_number:
                                                                    userInfo?.rewardsNumber ||
                                                                    '',
                                                            }
                                                        );
                                                        handleActive(
                                                            offer,
                                                            index
                                                        );
                                                    }}
                                                    size="medium"
                                                    variant={
                                                        offer?.couponInfo
                                                            ?.isSaved
                                                            ? 'text'
                                                            : 'contained-rounded'
                                                    }
                                                    className={
                                                        offer?.couponInfo
                                                            ?.isSaved &&
                                                        styles.tapPadding
                                                    }
                                                >
                                                    {offer?.couponInfo
                                                        ?.isSaved &&
                                                    !offer?.couponInfo
                                                        ?.isRedeemed ? (
                                                        <span
                                                            className={`${styles.textPurple} ${styles.saveBtnText}`}
                                                        >
                                                            <IconSmallCheckmark />{' '}
                                                            Saved. Tap to use
                                                        </span>
                                                    ) : (
                                                        'Save'
                                                    )}
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </FullWidthCard>
                    ))}
                </>
            ) : (
                <div className={styles.noData}>
                    {tabName === 'saved' || tabName === 'redeemed' ? (
                        <>
                            <Body
                                size={18}
                                textAlign="textCenter"
                            >{`You have no ${tabName} coupons`}</Body>
                            <div className={styles.btnWrapper}>
                                <Button
                                    onClick={redirection}
                                    variant="contained-rounded"
                                    className={styles.availableButton}
                                >
                                    <Body textAlign="textCenter">
                                        Check available coupons
                                    </Body>
                                </Button>
                            </div>
                        </>
                    ) : (
                        <Body margin="16px" size={18} textAlign="textCenter">
                            No coupon available.
                        </Body>
                    )}
                </div>
            )}
            {tabName === 'available' && userInfo && !userInfo.rewardsNumber && (
                <>
                    {offerList.length ? (
                        <Body size={18} textAlign="textCenter">
                            Looking for even more deals? Sign up for Easy
                            Rewards
                            <sup style={{ fontSize: 8 }}>TM</sup> today!
                        </Body>
                    ) : (
                        <Body size={18} textAlign="textCenter">
                            To get more deals, sign up for Easy Rewards
                            <sup style={{ fontSize: 8 }}>TM</sup> today!
                        </Body>
                    )}
                    <div className={styles.btnWrapper}>
                        <Button
                            onClick={async () => {
                                if (validateUserInfo(userInfo)) {
                                    enroll({
                                        FirstName: userInfo.firstName,
                                        LastName: userInfo.lastName,
                                        PhoneNumber: userInfo.phone,
                                        EmailId: userInfo.email,
                                    })
                                        .then((res: any) => {
                                            if (res?.data?.success) {
                                                push(
                                                    REWARDS_ENROLLMENT_ROUTES.favorites
                                                );
                                            } else {
                                                analyticsCustomEvent(
                                                    eventMetricsMap.pop_up,
                                                    {
                                                        click_text:
                                                            'We were unable to enroll you in Easy Rewards at this time.',
                                                        element_location:
                                                            'easy_rewards',
                                                    }
                                                );
                                                setEnrollmentError({
                                                    errorCode: res?.data.code,
                                                    errorDialogMessage:
                                                        res?.data.msg,
                                                });
                                            }
                                        })
                                        .catch((error) => {
                                            analyticsCustomEvent(
                                                eventMetricsMap.pop_up,
                                                {
                                                    click_text:
                                                        'We were unable to enroll you in Easy Rewards at this time.',
                                                    element_location:
                                                        'easy_rewards',
                                                }
                                            );
                                            setEnrollmentError({
                                                errorCode:
                                                    error?.response?.data
                                                        ?.code || 'default',
                                                errorDialogMessage:
                                                    error?.response?.data
                                                        ?.msg ||
                                                    'Please try again',
                                            });
                                        });
                                } else {
                                    push(REWARDS_ENROLLMENT_ROUTES.missingInfo);
                                }
                                analyticsCustomEvent(
                                    eventMetricsMap.easy_rewards,
                                    {
                                        click_text: 'Join for free',
                                        element_location: 'unenrolled state',
                                        event_rewards_number:
                                            userInfo?.rewardsNumber?.padStart(
                                                10,
                                                '0'
                                            ) || '',
                                    }
                                );
                            }}
                            variant="contained-rounded"
                            className={styles.availableButton}
                        >
                            <Body textAlign="textCenter">Join for free</Body>
                        </Button>
                    </div>
                </>
            )}
            {loading && (
                <div className={styles.loader}>Loading more offers</div>
            )}
            <RewardsEnrollErrorDialog
                error={enrollmentError}
                setError={setEnrollmentError}
            />
            {/* <InfoDrawer offerdisclaimer={offerdisclaimer} openInfo={openState} currentMode={(open: boolean) => setOpenState(open)} /> */}
        </>
    );
}
export default CouponList;
