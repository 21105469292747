import React, { useState } from 'react';
import { useFirebaseMessagingContext } from 'src/lib/contexts/firebase-messaging-context';
import useEventsReporter from './useEventsReporter';
import eventMetricsMap from './useEventsReporter/event-types';

type NotificationPermissionStatus =
    | 'denied'
    | 'granted'
    | 'default'
    | 'not_supported';

function useNotifications() {
    const { subscribe } = useFirebaseMessagingContext();
    const { analyticsCustomEvent } = useEventsReporter();
    const hasNotificationSupport: boolean = 'Notification' in window;
    const [currentNotificationPermission, setCurrentNotificationPermission] =
        useState(
            hasNotificationSupport ? Notification.permission : 'not_supported'
        );

    async function askNotificationPermission(): Promise<NotificationPermissionStatus> {
        if (!hasNotificationSupport) {
            return 'not_supported';
        }
        const permission = await Notification.requestPermission();
        setCurrentNotificationPermission(permission);
        if (permission === 'granted') {
            analyticsCustomEvent(eventMetricsMap.account_creation, {
                click_text: 'allow',
                element_location: 'pop-up',
            });
            analyticsCustomEvent(eventMetricsMap.enable_notifications);
        } else if (permission === 'denied') {
            analyticsCustomEvent(eventMetricsMap.account_creation, {
                click_text: 'not allow',
                element_location: 'pop-up',
            });
        }
        subscribe();
        setCurrentNotificationPermission(permission);
        return permission;
    }

    const areNotificationsAllowed = (): boolean =>
        currentNotificationPermission === 'granted';

    return {
        areNotificationsAllowed,
        askNotificationPermission,
        hasNotificationSupport,
        currentNotificationPermission,
    };
}

export default useNotifications;
