import React, { ReactNode } from 'react';
import { IconSolidCheckmark } from 'src/components/Icons';
import styles from './styles.module.scss';

interface SelectableCardProps {
    disabled?: boolean;
    selected?: boolean;
    onSelect?: React.MouseEventHandler<HTMLButtonElement>;
    containerClassname?: string;
    children: ReactNode;
}

function SelectableCard({
    onSelect,
    selected,
    disabled,
    containerClassname,
    children,
}: SelectableCardProps) {
    const buttonContainerClassname = selected
        ? styles.containerSelected
        : styles.container;
    return (
        <button
            type="button"
            onClick={onSelect}
            disabled={disabled}
            className={buttonContainerClassname}
        >
            <span className={styles.checkmarkContainer}>
                <IconSolidCheckmark />
            </span>
            <div className={containerClassname}>{children}</div>
        </button>
    );
}

export default SelectableCard;
