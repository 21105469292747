/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/self-closing-comp */
/* eslint-disable max-len */
import React, { useEffect, useState, useMemo } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import {
    Typography,
    Tabs,
    Tab as MUITab,
    withStyles,
    SwipeableDrawer,
} from '@material-ui/core';
import { AxiosResponse } from 'axios';
import {
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
    UseMutateAsyncFunction,
} from 'react-query';
import axiosInstance from 'src/lib/api-client/custom-axios';
import {
    eventMetricsMap,
    useEventsReporter,
    useRouter,
    useStickyNotification,
    useUserInfo,
} from 'src/hooks';
import ManageFavorites from 'src/screens/EasyRewards/ManageFavorites';
import { rewardsGetCouponDataURL } from 'src/services/urls';
import {
    ActivateOfferRequestBody,
    LoyaltyOffer,
    LoyaltyOfferResponse,
    activateOffer,
    enrollInRewards,
    getFeaturedLoyaltyOffers,
    getLoyaltyAccountDetails,
    getLoyaltyOffers,
    updateLoyaltyOfferCache,
} from 'src/services/easyRewardsServices';
import AlertMessage from 'src/screens/alert-message';
import { Body, Button, Heading } from 'src/components/UI';
import { saveDigitalCoupon } from 'src/lib/api-client/wallet-activities';
import {
    IntroCarousel,
    RewardsEnrollErrorDialog,
} from 'src/components/EasyRewards';
import { mergeClasses } from 'src/stylesheets/utils';
import { LOGIN_ROUTES, REWARDS_ENROLLMENT_ROUTES } from 'src/routers/routes';
import { validateUserInfo } from 'src/utils/userUtils';
import PullToRefresh from 'src/components/PullToRefresh';
import {
    IconCheckmark,
    IconSmallCheckmark,
    IconSolidCheckmark,
    IconStaplesRound,
} from 'src/components/Icons';
import { useAtom } from 'jotai';
import { useHistory } from 'react-router';
import LoadingOverlay from 'src/components/loading-overlay';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import OfferTab from './OfferTab';
import Header from './Header';
import CouponTab from '../CouponTab';
import OfferList from './OfferList';
import styles from './styles.module.scss';
import RecyclingCartridgeEarning from './RecyclingCartridgeEarning';
import { guestMode } from '../../lib/utils/app-utils';
import { toastAtom } from './OfferList/OfferCard';
import Coachmarks from '../Coachmarks/Coachmarks';
import { initialInfoDrawerState } from './OfferList/OfferList';

const Tab = withStyles(() => ({
    root: {
        fontSize: 18,
        textTransform: 'none',
        fontFamily: 'StaplesNormsRegular',
        '&$selected': {
            fontFamily: 'StaplesNormsBold',
        },
    },
    selected: {},
}))((props: { label: string }) => <MUITab {...props} />);

interface RenderViewAllProps {
    isViewAllOffers: boolean;
    isViewAllMassOffers: boolean;
    showActivateAll: boolean;
    handleAllOfferBack: () => void;
    activateAllOffers: () => void;
    filterValue: string;
    handleFilter: (value: string) => void;
    targetedOffers: LoyaltyOfferResponse | undefined;
    massOffers: LoyaltyOfferResponse | undefined;
    filterOffers: LoyaltyOffer[] | undefined;
    showBackToTop: boolean;
    handleOfferActivate: UseMutateAsyncFunction<
        AxiosResponse<any>,
        unknown,
        ActivateOfferRequestBody,
        unknown
    >;
    isTargetedOffersLoading: boolean;
    isTargetedOffersFetched: boolean;
    setShowBackToTop: React.Dispatch<React.SetStateAction<boolean>>;
    refetchMassLoyaltyOffers: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<LoyaltyOfferResponse, unknown>>;
    isMassOffersFetched: boolean;
    isMassOffersLoading: boolean;
    massFilterOffers: LoyaltyOffer[] | undefined;
    handleMassOfferBack: () => void;
    featuredOffers: LoyaltyOffer[] | undefined;
    isFeaturedOfferFetched: boolean;
    deeplinkActiveOffer: boolean;
    setDeeplinkActiveOffer: (flag: boolean) => void;
}

function RenderViewAll({
    isViewAllOffers,
    isViewAllMassOffers,
    showActivateAll,
    filterValue,
    handleAllOfferBack,
    activateAllOffers,
    handleFilter,
    targetedOffers,
    massOffers,
    showBackToTop,
    handleOfferActivate,
    filterOffers,
    isTargetedOffersLoading,
    isTargetedOffersFetched,
    setShowBackToTop,
    isMassOffersFetched,
    isMassOffersLoading,
    massFilterOffers,
    refetchMassLoyaltyOffers,
    handleMassOfferBack,
    featuredOffers,
    isFeaturedOfferFetched,
    deeplinkActiveOffer,
    setDeeplinkActiveOffer,
}: RenderViewAllProps) {
    const { userInfo } = useUserInfo();
    const { analyticsCustomEvent } = useEventsReporter();
    if (isViewAllOffers) {
        return (
            <>
                <div className={styles.offerHeader}>
                    <Button
                        variant="text"
                        className={styles.arrowBtn}
                        fontWeight="bold"
                        onClick={handleAllOfferBack}
                        tabIndex={1}
                        ariaLabel="Viewing all offers personalized for you"
                    >
                        <img
                            src={require('../../assets/icons/right-arrow.svg')}
                            alt="arrow icon"
                        />
                        Your offers
                    </Button>
                    {showActivateAll ? (
                        <Button
                            variant="text"
                            className={styles.activateAll}
                            fontWeight="normal"
                            fontSize={12}
                            tabIndex={2}
                            ariaLabel="Activate your offers"
                            onClick={() => {
                                activateAllOffers();
                                analyticsCustomEvent(
                                    eventMetricsMap.easy_rewards,
                                    {
                                        click_text: 'activate all',
                                        element_location: 'Offers - For You',
                                        event_rewards_number:
                                            userInfo?.rewardsNumber?.padStart(
                                                10,
                                                '0'
                                            ) || '',
                                    }
                                );
                            }}
                        >
                            Activate all
                        </Button>
                    ) : (
                        <Body
                            color="textWhite"
                            weight="bold"
                            size={12}
                            tabIndex={2}
                            ariaLabel="All offers are activated"
                            className={styles.allOffersActivatedText}
                        >
                            <IconSmallCheckmark
                                bgFillColor="fillWhite"
                                fillColor="fillPurple"
                            />
                            All offers are activated
                        </Body>
                    )}
                </div>
                <div className={styles.offerCapsules}>
                    <Button
                        className={`${styles.capsuleBtn} ${filterValue === 'All' ? styles.active : ''
                            }`}
                        fontSize={12}
                        fontWeight={filterValue === 'All' ? 'normal' : 'light'}
                        onClick={() => handleFilter('All')}
                        tabIndex={4}
                        ariaLabel="All"
                        role="Button"
                        ariaDescription="You are viewing both in store and online offers"
                    >
                        All
                    </Button>
                    <Button
                        className={`${styles.capsuleBtn} ${filterValue === 'InStore' ? styles.active : ''
                            }`}
                        fontSize={12}
                        fontWeight={
                            filterValue === 'InStore' ? 'normal' : 'light'
                        }
                        onClick={() => handleFilter('InStore')}
                        tabIndex={5}
                        ariaLabel="In store"
                        role="Button"
                        ariaDescription="You are viewing in store offers only"
                    >
                        In store
                    </Button>
                    <Button
                        className={`${styles.capsuleBtn} ${filterValue === 'Online' ? styles.active : ''
                            }`}
                        fontSize={12}
                        fontWeight={
                            filterValue === 'Online' ? 'normal' : 'light'
                        }
                        onClick={() => handleFilter('Online')}
                        tabIndex={6}
                        ariaLabel="Online"
                        role="Button"
                        ariaDescription="You are viewing in store online only"
                    >
                        Online
                    </Button>
                </div>
                <div className={styles.allOffers}>
                    <OfferTab
                        showActivateAll={showActivateAll}
                        featuredOffers={featuredOffers}
                        isFeaturedOfferFetched={isFeaturedOfferFetched}
                        activateAllOffers={activateAllOffers}
                        targetedOffers={
                            filterValue === 'All'
                                ? targetedOffers?.offers
                                : filterOffers
                        }
                        isTargetedOffersLoading={isTargetedOffersLoading}
                        handleActivate={handleOfferActivate}
                        isTargetedOffersFetched={isTargetedOffersFetched}
                        hideHeader
                        hideMassOfferData
                        showBackToTop={showBackToTop}
                        setShowBackToTop={setShowBackToTop}
                        allTargetedOffer={targetedOffers?.offers}
                        allMassOffer={massOffers?.offers}
                        startIndex={2}
                        deeplinkActiveOffer={deeplinkActiveOffer}
                        setDeeplinkActiveOffer={setDeeplinkActiveOffer}
                    />
                </div>
            </>
        );
    }
    if (isViewAllMassOffers) {
        return (
            <>
                <div className={styles.offerHeader}>
                    <Button
                        variant="text"
                        className={styles.arrowBtn}
                        fontWeight="bold"
                        onClick={handleMassOfferBack}
                        tabIndex={1}
                        ariaLabel="Viewing more offers"
                        ariaDescription="You are viewing online offers only"
                    >
                        <img
                            src={require('../../assets/icons/right-arrow.svg')}
                            alt="arrow icon"
                        />
                        More offers
                    </Button>
                    {showActivateAll ? (
                        <Button
                            variant="text"
                            className={styles.activateAll}
                            fontWeight="normal"
                            fontSize={12}
                            tabIndex={2}
                            ariaLabel="Activate all"
                            onClick={() => {
                                activateAllOffers();
                                analyticsCustomEvent(
                                    eventMetricsMap.easy_rewards,
                                    {
                                        click_text: 'activate all',
                                        element_location:
                                            'Offers - More Offers',
                                        event_rewards_number:
                                            userInfo?.rewardsNumber?.padStart(
                                                10,
                                                '0'
                                            ) || '',
                                    }
                                );
                            }}
                        >
                            Activate all
                        </Button>
                    ) : (
                        <Body
                            color="textWhite"
                            weight="bold"
                            size={12}
                            className={styles.allOffersActivatedText}
                            tabIndex={3}
                            ariaLabel="All offers are activated"
                        >
                            <IconSmallCheckmark
                                bgFillColor="fillWhite"
                                fillColor="fillPurple"
                            />
                            All offers are activated
                        </Body>
                    )}
                </div>
                <div className={styles.offerCapsules}>
                    <Button
                        className={`${styles.capsuleBtn} ${filterValue === 'All' ? styles.active : ''
                            }`}
                        fontSize={12}
                        fontWeight={filterValue === 'All' ? 'normal' : 'light'}
                        onClick={() => handleFilter('All')}
                        tabIndex={4}
                        ariaLabel="All"
                        ariaDescription="You are viewing both in store and online offers"
                    >
                        All
                    </Button>
                    <Button
                        className={`${styles.capsuleBtn} ${filterValue === 'InStore' ? styles.active : ''
                            }`}
                        fontSize={12}
                        fontWeight={
                            filterValue === 'InStore' ? 'normal' : 'light'
                        }
                        onClick={() => handleFilter('InStore')}
                        tabIndex={5}
                        ariaLabel="In Store"
                        ariaDescription="You are viewing in store offers only"
                    >
                        In store
                    </Button>
                    <Button
                        className={`${styles.capsuleBtn} ${filterValue === 'Online' ? styles.active : ''
                            }`}
                        fontSize={12}
                        fontWeight={
                            filterValue === 'Online' ? 'normal' : 'light'
                        }
                        onClick={() => handleFilter('Online')}
                        tabIndex={6}
                        ariaLabel="Online"
                        ariaDescription="You are viewing online offers only"
                    >
                        Online
                    </Button>
                </div>
                <div className={styles.allOffers}>
                    <OfferTab
                        showActivateAll={showActivateAll}
                        activateAllOffers={activateAllOffers}
                        featuredOffers={featuredOffers}
                        isFeaturedOfferFetched={isFeaturedOfferFetched}
                        massOffers={
                            filterValue === 'All'
                                ? massOffers?.offers
                                : massFilterOffers
                        }
                        totalMassOffers={massOffers?.offers.length}
                        isMassOffersLoading={isMassOffersLoading}
                        handleActivate={handleOfferActivate}
                        isMassOffersFetched={isMassOffersFetched}
                        refetchMassLoyaltyOffers={refetchMassLoyaltyOffers}
                        hideHeader
                        hideTargetOfferData
                        showBackToTop={showBackToTop}
                        setShowBackToTop={setShowBackToTop}
                        allTargetedOffer={targetedOffers?.offers}
                        allMassOffer={massOffers?.offers}
                        startIndex={2}
                        deeplinkActiveOffer={deeplinkActiveOffer}
                        setDeeplinkActiveOffer={setDeeplinkActiveOffer}
                    />
                </div>
            </>
        );
    }
    return null;
}

function EasyRewards() {
    const coachmarks: any = localStorage?.getItem('coachmarks');
    const parseCoachmarks = JSON.parse(coachmarks);
    const [coachmarksData, setCoachmarksData] = useState(parseCoachmarks);
    const isGuestModeOn = guestMode();
    const { addNotification } = useStickyNotification();
    const history = useHistory();
    const [toastMessage, setToastMessage] = useAtom(toastAtom);
    const { userInfo, isFetchingUserInfo, refetchUserInfo } = useUserInfo();
    const { query, push } = useRouter();
    const [enrollmentError, setEnrollmentError] = useState<{
        errorCode: string;
        errorDialogMessage: string;
    } | null>(null);

    const { analyticsCustomEvent } = useEventsReporter();

    const { mutateAsync: enroll } = enrollInRewards({
        onSuccess: () => refetchUserInfo(),
    });
    const rewardsNumber = userInfo?.rewardsNumber || '';
    // TODO Remove all coupon logic from this component and move it to it's own component.
    // Use this component only to consolidate all the sections of the easy rewards page.
    // Do not use this component to write any logic which can be isolated in its own component.

    const [defaultOffset, defaultLimit] = [0, 100];
    const [offset, setOffset] = useState(defaultOffset);
    const [limit, setLimit] = useState(defaultLimit);
    const tabIndex = parseInt(query?.tabIndex, 10) || 1;
    const [activeTabIndex, setActiveTabIndex] = useState(tabIndex);
    const [tosSource, setTosSource] = useState<string | null>(null);
    const [showTabs, setShowTabs] = useState(false);
    const [tosTabIndex, setTosTabIndex] = useState(0);
    const [isCouponsFetchingError, setCouponsFetchingError] = useState(false);
    const [anyOfferLoaded, setAnyOfferLoaded] = useState(false);
    const [showActivateAll, setShowActivateAll] = useState(true);
    const [heroCoupon, setHeroCoupon] = useState({});
    const viewcoupon = localStorage.getItem('viewcoupon');
    const [showBackToTop, setShowBackToTop] = useState(false);
    const [infoDrawerData, setinfoDrawerData] = useState(
        initialInfoDrawerState
    );

    const {
        data: loyaltyAccountDetails,
        isLoading: isLoyaltyAccountDetailsLoading,
    } = getLoyaltyAccountDetails({
        rewardsNumber: userInfo?.rewardsNumber,
        config: {
            enabled: !!userInfo?.rewardsNumber,
        },
    });

    const {
        data: massOffers,
        refetch: refetchMassLoyaltyOffers,
        isLoading: isMassOffersLoading,
        isFetched: isMassOffersFetched,
    } = getLoyaltyOffers({
        offerType: 'MASS',
        config: {
            onSuccess: (res) => {
                if (res && res?.offers?.length > 0) {
                    setOffset(offset + limit + 1);
                    setAnyOfferLoaded(true);
                }
            },
            refetchOnMount: false,
        },
    });
    massOffers?.offers?.sort((a, b) => a.ranking - b.ranking);

    const {
        data: targetedOffers,
        refetch: refetchTargetedLoyaltyOffers,
        isLoading: isTargetedOffersLoading,
        isFetched: isTargetedOffersFetched,
    } = getLoyaltyOffers({
        offerType: 'TARGETED',
        config: {
            onSuccess: (res) => {
                if (res && res?.offers?.length > 0) {
                    setOffset(offset + limit + 1);
                    setAnyOfferLoaded(true);
                }
            },
            refetchOnMount: false,
        },
    });
    targetedOffers?.offers?.sort((a, b) => a.ranking - b.ranking);

    const {
        data: featuredOffers,
        refetch: refetchFeaturedLoyaltyOffers,
        isLoading: isFeaturedOfferLoading,
        isFetched: isFeaturedOfferFetched,
    } = getFeaturedLoyaltyOffers({
        config: {
            onSuccess: (res) => {
                if (res && res?.offers?.length > 0) {
                    setAnyOfferLoaded(true);
                }
            },
            refetchOnMount: false,
        },
    });

    const refetchLoyaltyOffers = () => {
        Promise.all([
            refetchFeaturedLoyaltyOffers(),
            refetchTargetedLoyaltyOffers(),
            refetchMassLoyaltyOffers(),
        ]);
    };

    const { mutateAsync: handleOfferActivate } = activateOffer();

    const activateAllOffers = () => {
        const targetedOfferIdsToActivate =
            targetedOffers?.offers
                ?.filter((item) => !item.activated)
                .map((item) => item.offerid) || [];
        const massOfferIdsToActivate =
            massOffers?.offers
                ?.filter((item) => !item.activated)
                .map((item) => item.offerid) || [];
        const featuredOfferIdsToActivate =
            featuredOffers?.offers
                ?.filter((item) => !item.activated)
                .map((item) => item.offerid) || [];
        const idsToActivate = [
            ...targetedOfferIdsToActivate,
            ...featuredOfferIdsToActivate,
            ...massOfferIdsToActivate,
        ];
        setShowActivateAll(false);
        ['MASS', 'TARGETED', 'FEATURED'].forEach((offerCacheKey) => {
            updateLoyaltyOfferCache({
                offerType: offerCacheKey as 'TARGETED' | 'MASS' | 'FEATURED',
                updater: (oldData) => {
                    if (oldData) {
                        const newData: typeof oldData = {
                            ...oldData,
                        };
                        newData.offers.map((item) => {
                            item.activated = true;
                            return item;
                        });
                        return newData;
                    }
                    return {} as LoyaltyOfferResponse;
                },
            });
        });
        handleOfferActivate(idsToActivate)
            .then((res) => {
                if (
                    res.data?.some((activateRes: any) => !activateRes?.success)
                ) {
                    throw new Error(
                        "All offers couldn't be activated, try again."
                    );
                }
            })
            .catch(() => {
                setShowActivateAll(true);
                setToastMessage("All offers couldn't be activated, try again.");
            })
            .finally(() => {
                refetchLoyaltyOffers();
            });
    };

    const [available, setAvailable] = useState<any[]>([]);
    const [saved, setSaved] = useState<any[]>([]);
    const [redeemed, setRedeemed] = useState<any[]>([]);
    const [isCouponDataFetching, setCouponDataFetching] = useState(false);
    const [isViewAllOffers, setIsViewAllOffers] = useState(false);
    const [isViewAllMassOffers, setIsViewAllMassOffers] = useState(false);
    const [filterValue, setFilterValue] = useState('All');
    const { setHideFooter, setGuestModal } = useUserUpdateContext();
    const [deeplinkActiveOffer, setDeeplinkActiveOffer] = useState(false);

    const getCouponData = () => {
        setCouponDataFetching(true);
        // TODO use react query to write the service call
        axiosInstance
            .get(
                `${rewardsGetCouponDataURL.replace(
                    '%rewardsnumber%',
                    rewardsNumber || '0'
                )}`
            )
            .then((couponRes: any) => {
                const data = couponRes?.data?.couponDataList;
                const filterHeroCoupon = data.find(
                    (item: any) =>
                        item?.category === 'Coupon' &&
                        item?.couponInfo?.isNewCoupon &&
                        item?.couponInfo?.offerType === 'Hero'
                );
                if (filterHeroCoupon?.couponInfo) {
                    setHeroCoupon(filterHeroCoupon);
                } else if (data?.[0]?.couponInfo) {
                    setHeroCoupon(data?.[0]);
                }
                const filterCouponData = data
                    .filter((coupon: any) => coupon.category === 'Coupon')
                    .sort(
                        (a: any, b: any) =>
                            a.couponInfo.priority - b.couponInfo.priority
                    );

                setAvailable(
                    filterCouponData?.filter(
                        (item: any) =>
                            item?.couponInfo?.isNewCoupon &&
                            (!item?.couponInfo?.isRedeemed ||
                                item?.couponInfo?.couponClass.toLowerCase() ===
                                'storecredit')
                    )
                );
                setSaved(
                    filterCouponData?.filter(
                        (item: any) =>
                            item?.couponInfo?.isSaved &&
                            !item?.couponInfo?.isRedeemed
                    )
                );
                setRedeemed(
                    filterCouponData?.filter(
                        (item: any) => item?.couponInfo?.isRedeemed
                    )
                );
                setCouponDataFetching(false);
                if (!couponRes?.data?.success) {
                    setCouponsFetchingError(true);
                }
            })
            .catch((error) => {
                setCouponsFetchingError(true);
                console.log(error, 'loyaltyerror');
                setCouponDataFetching(false);
                // setLoading(false);
            });
    };

    // TODO fix variable type
    const handleExtendCouponDate = (offer: any) => {
        setCouponDataFetching(true);
        // TODO use react query to write the service call
        saveDigitalCoupon({
            rewardsNumber,
            couponCode: offer?.couponNumber,
            isSaved: !offer?.couponInfo?.isSaved,
        }).then((couponRes) => {
            const data = couponRes?.data?.couponDataList;
            setAvailable(
                data?.filter(
                    (item: any) =>
                        item?.couponInfo?.isNewCoupon &&
                        !item?.couponInfo?.isRedeemed
                )
            );
            setSaved(
                data?.filter(
                    (item: any) =>
                        item?.couponInfo?.isSaved &&
                        !item?.couponInfo?.isRedeemed
                )
            );
            setRedeemed(
                data?.filter((item: any) => item?.couponInfo?.isRedeemed)
            );
            setCouponDataFetching(false);
        });
    };

    const handleToggleIndex = (index: number, isScrollToTop = false) => {
        setCouponsFetchingError(false);
        getCouponData();
        setActiveTabIndex(index);
        if (isScrollToTop) {
            setShowBackToTop(false);
        }
    };
    const handleviewAllTargetedOffers = () => {
        setFilterValue('All');
        setIsViewAllOffers(true);
        setHideFooter(true);
    };
    const handleAllMassOffers = () => {
        setFilterValue('All');
        setIsViewAllMassOffers(true);
        setHideFooter(true);
    };
    useEffect(() => {
        if (rewardsNumber) {
            getCouponData();
        }
    }, [rewardsNumber]);

    useEffect(() => {
        if (activeTabIndex === 1) {
            setCouponsFetchingError(false);
            refetchLoyaltyOffers();
        }
    }, [activeTabIndex]);

    useEffect(() => {
        if (activeTabIndex === 2) {
            getCouponData();
        }
    }, [rewardsNumber, activeTabIndex]);

    useEffect(() => {
        if (viewcoupon === 'true') {
            localStorage.setItem('viewcoupon', 'false');
            setActiveTabIndex(2);
        }
    }, [viewcoupon]);

    //  show tos notification if user hasn't accepted them
    useEffect(() => {
        if (
            userInfo?.rewardsNumber &&
            !isLoyaltyAccountDetailsLoading &&
            loyaltyAccountDetails?.accounts[0]?.termsandconditionaccepted ===
            'N'
        ) {
            addNotification({
                id: 'loyaltyTOSNotification',
                group: 'loyaltyTOS',
            });
        }
    }, [isLoyaltyAccountDetailsLoading, isFetchingUserInfo]);

    useEffect(() => {
        if (isViewAllOffers || isViewAllMassOffers) {
            setShowBackToTop(false);
        }
    }, [isViewAllOffers, isViewAllMassOffers]);

    const handleFilter = (value: string) => {
        setFilterValue(value);
    };

    const handleMassOfferBack = () => {
        setIsViewAllMassOffers(false);
        setTimeout(() => {
            document
                ?.getElementById?.('view-all-id-for-offer')
                ?.scrollIntoView(true);
        }, 500);
        setHideFooter(false);
    };

    const handleAllOfferBack = () => {
        setIsViewAllOffers(false);
        setTimeout(() => {
            document?.getElementById?.('your-offer-id')?.scrollIntoView(false);
        }, 500);
        setHideFooter(false);
    };

    const filterOffers = useMemo(() => {
        if (targetedOffers?.offers) {
            const copyOfTargetOffer = [...targetedOffers.offers];
            if (filterValue === 'InStore') {
                return copyOfTargetOffer?.filter((offer) =>
                    offer?.channel?.includes('Retail')
                );
            }
            if (filterValue === 'Online') {
                return copyOfTargetOffer?.filter((offer) =>
                    offer?.channel?.includes('Dotcom')
                );
            }
        }
        return targetedOffers?.offers;
    }, [targetedOffers?.offers, filterValue]);

    const massFilterOffers = useMemo(() => {
        if (massOffers?.offers) {
            const copyOfMassOffer = [...massOffers.offers];
            if (filterValue === 'InStore') {
                return copyOfMassOffer?.filter((offer) =>
                    offer.channel.includes('Retail')
                );
            }
            if (filterValue === 'Online') {
                return copyOfMassOffer?.filter((offer) =>
                    offer.channel.includes('Dotcom')
                );
            }
        }
        return massOffers?.offers;
    }, [massOffers?.offers, filterValue]);

    useEffect(() => {
        if (isViewAllOffers || isViewAllMassOffers) {
            const unblock = history.block(() => {
                // eslint-disable-next-line no-unused-expressions
                isViewAllOffers ? handleAllOfferBack() : handleMassOfferBack();
                return false;
            });

            return () => {
                unblock();
            };
        }
        return undefined;
    }, [isViewAllOffers, isViewAllMassOffers]);

    useEffect(() => {
        setShowActivateAll(
            !!(
                targetedOffers?.offers?.some((offer) => !offer.activated) ||
                massOffers?.offers?.some((offer) => !offer.activated) ||
                featuredOffers?.offers.some((offer) => !offer.activated)
            )
        );
    }, [targetedOffers, massOffers, featuredOffers]);

    useEffect(() => {
        setHideFooter(false);
        analyticsCustomEvent(eventMetricsMap.screen_view, {
            firebase_screen: 'app_easy rewards',
            firebase_screen_class: 'app_easy rewards',
        });

        return () => setHideFooter(false);
    }, []);
    const activeFeatureOffer = (findFeaturedOffers: any) => {
        handleOfferActivate([findFeaturedOffers?.offerid])
            .then((res) => {
                console.log('🚀 ~ .then ~ res:', res);
                if (res?.data[0] && res?.data[0].success) {
                    analyticsCustomEvent(eventMetricsMap.easy_rewards, {
                        click_text: 'Activate',
                        element_location: 'Featured offers',
                        offer_description:
                            `${findFeaturedOffers.name} ${findFeaturedOffers?.offerdescription}`
                                ?.substring(0, 50)
                                ?.toLocaleLowerCase(),
                        coupon_number: findFeaturedOffers?.offerid,
                        event_rewards_number:
                            userInfo?.rewardsNumber?.padStart(10, '0') || '',
                    });
                    updateLoyaltyOfferCache({
                        offerType: 'FEATURED',
                        updater: (oldData) => {
                            if (oldData) {
                                const newData: typeof oldData = {
                                    ...oldData,
                                };
                                newData.offers.map((item) => {
                                    if (
                                        item.offerid ===
                                        findFeaturedOffers?.offerid
                                    ) {
                                        item.activated = true;
                                    }
                                    return item;
                                });
                                return newData;
                            }
                            return {} as LoyaltyOfferResponse;
                        },
                    });
                } else {
                    setToastMessage(
                        "This offer couldn't be activated, try again."
                    );
                }
            })
            .catch(() => {
                setToastMessage("This offer couldn't be activated, try again.");
            });
    };
    useEffect(() => {
        const findFeaturedOffers = featuredOffers?.offers?.find(
            (offerData: any) => offerData?.offerid === query?.offerId
        );
        if (findFeaturedOffers?.offerid) {
            const isActivateOffer = localStorage.getItem('isActivateOffer');
            if (isActivateOffer === 'false') {
                if (findFeaturedOffers?.offerid) {
                    setTimeout(() => {
                        localStorage.setItem('isActivateOffer', 'true');
                        setinfoDrawerData({
                            offer: findFeaturedOffers,
                            isOpen: true,
                        });
                    }, 1500);
                    if (query.shouldActivate === 'true') {
                        if (!findFeaturedOffers?.activated) {
                            setTimeout(() => {
                                activeFeatureOffer(findFeaturedOffers);
                            }, 2500);
                        }
                    }
                }
            }
        }
    }, [featuredOffers?.offers?.length, isFeaturedOfferFetched]);

    return (
        <>
            {(isFetchingUserInfo || !userInfo) && !isGuestModeOn ? (
                <div style={{ height: '0vh' }}></div>
            ) : null}
            <PullToRefresh
                onRefresh={async () =>
                    Promise.all([refetchLoyaltyOffers(), getCouponData()])
                }
                bgColor="bgPurple"
                setShowBackToTop={setShowBackToTop}
                showBackToTop={showBackToTop}
            >
                {isViewAllOffers || isViewAllMassOffers ? (
                    <RenderViewAll
                        isViewAllOffers={isViewAllOffers}
                        isViewAllMassOffers={isViewAllMassOffers}
                        showActivateAll={showActivateAll}
                        filterValue={filterValue}
                        handleAllOfferBack={handleAllOfferBack}
                        activateAllOffers={activateAllOffers}
                        handleFilter={handleFilter}
                        targetedOffers={targetedOffers}
                        massOffers={massOffers}
                        showBackToTop={showBackToTop}
                        handleOfferActivate={handleOfferActivate}
                        filterOffers={filterOffers}
                        isTargetedOffersLoading={isTargetedOffersLoading}
                        isTargetedOffersFetched={isTargetedOffersFetched}
                        setShowBackToTop={setShowBackToTop}
                        isMassOffersFetched={isMassOffersFetched}
                        isMassOffersLoading={isMassOffersLoading}
                        massFilterOffers={massFilterOffers}
                        refetchMassLoyaltyOffers={refetchMassLoyaltyOffers}
                        handleMassOfferBack={handleMassOfferBack}
                        featuredOffers={featuredOffers?.offers}
                        isFeaturedOfferFetched={isFeaturedOfferFetched}
                        deeplinkActiveOffer={deeplinkActiveOffer}
                        setDeeplinkActiveOffer={setDeeplinkActiveOffer}
                    />
                ) : (
                    <div className={styles.pbNav}>
                        <Header
                            activeTabIndex={activeTabIndex}
                            toggleIndex={handleToggleIndex}
                        />
                        {activeTabIndex === 1 &&
                            ((userInfo && !userInfo.rewardsNumber) ||
                                isGuestModeOn ? (
                                <div style={{ padding: '1rem' }}>
                                    <IntroCarousel
                                        variant="non-migrated-rewards-center"
                                        primaryCta={async () => {
                                            if (isGuestModeOn) {
                                                setGuestModal({
                                                    open: true,
                                                    closeBehavior: 'onboarding',
                                                });
                                            } else if (
                                                userInfo &&
                                                validateUserInfo(userInfo)
                                            ) {
                                                enroll({
                                                    FirstName:
                                                        userInfo.firstName,
                                                    LastName: userInfo.lastName,
                                                    PhoneNumber: userInfo.phone,
                                                    EmailId: userInfo.email,
                                                })
                                                    .then((res: any) => {
                                                        console.log({ res });
                                                        if (
                                                            res?.data?.success
                                                        ) {
                                                            push(
                                                                REWARDS_ENROLLMENT_ROUTES.peronalize
                                                            );
                                                        } else {
                                                            analyticsCustomEvent(
                                                                eventMetricsMap.pop_up,
                                                                {
                                                                    click_text:
                                                                        'We were unable to enroll you in Easy Rewards at this time.',
                                                                    element_location:
                                                                        'easy_rewards',
                                                                }
                                                            );
                                                            setEnrollmentError({
                                                                errorCode:
                                                                    res?.data
                                                                        .code,
                                                                errorDialogMessage:
                                                                    res?.data
                                                                        .msg,
                                                            });
                                                        }
                                                    })
                                                    .catch((error) => {
                                                        analyticsCustomEvent(
                                                            eventMetricsMap.pop_up,
                                                            {
                                                                click_text:
                                                                    'We were unable to enroll you in Easy Rewards at this time.',
                                                                element_location:
                                                                    'easy_rewards',
                                                            }
                                                        );
                                                        setEnrollmentError({
                                                            errorCode:
                                                                error?.response
                                                                    ?.data
                                                                    ?.code ||
                                                                'default',
                                                            errorDialogMessage:
                                                                error?.response
                                                                    ?.data
                                                                    ?.msg ||
                                                                'Please try again',
                                                        });
                                                    });
                                            } else {
                                                push(
                                                    REWARDS_ENROLLMENT_ROUTES.missingInfo
                                                );
                                            }
                                            analyticsCustomEvent(
                                                eventMetricsMap.easy_rewards,
                                                {
                                                    click_text: 'join for free',
                                                    element_location:
                                                        'unenrolled state',
                                                    event_rewards_number:
                                                        userInfo?.rewardsNumber ||
                                                        '',
                                                }
                                            );
                                        }}
                                    />
                                </div>
                            ) : (
                                <>
                                    <ManageFavorites />
                                    <RecyclingCartridgeEarning />
                                    {anyOfferLoaded ? (
                                        <>
                                            <section
                                                className={mergeClasses(
                                                    styles.px4,
                                                    styles.listContainer
                                                )}
                                            >
                                                <div
                                                    className={
                                                        styles.offerHeading
                                                    }
                                                >
                                                    <p
                                                        tabIndex={8}
                                                        className={
                                                            styles.heading
                                                        }
                                                    >
                                                        Featured offers
                                                    </p>
                                                    {targetedOffers?.offers
                                                        .length ||
                                                        massOffers?.offers
                                                            ?.length ? (
                                                        <>
                                                            {showActivateAll ? (
                                                                <Typography
                                                                    aria-label="Activate all button"
                                                                    className={
                                                                        styles.activeAll
                                                                    }
                                                                    onClick={() => {
                                                                        activateAllOffers();
                                                                        analyticsCustomEvent(
                                                                            eventMetricsMap.easy_rewards,
                                                                            {
                                                                                click_text:
                                                                                    'activate all',
                                                                                element_location:
                                                                                    'Featured Offers',
                                                                                event_rewards_number:
                                                                                    userInfo?.rewardsNumber ||
                                                                                    '',
                                                                            }
                                                                        );
                                                                    }}
                                                                >
                                                                    Activate all
                                                                </Typography>
                                                            ) : (
                                                                <Body
                                                                    color="textPurple"
                                                                    weight="bold"
                                                                    size={12}
                                                                    className={
                                                                        styles.allOffersActivatedText
                                                                    }
                                                                >
                                                                    <IconSmallCheckmark />
                                                                    All offers
                                                                    are
                                                                    activated
                                                                </Body>
                                                            )}
                                                        </>
                                                    ) : null}
                                                </div>
                                                <OfferList
                                                    variant="horizontal"
                                                    activateOffer={
                                                        handleOfferActivate
                                                    }
                                                    isDataLoading={
                                                        isFeaturedOfferLoading
                                                    }
                                                    infoDrawerData={
                                                        infoDrawerData
                                                    }
                                                    setinfoDrawerData={
                                                        setinfoDrawerData
                                                    }
                                                    data={
                                                        featuredOffers?.offers
                                                    }
                                                    offerCacheKey="FEATURED"
                                                    strtIndex={9}
                                                />
                                            </section>
                                            <OfferTab
                                                showActivateAll={
                                                    showActivateAll
                                                }
                                                isFeaturedOfferFetched={
                                                    isFeaturedOfferFetched
                                                }
                                                featuredOffers={
                                                    featuredOffers?.offers
                                                }
                                                activateAllOffers={
                                                    activateAllOffers
                                                }
                                                targetedOffers={targetedOffers?.offers?.slice(
                                                    0,
                                                    3
                                                )}
                                                allTargetedOffer={
                                                    targetedOffers?.offers
                                                }
                                                allMassOffer={
                                                    massOffers?.offers
                                                }
                                                totalTargetOffers={
                                                    targetedOffers?.offers
                                                        .length
                                                }
                                                isTargetedOffersLoading={
                                                    isTargetedOffersLoading
                                                }
                                                massOffers={massOffers?.offers?.slice(
                                                    0,
                                                    3
                                                )}
                                                totalMassOffers={
                                                    massOffers?.offers.length
                                                }
                                                isMassOffersLoading={
                                                    isMassOffersLoading
                                                }
                                                handleActivate={
                                                    handleOfferActivate
                                                }
                                                isMassOffersFetched={
                                                    isMassOffersFetched
                                                }
                                                isTargetedOffersFetched={
                                                    isTargetedOffersFetched
                                                }
                                                refetchMassLoyaltyOffers={
                                                    refetchMassLoyaltyOffers
                                                }
                                                couponData={heroCoupon}
                                                toggleIndex={handleToggleIndex}
                                                handleviewAllTargetedOffers={
                                                    handleviewAllTargetedOffers
                                                }
                                                isMainRewardsPage
                                                showBackToTop={showBackToTop}
                                                setShowBackToTop={
                                                    setShowBackToTop
                                                }
                                                handleAllMassOffers={
                                                    handleAllMassOffers
                                                }
                                                deeplinkActiveOffer={
                                                    deeplinkActiveOffer
                                                }
                                                setDeeplinkActiveOffer={
                                                    setDeeplinkActiveOffer
                                                }
                                            />
                                        </>
                                    ) : (
                                        <Typography
                                            className={styles.errorText}
                                        >
                                            {isFeaturedOfferLoading ||
                                                isMassOffersLoading ||
                                                isTargetedOffersLoading ? (
                                                <Player
                                                    autoplay
                                                    loop
                                                    src={require('../../assets/lotties/stapleRevealWordmark.json')}
                                                    style={{ height: '150px' }}
                                                />
                                            ) : (
                                                'Offers are unavailable at this time, please check back later.'
                                            )}
                                        </Typography>
                                    )}
                                </>
                            ))}
                        {activeTabIndex === 2 &&
                            (available.length ||
                                saved.length ||
                                redeemed.length ||
                                isCouponsFetchingError) ? (
                            <>
                                <CouponTab
                                    available={available}
                                    saved={saved}
                                    redeemed={redeemed}
                                    offerActivate={handleExtendCouponDate}
                                    isCouponsFetchingError={
                                        isCouponsFetchingError
                                    }
                                    showBackToTop={showBackToTop}
                                    setShowBackToTop={setShowBackToTop}
                                />
                            </>
                        ) : (
                            <>
                                {activeTabIndex === 2 &&
                                    isCouponDataFetching &&
                                    !(
                                        available.length ||
                                        saved.length ||
                                        redeemed.length
                                    ) && (
                                        <Player
                                            autoplay
                                            loop
                                            src={require('../../assets/lotties/stapleRevealWordmark.json')}
                                            style={{ height: '150px' }}
                                        />
                                    )}
                            </>
                        )}
                    </div>
                )}
            </PullToRefresh>
            <AlertMessage
                open={!!toastMessage}
                icon={<IconStaplesRound />}
                handleClose={() => setToastMessage('')}
                btnName={''}
                message={toastMessage}
                handleBtnClick={() => setToastMessage('')}
                status={''}
            />
            <RewardsEnrollErrorDialog
                error={enrollmentError}
                setError={setEnrollmentError}
            />
            <SwipeableDrawer
                anchor="bottom"
                open={!!tosSource}
                onClose={() => {
                    setTosSource(null);
                    setShowTabs(false);
                }}
                onOpen={() => console.log('open')}
            >
                {showTabs && (
                    <Tabs
                        className={styles.tosTabs}
                        value={tosTabIndex}
                        onChange={(_e, value) => setTosTabIndex(value)}
                        aria-label="simple tabs example"
                    >
                        <Tab label="Staples" />
                        <Tab label="Easy Rewards" />
                    </Tabs>
                )}
                {tosTabIndex === 0 && (
                    <iframe
                        id="privacy-link"
                        title="privacy link iFrame"
                        style={{ height: '90vh', width: '100%', marginTop: 16 }}
                        src={tosSource || '#'}
                    />
                )}
                {tosTabIndex === 1 && (
                    <iframe
                        id="privacy-link"
                        title="privacy link iFrame"
                        style={{ height: '90vh', width: '100%', marginTop: 16 }}
                        src={tosSource || '#'}
                    />
                )}
            </SwipeableDrawer>
            {coachmarksData?.completed === false && userInfo?.rewardsNumber ? (
                <Coachmarks
                    coachmarksData={coachmarksData}
                    setCoachmarksData={setCoachmarksData}
                    featuredOffers={featuredOffers}
                />
            ) : null}
        </>
    );
}

export default EasyRewards;
