import React, { useState, useLayoutEffect, useEffect } from 'react';
import {
    eventMetricsMap,
    useEventsReporter,
    useGeolocation,
    useRouter,
    useStoreData,
    useUserInfo,
} from 'src/hooks';
import { COACH_SCREENS_ROUTES } from 'src/routers/routes';
import { guestMode } from 'src/lib/utils/app-utils';
import ZipCodeDialogContainer from 'src/components/ZipcodeDialog/ZipcodeDialogContainer';
import StoreSelection from 'src/components/StoreSelection';
import CommonDialog from 'src/components/common-dialog';
import LoadingOverlay from 'src/components/loading-overlay';
import { Store } from 'src/services/types';
import ROUTES from 'src/lib/routes';
import { SELECTED_STORE_OBJ_KEY } from 'src/hooks/useStoreData';
import CoachScreens from '.';

function LocationCoachScreen() {
    const { replace } = useRouter();
    const { userInfo, isFetchingUserInfo } = useUserInfo();
    const { detectAndSaveZipcode, isDetectingLocation } = useGeolocation();
    const [showZipcodeDialog, setShowZipcodeDialog] = useState(false);
    const [showSearchResult, setShowSearchResult] = useState<boolean>(false);
    const [mainStoreResult, setMainStoreResult] = useState({});
    const [nearbyStores, setNearbyStores] = useState<Store[]>([]);
    const { updateMainStore } = useStoreData();
    const { analyticsCustomEvent } = useEventsReporter();
    const newUser = localStorage.getItem('newUser');
    const { push } = useRouter();
    const isGuestModeOn = guestMode();
    const [openLocationDialog, setOpenLocationDialog] = useState(false);

    const handleSetStore = (selectedStoreData: Store) => {
        updateMainStore(selectedStoreData);
        if (isGuestModeOn) {
            setShowZipcodeDialog(false);
            push(ROUTES.home);
        }
    };

    const showStoreResult = (stores: any) => {
        if (stores?.length) {
            const mainStoreId = stores[0]?.id;
            const addeitionalStore = stores.filter(
                (s: any) => s.id !== mainStoreId
            );
            setMainStoreResult(stores[0]);
            setNearbyStores(addeitionalStore);
            setShowSearchResult(true);
        } else if (stores?.length === 0) {
            console.log('No stores found within 250 miles of this zip code');
        }
    };

    const requestPermission = async () => {
        const result = await navigator.permissions.query({
            name: 'geolocation',
        });
        console.log('🚀 ~ requestPermission ~ result:', result);
        if (result.state === 'granted') {
            setOpenLocationDialog(false);
        } else if (result.state === 'denied') {
            setOpenLocationDialog(true);
        }
    };

    useLayoutEffect(() => {
        if (userInfo?.currentLocationZipCode) {
            replace(COACH_SCREENS_ROUTES.notification);
        }
        if ((userInfo && !userInfo?.currentLocationZipCode) || isGuestModeOn) {
            // @Screen name Set your store
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `Set your store`,
                firebase_screen_class: `Set your store`,
            });
            const storedSelectedStoreObj = localStorage.getItem(
                SELECTED_STORE_OBJ_KEY
            );
            if (storedSelectedStoreObj) {
                handleSetStore(JSON.parse(storedSelectedStoreObj));
            }
        }
    }, [userInfo?.currentLocationZipCode]);

    useEffect(() => {
        // @Screen name Set your store
        if (newUser !== 'true') {
            analyticsCustomEvent(eventMetricsMap.login_flow, {
                click_text: `sign in`,
                element_location: `sign-in`,
            });
        }
    }, []);

    if (
        isDetectingLocation ||
        (!userInfo?.currentLocationZipCode &&
            isFetchingUserInfo &&
            !userInfo?.firstName)
    ) {
        return (
            <LoadingOverlay
                variant="wordmark"
                theme="dark"
                open={
                    isDetectingLocation ||
                    (!userInfo?.currentLocationZipCode &&
                        isFetchingUserInfo &&
                        !userInfo?.firstName)
                }
            />
        );
    }
    return (
        <>
            <CoachScreens
                title="Set your store"
                subtitle="See store details, in-store services, view your weekly ad and place 1-hour pickup orders."
                imageSrc={require('../../assets/lotties/graphic_stores.json')}
                primaryButtonText="Use my location"
                primaryButtonOnClick={() => {
                    detectAndSaveZipcode({ showStoreResult });
                    requestPermission();
                    analyticsCustomEvent(eventMetricsMap.account_creation, {
                        click_text: 'use my location',
                        element_location: 'set your store',
                    });
                }}
                secondaryButtonText="Enter location"
                secondaryButtonOnClick={() => {
                    setShowZipcodeDialog(true);
                    analyticsCustomEvent(eventMetricsMap.account_creation, {
                        click_text: 'enter location',
                        element_location: 'set your store',
                    });
                }}
                background="#FFFFFF"
            />
            <ZipCodeDialogContainer
                showZipcodeDialog={showZipcodeDialog}
                setShowZipcodeDialog={setShowZipcodeDialog}
                requestPermission={setOpenLocationDialog}
            />

            <StoreSelection
                open={showSearchResult}
                onClose={() => setShowSearchResult(false)}
                handleSetStore={handleSetStore}
                nearbyStores={nearbyStores}
                mainStoreResult={mainStoreResult}
            />
            {openLocationDialog && (
                <CommonDialog
                    open
                    onClose={() => setOpenLocationDialog(false)}
                    message={'Please enable location service.'}
                    title={'No location access'}
                    closeIcon={false}
                />
            )}
        </>
    );
}

export default LocationCoachScreen;
