/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import {
    AnimatePresence,
    AnimationControls,
    motion,
    useAnimationControls,
} from 'framer-motion';
import { Player } from '@lottiefiles/react-lottie-player';
import { useSetAtom, atom } from 'jotai';
import { Heading, Body, Button } from 'src/components/UI';
import { mergeClasses } from 'src/stylesheets/utils';
import { useEventsReporter, eventMetricsMap, useUserInfo } from 'src/hooks';
import { IconInfo, IconSmallCheckmark } from 'src/components/Icons';
import {
    ActivateOfferMutateAsyncFunc,
    LoyaltyOffer,
    LoyaltyOfferResponse,
    updateLoyaltyOfferCache,
} from 'src/services/easyRewardsServices';
import OfferTags from './OfferTags';
import styles from './styles.module.scss';

export const toastAtom = atom('');

interface ActivateButtonProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    buttonContent: JSX.Element;
    setLottieRef: React.Dispatch<any>;
    activateButtonAnimationControls: AnimationControls;
    sparkSize?: 'small' | 'wide';
}

export function ActivateButton({
    onClick,
    buttonContent,
    activateButtonAnimationControls,
    setLottieRef,
    sparkSize = 'small',
}: ActivateButtonProps) {
    const lottieURI =
        sparkSize === 'small'
            ? require('../../../assets/lotties/rewards-enrollment/activate-btn-small-spark.json')
            : require('../../../assets/lotties/rewards-enrollment/activate-btn-wide-spark.json');
    return (
        <motion.button
            animate={activateButtonAnimationControls}
            className={styles.activateButton}
            onClick={onClick}
        >
            <Player
                lottieRef={(ref) => setLottieRef(ref)}
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    height: '200%',
                    width: '100%',
                }}
                src={lottieURI}
            />
            {buttonContent}
        </motion.button>
    );
}

interface DefaultCardOptionsProps {
    offername?: string
    offerId: string;
    offerdescription: string;
    offertype: string;
    activated: boolean;
    handleActivate: ActivateOfferMutateAsyncFunc;
    offerCacheKey: 'TARGETED' | 'MASS' | 'FEATURED';
    sparkSize?: 'small' | 'wide';
}

export function DefaultCardOptions({
    offername,
    offerId,
    offerdescription,
    activated,
    offertype,
    handleActivate,
    offerCacheKey,
    sparkSize = 'small',
}: DefaultCardOptionsProps) {
    const { analyticsCustomEvent } = useEventsReporter();
    const { userInfo } = useUserInfo();
    const setToastMessage = useSetAtom(toastAtom);
    const activateButtonAnimationControls = useAnimationControls();
    const [lottieRef, setLottieRef] = useState<any>(null);
    const [buttonContent, setButtonContent] = useState(<span>Activate</span>);
    const [isOfferInitiallyActive] = useState(activated);
    console.log('273 isOfferInitiallyActive', isOfferInitiallyActive);

    useEffect(() => {
        if (activated) {
            setButtonContent(
                <span className={styles.activatedButtonContent}>
                    <IconSmallCheckmark /> Activated
                </span>
            );
            if (isOfferInitiallyActive) {
                activateButtonAnimationControls.set({
                    backgroundColor: '#ffffff',
                    color: '#5b0069',
                });
            } else {
                if (lottieRef) {
                    lottieRef.play();
                }
                activateButtonAnimationControls.start({
                    backgroundColor: '#ffffff',
                    color: '#5b0069',
                    animationDuration: '3s',
                });
            }
            setButtonContent(
                <span className={styles.activatedButtonContent}>
                    <IconSmallCheckmark /> Activated
                </span>
            );
        } else {
            activateButtonAnimationControls.set({
                backgroundColor: '#5b0069',
                color: '#ffffff',
            });
            setButtonContent(<span>Activate</span>);
        }
    }, [activated]);

    const handleActivationFailure = () => {
        updateLoyaltyOfferCache({
            offerType: offerCacheKey,
            updater: (oldData) => {
                if (oldData) {
                    const newData: typeof oldData = {
                        ...oldData,
                    };
                    newData.offers.map((item) => {
                        if (item.offerid === offerId) {
                            item.activated = false;
                        }
                        return item;
                    });
                    return newData;
                }
                return {} as LoyaltyOfferResponse;
            },
        });
        activateButtonAnimationControls.start({
            backgroundColor: '#5b0069',
            color: '#ffffff',
            animationDuration: '3s',
        });
        setButtonContent(<span>Activate</span>);
    };

    return (
        <ActivateButton
            buttonContent={buttonContent}
            activateButtonAnimationControls={activateButtonAnimationControls}
            setLottieRef={setLottieRef}
            sparkSize={sparkSize}
            onClick={(event) => {
                event.stopPropagation();
                console.log('273 activated', activated);
                if (!activated) {
                    updateLoyaltyOfferCache({
                        offerType: offerCacheKey,
                        updater: (oldData) => {
                            if (oldData) {
                                const newData: typeof oldData = {
                                    ...oldData,
                                };
                                newData.offers.map((item) => {
                                    if (item.offerid === offerId) {
                                        item.activated = true;
                                    }
                                    return item;
                                });
                                return newData;
                            }
                            return {} as LoyaltyOfferResponse;
                        },
                    });
                    handleActivate([offerId])
                        .then((res) => {
                            if (res?.data[0] && res?.data[0].success) {
                                analyticsCustomEvent(
                                    eventMetricsMap.easy_rewards,
                                    {
                                        click_text: 'Activate',
                                        element_location:
                                            offerCacheKey === 'FEATURED'
                                                ? 'Featured offers'
                                                : offertype === 'TARGETED'
                                                ? 'Offers - For You'
                                                : 'Offers - More Offers',
                                        offer_description: (`${offername} ${offerdescription}`)
                                            ?.substring(0, 50)
                                            ?.toLocaleLowerCase(),
                                        coupon_number: offerId,
                                        event_rewards_number:
                                            userInfo?.rewardsNumber?.padStart(
                                                10,
                                                '0'
                                            ) || '',
                                    }
                                );
                            } else {
                                setToastMessage(
                                    "This offer couldn't be activated, try again."
                                );
                                handleActivationFailure();
                            }
                        })
                        .catch(() => {
                            setToastMessage(
                                "This offer couldn't be activated, try again."
                            );
                            handleActivationFailure();
                        });
                }
            }}
        />
    );
}

interface ContinuityCardOptionsProps {
    offer: LoyaltyOffer;
    activated: boolean;
    continuitytransactiondesc: string;
    continuitytransactiontotal: string;
    countinuitytransactioncurrent: string;
    handleActivate: ActivateOfferMutateAsyncFunc;
    offerCacheKey: 'TARGETED' | 'MASS' | 'FEATURED';
    sparkSize?: 'small' | 'wide';
}

export function ContinuityCardOptions({
    offer,
    activated,
    handleActivate,
    continuitytransactiondesc,
    continuitytransactiontotal,
    countinuitytransactioncurrent,
    offerCacheKey,
    sparkSize = 'small',
}: ContinuityCardOptionsProps) {
    const lottieURI =
        sparkSize === 'small'
            ? require('../../../assets/lotties/rewards-enrollment/activate-btn-small-spark.json')
            : require('../../../assets/lotties/rewards-enrollment/activate-btn-wide-spark.json');

    const getProgressValue = (name: string) =>
        name?.includes('$') ? name?.split('$')[1] : name;

    const { analyticsCustomEvent } = useEventsReporter();
    const { userInfo } = useUserInfo();
    const setToastMessage = useSetAtom(toastAtom);

    const [showPlayer, setShowPlayer] = useState(!activated);

    return (
        <AnimatePresence>
            {!activated ? (
                <motion.button
                    className={styles.activateButton}
                    exit={{ opacity: 0 }}
                    onClick={(event) => {
                        event.stopPropagation();
                        if (!activated) {
                            analyticsCustomEvent(eventMetricsMap.easy_rewards, {
                                click_text: 'Start Challenge',
                                element_location:
                                    offer?.offertype === 'TARGETED'
                                        ? 'Offers - For You'
                                        : 'Offers - More Offers',
                                offer_description: offer?.offerdescription
                                    ?.substring(0, 50)
                                    ?.toLocaleLowerCase(),
                                coupon_number: offer?.offerid,
                                event_rewards_number:
                                    userInfo?.rewardsNumber?.padStart(
                                        10,
                                        '0'
                                    ) || '',
                            });
                            updateLoyaltyOfferCache({
                                offerType: offerCacheKey,
                                updater: (oldData) => {
                                    if (oldData) {
                                        const newData: typeof oldData = {
                                            ...oldData,
                                        };
                                        newData.offers.map((item) => {
                                            if (
                                                item.offerid === offer.offerid
                                            ) {
                                                item.activated = true;
                                            }
                                            return item;
                                        });
                                        return newData;
                                    }
                                    return {} as LoyaltyOfferResponse;
                                },
                            });
                            setShowPlayer(true);
                            handleActivate([offer.offerid])
                                .then((res) => {
                                    if (
                                        !res?.data[0] ||
                                        !res?.data[0].success
                                    ) {
                                        throw new Error(
                                            "This offer couldn't be activated, try again."
                                        );
                                    }
                                })
                                .catch(() => {
                                    setToastMessage(
                                        "This offer couldn't be activated, try again."
                                    );
                                    updateLoyaltyOfferCache({
                                        offerType: offerCacheKey,
                                        updater: (oldData) => {
                                            if (oldData) {
                                                const newData: typeof oldData =
                                                    {
                                                        ...oldData,
                                                    };
                                                newData.offers.map((item) => {
                                                    if (
                                                        item.offerid ===
                                                        offer.offerid
                                                    ) {
                                                        item.activated = false;
                                                    }
                                                    return item;
                                                });
                                                return newData;
                                            }
                                            return {} as LoyaltyOfferResponse;
                                        },
                                    });
                                    setShowPlayer(false);
                                });
                        }
                    }}
                >
                    Start challenge
                </motion.button>
            ) : (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className={styles.progressBarContainer}
                >
                    {showPlayer && (
                        <Player
                            autoplay
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                height: '200%',
                                width: '100%',
                            }}
                            src={lottieURI}
                        />
                    )}
                    <progress
                        className={styles.progressBar}
                        value={getProgressValue(countinuitytransactioncurrent)}
                        max={getProgressValue(continuitytransactiontotal)}
                    />
                    <span>
                        <Body
                            display="inline"
                            size={10}
                            weight="bold"
                        >{`${countinuitytransactioncurrent} `}</Body>
                        <Body
                            display="inline"
                            size={10}
                            weight="light"
                        >{`of ${continuitytransactiontotal} ${continuitytransactiondesc}`}</Body>
                    </span>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

interface OfferCardProps {
    offer: LoyaltyOffer;
    setInfoDrawerState: React.Dispatch<
        React.SetStateAction<{
            offer: LoyaltyOffer | null;
            isOpen: boolean;
        }>
    >;
    variant: 'vertical' | 'horizontal';
    offerConditions: string | JSX.Element;
    handleActivate: ActivateOfferMutateAsyncFunc;
    labels: (string | null)[];
    offerCacheKey: 'TARGETED' | 'MASS' | 'FEATURED';
    shopNow?: string | JSX.Element;
    tabIndex?: number;
}

function OfferCard({
    offer,
    setInfoDrawerState,
    variant,
    offerConditions,
    handleActivate,
    labels,
    offerCacheKey,
    shopNow,
    tabIndex,
}: OfferCardProps) {
    const isVerticalVariant = variant === 'vertical';

    const { userInfo } = useUserInfo();
    const { analyticsCustomEvent } = useEventsReporter();

    return (
        <Box
            className={styles[`${variant}CardContainer`]}
            id={offer.offerid}
            tabIndex={tabIndex}
            onClick={() => {
                analyticsCustomEvent(eventMetricsMap.easy_rewards, {
                    click_text: 'info',
                    element_location:
                        offerCacheKey === 'FEATURED'
                            ? 'Featured offers'
                            : offer?.offertype === 'TARGETED'
                            ? 'Offers - For You'
                            : 'Offers - More Offers',
                    coupon_number: offer?.offerid ? offer.offerid : '',
                    event_rewards_number:
                        userInfo?.rewardsNumber?.padStart(10, '0') || '',
                    offer_description:
                        offer?.offerdescription?.toLocaleLowerCase(),
                });
                setInfoDrawerState({
                    offer,
                    isOpen: true,
                });
            }}
        >
            <div>
                <Box className={styles.infoBar}>
                    <OfferTags
                        variant={
                            variant === 'horizontal' ? 'full' : 'truncated'
                        }
                        labels={labels}
                    />
                    <IconInfo />
                </Box>
                <div className={styles[`${variant}CardDetailsSection`]}>
                    <img
                        className={styles[`${variant}CardImg`]}
                        src={offer.digitalviewimage}
                        alt="product"
                    />
                    <div className={styles[`${variant}CardTextSection`]}>
                        <Heading
                            textAlign={
                                isVerticalVariant ? 'textCenter' : 'textLeft'
                            }
                            size={18}
                            type="h4"
                            weight="bold"
                        >
                            {offer.name}
                        </Heading>
                        <Body
                            className={styles.offerDesciption}
                            size={isVerticalVariant ? 14 : 16}
                            textAlign={
                                isVerticalVariant ? 'textCenter' : 'textLeft'
                            }
                            weight="light"
                        >
                            {offer.offerdescription}
                        </Body>
                    </div>
                </div>
                <div
                    className={mergeClasses(
                        styles[`${variant}CardActionSection`],
                        styles.justifySpaceBetween
                    )}
                >
                    <Body
                        size={12}
                        weight="light"
                        color="textMediumGray"
                        textAlign={
                            isVerticalVariant ? 'textCenter' : 'textLeft'
                        }
                    >
                        {offerConditions}
                    </Body>
                    {offer.type === 'CONTINUITY' ? (
                        <ContinuityCardOptions
                            offer={offer}
                            offerCacheKey={offerCacheKey}
                            activated={offer.activated}
                            handleActivate={handleActivate}
                            continuitytransactiondesc={
                                offer.continuitytransactiondesc
                            }
                            continuitytransactiontotal={
                                offer.continuitytransactiontotal
                            }
                            countinuitytransactioncurrent={
                                offer.countinuitytransactioncurrent
                            }
                        />
                    ) : (
                        <DefaultCardOptions
                            offerdescription={offer.offerdescription}
                            offerCacheKey={offerCacheKey}
                            offertype={offer.offertype}
                            offerId={offer.offerid}
                            activated={offer.activated}
                            handleActivate={handleActivate}
                        />
                    )}
                </div>
                <div
                    className={
                        isVerticalVariant
                            ? ''
                            : offer.activated && offer.destinationUrl
                            ? styles.shopNowContainerWrapper
                            : ''
                    }
                >
                    <div
                        className={
                            isVerticalVariant
                                ? styles.fshopNowContainer
                                : styles.shopNowContainer
                        }
                    >
                        {shopNow}
                    </div>
                </div>
            </div>
        </Box>
    );
}

export default OfferCard;
