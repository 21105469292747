import React, { useEffect, useState } from 'react';
import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import ROUTES from 'src/lib/routes';
import useRouter from 'src/hooks/useRouter';
import CreateShippingSenderInformation from './creating-shipping-sender-information';
import CreateShippingRecipientInformation from './creating-shipping-recipient-information';
import CreateShippingPackageInformation from './creating-shipping-package-information';
import CreateShippingReviewInformation from './creating-shipping-review-information';
import CreateShippingQRInformation from './creating-shipping-QR-information';

const useStyles = makeStyles((theme) => ({
    shippingServicesContainer: {
        overflowY: 'hidden',
        height: '100vh',
    },
    CreateShipingHeader: {
        height: '114px',
        display: 'flex',
        flexDirection: 'column',
        background: '#101820',
        alignItems: 'center',
        gap: '15px',
        justifyContent: 'center',
        position: 'sticky',
        zIndex: 1,
    },
    startCreate: {
        height: '30px',
        width: '166px',
        borderRadius: '20px',
        background: '#CC0000',
        marginBottom: '24px',
        '& span': {
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '11.8px',
            color: '#FFFFFF',
            textAlign: 'center',
        },
    },
    CreateShipingHeaderImgTextAlign: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    labelCreate: {
        fontSize: '22px',
        fontWeight: 700,
        lineHeight: '25.96px',
        color: '#FFFFFF',
        letterSpacing: '0px',
    },
    backIconAlign: {
        position: 'absolute',
        left: '20px',
    },
    shippingServicesMainSubContainer: {
        height: 'calc(100vh - 114px)',
        overflow: 'auto',
    },
    shippingServicesQRInfoSubContainer: {
        height: '100%',
        overflow: 'auto',
    },
}));

const CreateShippingContainer = () => {
    const classes = useStyles();
    const { history, pathname, query } = useRouter();
    const [senderInfo, setSenderInfo] = useState({});
    const [recipientInfo, setRecipientInfo] = useState({});
    const [packageInfo, setPackageInfo] = useState({});
    const [qrCodeValue, setQrCodeValue] = useState('');
    const { step } = query;

    const getComponentBasedOnRoute = () => {
        switch (step) {
            case 'createShipmentSenderInfo':
                return (
                    <CreateShippingSenderInformation
                        setSenderInfo={setSenderInfo}
                        senderInfo={senderInfo}
                    />
                );
            case 'CreateShippingRecipientInformation':
                return (
                    <CreateShippingRecipientInformation
                        senderInfo={senderInfo}
                        setSenderInfo={setSenderInfo}
                        setRecipientInfo={setRecipientInfo}
                        recipientInfo={recipientInfo}
                    />
                );
            case 'CreateShippingPackageInformation':
                return (
                    <CreateShippingPackageInformation
                        packageInfo={packageInfo}
                        setPackageInfo={setPackageInfo}
                    />
                );
            case 'CreateShippingReviewInformation':
                return (
                    <CreateShippingReviewInformation
                        senderInfo={senderInfo}
                        recipientInfo={recipientInfo}
                        packageInfo={packageInfo}
                        setQrCodeValue={setQrCodeValue}
                    />
                );
            case 'CreateShippingQRInformation':
                return (
                    <CreateShippingQRInformation qrCodeValue={qrCodeValue} />
                );
            default:
                return null;
        }
    };
    const goBack = () => {
        let search;
        switch (step) {
            case 'createShipmentSenderInfo':
                search = '';
                break;
            case 'CreateShippingRecipientInformation':
                search = 'createShipmentSenderInfo';
                break;
            case 'CreateShippingPackageInformation':
                search = 'CreateShippingRecipientInformation';
                break;
            case 'CreateShippingReviewInformation':
                search = 'CreateShippingPackageInformation';
                break;
            case 'CreateShippingQRInformation':
                search = 'CreateShippingReviewInformation';
                break;
            default:
                search = '';
        }
        if (search) {
            history.push({
                pathname: ROUTES.createShippingInformation,
                search: search ? `step=${search}` : '',
            });
        } else {
            history.push({ pathname: ROUTES.shippingServices });
        }
    };
    return (
        <div className={classes.shippingServicesContainer}>
            {step !== 'CreateShippingQRInformation' && (
                <div className={classes.CreateShipingHeader}>
                    <div className={classes.CreateShipingHeaderImgTextAlign}>
                        <ButtonBase
                            className={classes.backIconAlign}
                            onClick={goBack}
                        >
                            <img
                                src={require('../../../assets/icons/iconBackWhite.svg')}
                                alt="iconBackWhite"
                            />
                        </ButtonBase>
                        <Typography className={classes.labelCreate}>
                            Create a Shipment
                        </Typography>
                    </div>
                </div>
            )}
            <div
                className={
                    step !== 'CreateShippingQRInformation'
                        ? classes.shippingServicesMainSubContainer
                        : classes.shippingServicesQRInfoSubContainer
                }
            >
                {getComponentBasedOnRoute()}
            </div>
        </div>
    );
};

export default CreateShippingContainer;
