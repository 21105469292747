/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from 'react';
import { useSetAtom } from 'jotai';
import { Box } from '@material-ui/core';
import { differenceInDays, format } from 'date-fns';
import { Player } from '@lottiefiles/react-lottie-player';
import {
    Body,
    Button,
    Heading,
    Pill,
    SwipeableDrawer,
} from 'src/components/UI';
import {
    ActivateOfferMutateAsyncFunc,
    LoyaltyOffer,
    LoyaltyOfferResponse,
    updateLoyaltyOfferCache,
} from 'src/services/easyRewardsServices';
import { eventMetricsMap, useEventsReporter, useUserInfo } from 'src/hooks';
import { motion } from 'framer-motion';
import { IconSmallCheckmark } from 'src/components/Icons';
import { useHistory } from 'react-router';
import OfferCard, {
    ContinuityCardOptions,
    DefaultCardOptions,
    toastAtom,
} from './OfferCard';
import OfferTags from './OfferTags';
import styles from './styles.module.scss';
import ROUTES from '../../../lib/routes';
import useRouter from '../../../hooks/useRouter';
import { useTabsUIStateContext } from '../../../lib/contexts/tabs-ui-state-context';

const isMultiUse = (offer: LoyaltyOffer) =>
    offer?.multiuse ? 'MULTI-USE' : null;

const getExpirationTagString = (endDate: string): string | null => {
    if (endDate) {
        const todayDate = new Date();
        const end = new Date(endDate);
        const diffDays = differenceInDays(end, todayDate);

        if (diffDays <= 3 && diffDays > 0) {
            return `${diffDays} DAYS LEFT`;
        }
        if (diffDays === 0) {
            return `EXPIRES TODAY`;
        }
    }
    return null;
};

export const getExpDate = (
    endDate: string,
    channel: string,
    variant?: string,
    pillFlag?: boolean
) => {
    if (endDate) {
        const end = new Date(endDate);

        let offerPlatform = '';
        const expirationDateString = `Exp ${format(end, 'M/dd/yy')}`;

        if (channel.toLowerCase() === 'retail') {
            offerPlatform = 'IN-STORE ONLY';
        } else if (channel.toLowerCase() === 'dotcom') {
            offerPlatform = 'ONLINE ONLY';
        }
        if (pillFlag) {
            return offerPlatform
                ? `${offerPlatform} • ${expirationDateString}`
                : expirationDateString;
        }

        return offerPlatform ? (
            <div
                className={
                    variant === 'vertical'
                        ? styles.showExp
                        : `${styles.showExp} ${styles.showExpVer}`
                }
            >
                <Pill
                    className={
                        variant === 'vertical'
                            ? `${styles.pillOffer} ${styles.showExpDate}`
                            : styles.pillOffer
                    }
                >
                    {offerPlatform}
                </Pill>
                <span className={styles.expDateText}>
                    {expirationDateString}
                </span>
            </div>
        ) : (
            <>
                {/* {veriant === 'vertical' ? (<div className={styles.pillPlaceHolder} />) : ''} */}
                <span>{expirationDateString}</span>
            </>
        );
    }
    return '';
};

export const initialInfoDrawerState: {
    offer: LoyaltyOffer | null;
    isOpen: boolean;
} = {
    offer: null,
    isOpen: false,
};

interface scrollToOfferProps {
    offerId: string;
    isScroll: boolean;
}
interface InfoDrawerOfferOptionsProps {
    offer: LoyaltyOffer;
    handleActivate: ActivateOfferMutateAsyncFunc;
    offerCacheKey: 'TARGETED' | 'MASS' | 'FEATURED';
}

function InfoDrawerOfferOptions({
    offer,
    handleActivate,
    offerCacheKey,
}: InfoDrawerOfferOptionsProps) {
    if (offer.type === 'CONTINUITY') {
        return (
            <ContinuityCardOptions
                offer={offer}
                offerCacheKey={offerCacheKey}
                activated={offer.activated}
                handleActivate={handleActivate}
                continuitytransactiondesc={offer.continuitytransactiondesc}
                continuitytransactiontotal={offer.continuitytransactiontotal}
                countinuitytransactioncurrent={
                    offer.countinuitytransactioncurrent
                }
                sparkSize="wide"
            />
        );
    }

    return (
        <DefaultCardOptions
            offername={offer.name}
            offerdescription={offer.offerdescription}
            offerCacheKey={offerCacheKey}
            offertype={offer.offertype}
            offerId={offer.offerid}
            activated={offer.activated}
            handleActivate={handleActivate}
            sparkSize="wide"
        />
    );
}

interface OfferListProps {
    variant: 'vertical' | 'horizontal';
    data?: LoyaltyOffer[];
    isDataLoading?: boolean;
    activateOffer: ActivateOfferMutateAsyncFunc;
    infoDrawerData?: typeof initialInfoDrawerState;
    setinfoDrawerData?: React.Dispatch<
        React.SetStateAction<{
            offer: LoyaltyOffer | null;
            isOpen: boolean;
        }>
    >;
    scrollToOffer?: scrollToOfferProps;
    setScrollToOffer?: (scrollData: scrollToOfferProps) => void;
    offerCacheKey: 'TARGETED' | 'MASS' | 'FEATURED';
    isMainRewardsPage?: boolean;
    strtIndex?: number;
}

function OfferList({
    variant,
    data = [],
    isDataLoading,
    activateOffer,
    infoDrawerData,
    setinfoDrawerData,
    scrollToOffer,
    setScrollToOffer,
    offerCacheKey,
    isMainRewardsPage,
    strtIndex = 0,
}: OfferListProps) {
    const [infoDrawerState, setInfoDrawerState] = useState(
        initialInfoDrawerState
    );
    const { setShopLinkInNative } = useTabsUIStateContext();
    const { analyticsCustomEvent } = useEventsReporter();
    const { userInfo } = useUserInfo();

    const history = useHistory();
    const sortedData = useMemo(
        () => data?.sort((item1) => (item1.type === 'CONTINUITY' ? -1 : 0)),
        [data]
    );
    const { push } = useRouter();

    const scrollToElement = (id: string) => {
        const element = document.getElementById(id);
        element?.scrollIntoView({
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        if (
            infoDrawerData?.isOpen &&
            !(offerCacheKey === 'MASS' && isMainRewardsPage)
        ) {
            // scrollToElement(`${infoDrawerData?.offer?.offerid}`)
            setInfoDrawerState(infoDrawerData);
            setinfoDrawerData?.({
                offer: null,
                isOpen: false,
            });
        }
    }, [infoDrawerData?.isOpen]);

    useEffect(() => {
        if (infoDrawerState.isOpen) {
            // scrollToElement(`${infoDrawerData?.offer?.offerid}`)
            const unblock = history.block(() => {
                setInfoDrawerState({
                    offer: null,
                    isOpen: false,
                });
                return false;
            });
            return () => {
                unblock();
            };
        }
        return undefined;
    }, [infoDrawerState.isOpen]);

    useEffect(() => {
        if (scrollToOffer?.isScroll) {
            scrollToElement(scrollToOffer?.offerId);
            setScrollToOffer?.({
                offerId: '',
                isScroll: false,
            });
        }
    }, [scrollToOffer?.isScroll]);

    if (isDataLoading) {
        return (
            <div>
                <Player
                    autoplay
                    loop
                    src={require('../../../assets/lotties/stapleRevealWordmark.json')}
                    style={{
                        height: '100px',
                    }}
                />
            </div>
        );
    }

    if (!data?.length) {
        return (
            <Body weight="bold" size={18}>
                Your have no offers at this time, please try agin later.
            </Body>
        );
    }

    const handleButtonClick = (offer: LoyaltyOffer | null) => {
        // Ensure no 'info' event is triggered by the shop now button click
        setInfoDrawerState(initialInfoDrawerState); // This resets the info drawer

        setTimeout(() => {
            push(ROUTES.shop); // Navigate to shop route
            const setUrl = infoDrawerState.offer?.destinationUrl?.replace(
                'https://www.staples.com',
                ''
            ) as string;

            // Update the native shop link
            setShopLinkInNative({
                src: setUrl,
                show: true,
                route: ROUTES.rewardsWallet,
            });
            console.log('pushed');
        });
        analyticsCustomEvent(eventMetricsMap.easy_rewards, {
            click_text: 'shop now',
            element_location:
                offerCacheKey === 'FEATURED'
                    ? 'Featured Offers'
                    : offer?.offertype === 'TARGETED'
                    ? 'Offers - For You'
                    : 'Offers - More Offers',
            offer_description: `${offer?.name} ${offer?.offerdescription}`
                ?.substring(0, 50)
                ?.toLocaleLowerCase(),
            coupon_number: offer?.offerid,
            event_rewards_number:
                userInfo?.rewardsNumber?.padStart(10, '0') || '',
        });
    };
    const getShopNowBtn = (offer: LoyaltyOffer) => {
        return offer.activated && offer.destinationUrl ? (
            <>
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{
                        opacity: 1,
                    }}
                    animate={{ x: 0 }}
                    transition={{ ease: 'easeOut', duration: 1 }}
                >
                    <Button
                        variant="contained-rounded"
                        size="medium"
                        className={
                            variant === 'horizontal'
                                ? styles.shopBtnv
                                : styles.shopBtn
                        }
                        onClick={() => {
                            push(ROUTES.shop);
                            const setUrl = offer?.destinationUrl?.replace(
                                'https://www.staples.com',
                                ''
                            ) as string;
                            setShopLinkInNative({
                                src: setUrl,
                                show: true,
                                route: ROUTES.rewardsWallet,
                            });
                            analyticsCustomEvent(eventMetricsMap.easy_rewards, {
                                click_text: 'shop now',
                                element_location:
                                    offerCacheKey === 'FEATURED'
                                        ? 'Featured Offers'
                                        : offer?.offertype === 'TARGETED'
                                        ? 'Offers - For You'
                                        : 'Offers - More Offers',
                                offer_description:
                                    `${offer?.name} ${offer?.offerdescription}`
                                        ?.substring(0, 50)
                                        ?.toLocaleLowerCase(),
                                coupon_number: offer?.offerid,
                                event_rewards_number:
                                    userInfo?.rewardsNumber?.padStart(
                                        10,
                                        '0'
                                    ) || '',
                            });
                        }}
                    >
                        <Body textAlign="textCenter" size={14} weight="bold">
                            Shop now
                        </Body>
                    </Button>
                </motion.div>
            </>
        ) : (
            ''
        );
    };

    return (
        <>
            <ul
                className={styles[`${variant}ListContainer`]}
                style={{ overflow: variant === 'vertical' ? 'hidden' : 'auto' }}
            >
                {sortedData
                    ?.sort((a, b) => a.ranking - b.ranking)
                    ?.map((offer, i) => (
                        <OfferCard
                            key={offer.offerid}
                            setInfoDrawerState={setInfoDrawerState}
                            offer={offer}
                            offerCacheKey={offerCacheKey}
                            tabIndex={Math.max(strtIndex + i)}
                            labels={[
                                isMultiUse(offer),
                                getExpirationTagString(offer.enddate),
                            ]}
                            variant={
                                variant === 'horizontal'
                                    ? 'vertical'
                                    : 'horizontal'
                            }
                            offerConditions={getExpDate(
                                offer.enddate,
                                offer.channel,
                                variant
                            )}
                            shopNow={getShopNowBtn(offer)}
                            handleActivate={activateOffer}
                        />
                    ))}
            </ul>
            <SwipeableDrawer
                anchor="bottom"
                open={infoDrawerState.isOpen}
                onClose={() => setInfoDrawerState(initialInfoDrawerState)}
                onOpen={() =>
                    setInfoDrawerState((currentInfoDrawerState) => ({
                        ...currentInfoDrawerState,
                        isOpen: true,
                    }))
                }
            >
                {infoDrawerState.offer && (
                    <Box className={styles.infoWrapper}>
                        <OfferTags
                            variant="full"
                            labels={[
                                isMultiUse(infoDrawerState.offer),
                                getExpirationTagString(
                                    infoDrawerState.offer.enddate
                                ),
                            ]}
                        />
                        <div className={styles.infoOfferDetailsSection}>
                            <img
                                className={styles.infoOfferImage}
                                src={infoDrawerState.offer.digitalviewimage}
                                alt="product"
                            />
                            <div>
                                <Heading
                                    textAlign="textLeft"
                                    size={26}
                                    type="h4"
                                    weight="bold"
                                >
                                    {infoDrawerState.offer.name}
                                </Heading>
                                <Body
                                    size={16}
                                    textAlign="textLeft"
                                    weight="light"
                                >
                                    {infoDrawerState.offer.offerdescription}
                                </Body>
                            </div>
                            <Body size={14} color="textMediumGray">
                                {getExpDate(
                                    infoDrawerState.offer.enddate,
                                    infoDrawerState.offer.channel,
                                    'horizontal',
                                    false
                                )}
                            </Body>
                            <InfoDrawerOfferOptions
                                handleActivate={activateOffer}
                                offer={infoDrawerState.offer}
                                offerCacheKey={offerCacheKey}
                            />
                            {infoDrawerState.offer.destinationUrl &&
                                infoDrawerState.offer.activated && (
                                    <Button
                                        variant="contained-rounded"
                                        fontSize={19}
                                        className={styles.shopBtnInfo}
                                        onClick={() =>
                                            handleButtonClick(
                                                infoDrawerState.offer
                                            )
                                        }
                                    >
                                        Shop now
                                    </Button>
                                )}
                        </div>
                        <p>
                            <strong>Offer Disclaimer:</strong>{' '}
                            {infoDrawerState.offer.offerdisclaimer}
                        </p>
                    </Box>
                )}
            </SwipeableDrawer>
        </>
    );
}
export default OfferList;
