/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { Box, SwipeableDrawer, Typography } from '@material-ui/core';
import ScrollableRow from 'src/components/scrollable-row';
import { format, differenceInDays } from 'date-fns';
import Button from 'src/components/UI/Button/Button';
import { Player } from '@lottiefiles/react-lottie-player';
import useDragScroll from 'src/hooks/useDragScroll';
import { IconSmallCheckmark } from 'src/components/Icons';
import { eventMetricsMap, useEventsReporter, useUserInfo } from 'src/hooks';
import { Body, Pill } from 'src/components/UI';
import styles from './styles.module.scss';

export function InfoDrawer({ openInfo, currentMode, offerdisclaimer }: any) {
    const [state, setState] = React.useState(openInfo);

    const toggleDrawer = (open: boolean) => {
        setState(open);
        currentMode(open);
    };

    useEffect(() => {
        toggleDrawer(openInfo);
    }, [openInfo]);

    return (
        <div>
            <SwipeableDrawer
                anchor="bottom"
                open={state}
                onClose={() => toggleDrawer(false)}
                onOpen={() => toggleDrawer(true)}
            >
                <Box className={styles.infoWrapper}>
                    <div className={styles.indicator}> </div>
                    <p>
                        <strong> Offer Disclaimer:</strong> {offerdisclaimer}
                    </p>
                </Box>
            </SwipeableDrawer>
        </div>
    );
}

const getChannelText = (channel: string) => {
    switch (channel) {
        case 'retail':
            return <Pill className={styles.pillOffer}>IN STORE ONLY</Pill>;
        case 'dotcom':
            return <Pill className={styles.pillOffer}>ONLINE ONLY</Pill>;
        default:
            return '';
    }
};

export const getExpDate = (
    endDate: string,
    channel: string,
    dateFormat = 'MM/dd/yy'
) => {
    if (!endDate) {
        return '';
    }

    const todayDate = new Date();
    const end = new Date(endDate);
    const diffDays = differenceInDays(end, todayDate);
    const channelText = getChannelText(channel.toLocaleLowerCase());

    if (diffDays > 30) {
        return (
            <Body
                textAlign="textCenter"
                weight="normal"
                size={12}
                className={styles.textWrap}
            >
                {channelText && (
                    <Body display="inline" weight="normal" size={12} className={styles.showExpDate} ariaLabel={channelText?.props?.children}>
                        {channelText}
                    </Body>
                )}
                <Body ariaLabel={`Offer Expire on ${format(
                    end,
                    dateFormat
                )}`} className={styles.showExpDate} display="inline" weight="light" size={12}>{`Exp ${format(
                    end,
                    dateFormat
                )}`}</Body>
            </Body>
        );
    }

    if (diffDays < 0) {
        return (
            <Body textAlign='textCenter' weight='normal' size={12} className={styles.textWrap}>
                {channelText && <Body display='inline' className={styles.pillSection} weight='normal' size={12}>{channelText}</Body>}
                <Body className={styles.pillSection} display='inline' weight='light' size={12}>{`Exp ${Math.abs(diffDays)} days ago`}</Body>
            </Body>
        );
    }
    return (
        <Body textAlign='textCenter' weight='normal' size={12} className={styles.textWrap}>
            {channelText && <Body display='inline' weight='normal' className={styles.pillSection} size={12}>{channelText}</Body>}
            <Body className={styles.pillSection} display='inline' weight='light' size={12}>{`Exp ${format(end, dateFormat)}`}</Body>
        </Body>
    );
};

// TODO define types here
function FeaturedList({ featureOffer, offerActivate }: any) {
    const { analyticsCustomEvent } = useEventsReporter();
    const [offerdisclaimer, setOfferdisclaimer] = useState('');
    const [offerList, setOfferList] = useState(featureOffer);
    const [openState, setOpenState] = React.useState(false);
    const [btnAnimate, setBtnAnimate] = useState<null | string>(null);
    const { userInfo } = useUserInfo();

    /* const handleClick = (index: number) => {
        setActiveIndex(index);
    }; */
    const scrollProps = useDragScroll();
    // TODO define types here
    const toggleInfoDrawer = (item: any) => {
        setOfferdisclaimer(item.offerdisclaimer);
        setOpenState(true);
    };

    // TODO define types here
    const handleActive = (offer: any) => {
        if (!offer.activated) {
            setBtnAnimate(offer.offerid);
            offer.activated = true;
            const changeIndex = offerList.findIndex(
                (item: any) => item.offerid === offer.offerid
            );
            offerList[changeIndex] = offer;
            setOfferList([...offerList]);
            offerActivate([offer.offerid]); // to activate all offers
        }
    };

    // TODO define types here
    const handleActiveBtnRef = (event: any) => {
        if (event === 'complete') {
            setBtnAnimate(null);
        }
    };

    return (
        <>
            <Typography className={styles.heading}>Featured offers</Typography>
            <ScrollableRow
                id="available-shipping-products-hour"
                scrollProps={scrollProps}
            >
                <div style={{ display: 'flex', margin: '10px 0' }}>
                    {offerList.map((item: any, index: number) => (
                        <Box
                            className={styles.slideItem}
                            key={item.offerid}
                            id={`onboarding-swipeable-box-${index}`}
                        >
                            <Box className={styles.wrapper}>
                                {item?.multiuse && (
                                    <div
                                        className={styles.multiUse}
                                        onClick={() => {
                                            analyticsCustomEvent(
                                                eventMetricsMap.easy_rewards,
                                                {
                                                    click_text: 'Activate',
                                                    element_location:
                                                        'Featured offers',
                                                    coupon_number: item.offerid,
                                                    event_rewards_number:
                                                        userInfo?.rewardsNumber ||
                                                        '',
                                                }
                                            );
                                            toggleInfoDrawer(item);
                                        }}
                                    >
                                        MULTI-USE
                                    </div>
                                )}
                                <div
                                    className={styles.infoIcon}
                                    onClick={() => {
                                        analyticsCustomEvent(
                                            eventMetricsMap.easy_rewards,
                                            {
                                                click_text: 'Activate',
                                                element_location:
                                                    'Featured offers',
                                                coupon_number: item.offerid,
                                                event_rewards_number:
                                                    userInfo?.rewardsNumber ||
                                                    '',
                                            }
                                        );
                                        toggleInfoDrawer(item);
                                    }}
                                >
                                    <img
                                        id={`onboarding-swipeable-elem-image-${index}`}
                                        src={require('../../assets/screenshots/infoIcon.svg')}
                                        alt="onboarding introduction"
                                    />
                                </div>
                                <div className={styles.slideImage}>
                                    <img
                                        id={`onboarding-swipeable-elem-image-${index}`}
                                        src={
                                            item?.imagepng ||
                                            require('../../assets/icons/placeholderImage.svg')
                                        }
                                        alt="onboarding introduction"
                                    />
                                </div>
                                <div className={styles.content}>
                                    <Body
                                        weight={'bold'}
                                        size={18}
                                        textAlign="textCenter"
                                        className={styles.title1}
                                    >
                                        {item?.name}
                                    </Body>
                                    <Body
                                        size={14}
                                        weight={'light'}
                                        textAlign="textCenter"
                                        className={styles.description}
                                    >
                                        {item?.offerdescription}
                                    </Body>
                                    <div className={styles.activeButton}>
                                        {btnAnimate === item.offerid ? (
                                            <Player
                                                onEvent={(event) =>
                                                    handleActiveBtnRef(event)
                                                }
                                                className={styles.playerBtn}
                                                autoplay
                                                src={require('../../assets/lotties/activate_button.json')}
                                            />
                                        ) : (
                                            <Button
                                                variant={'outlined-rounded'}
                                                className={
                                                    item?.activated &&
                                                    styles.activeButtonText
                                                }
                                                borderColor={
                                                    item?.activated
                                                        ? 'borderNone'
                                                        : 'borderPurple'
                                                }
                                                size="small"
                                                onClick={() => {
                                                    analyticsCustomEvent(
                                                        eventMetricsMap.easy_rewards,
                                                        {
                                                            click_text:
                                                                'Activate',
                                                            element_location:
                                                                'Featured offers',
                                                            coupon_number:
                                                                item.offerid,
                                                            event_rewards_number:
                                                                userInfo?.rewardsNumber ||
                                                                '',
                                                        }
                                                    );
                                                    handleActive(item);
                                                }}
                                            >
                                                {item?.activated ? (
                                                    <Body
                                                        textAlign="textCenter"
                                                        size={12}
                                                        weight="bold"
                                                    >
                                                        <IconSmallCheckmark />
                                                        Activated
                                                    </Body>
                                                ) : (
                                                    <Body
                                                        textAlign="textCenter"
                                                        size={12}
                                                        weight="bold"
                                                    >
                                                        Activate
                                                    </Body>
                                                )}
                                            </Button>
                                        )}
                                    </div>
                                    {/* <Body textAlign='textCenter' weight='light' size={12} className={styles.available1}> */}
                                    {getExpDate(item.enddate, item.channel)}
                                    {/* </Body> */}
                                </div>
                            </Box>
                        </Box>
                    ))}
                </div>
                <InfoDrawer
                    offerdisclaimer={offerdisclaimer}
                    openInfo={openState}
                    currentMode={(open: boolean) => setOpenState(open)}
                />
            </ScrollableRow>
        </>
    );
}
export default FeaturedList;
