/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import NavTop from 'src/components/nav-top';
import { Box, InputAdornment } from '@material-ui/core';
import FullWidthCard from 'src/components/cards/full-width-card';
import clsx from 'clsx';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useUserLoginUpdateContextState } from 'src/lib/contexts/user-login-context';
import ValidationTextField from 'src/components/validation-text-field';
import LoadingButton from 'src/components/loading-button';
import EmailAutoComplete from 'react-autocomplete-email';
import { SessionManager } from 'src/lib/api-client/sessionManager';
import { v4 as uuidv4 } from 'uuid';
import { loginUser } from 'src/lib/api-client/sign-on';
import { atom, useAtom } from 'jotai';
import {
    IconPasswordClose,
    IconPasswordOpen,
    IconStaplesLogo,
} from 'src/components/Icons';
import { Button, Body, Heading } from 'src/components/UI';
import { COACH_SCREENS_ROUTES, LOGIN_ROUTES } from 'src/routers/routes';
import {
    useRouter,
    hardReplace,
    useUserState,
    eventMetricsMap,
    useEventsReporter, useStoreData,
} from 'src/hooks';
import ROUTES from 'src/lib/routes';
import CommonDialog from 'src/components/common-dialog';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import Captcha from 'src/components/login-flow/captcha';
import { obfuscateUserEmail } from 'src/utils/userUtils';
import { customDomains } from 'src/lib/constants';
// import TermCondition from './TermCondition';
import useAdobeAnalytics from 'src/hooks/use-adobe-analytics';
import { useCartCountContext } from 'src/lib/contexts/cart-count-context';
import { mergeCartDetails } from 'src/lib/api-client/cart-api';
import COLORS from 'src/lib/colors';
import styles from './styles.module.scss';
import ShopAsGuest from './ShopAsGuest';

// const customDomains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com'];
//  eslint-disable-next-line max-len
const passwordReg =
    /(?=.{8,50})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/;
const passwordRegxValidationMsg =
    '8 characters minimum, case sensitive, no spaces and a special character or number.';
const validationSchemaFields = yup.object({
    email: yup
        .string()
        .required('This field is required'),
    password: yup
        .string()
        .required('Password is required field.')
        .min(8, 'Password must contain at least 8 characters.'),
});

const payload = { value: '', type: '' };

function AccountValid({ showInModal, setNextScreen, postLoginBehavior }: any) {
    const { replace } = useRouter();
    const [isLoading, setLoading] = useState(false);
    const initialRef: any = null;
    const [userState, setUserState] = useUserState();
    const { analyticsCustomEvent, analyticsScreenCustomNames } =
        useEventsReporter();

    const { push } = useRouter();
    const { mainStore } = useStoreData();

    const emailAutoCompleteRef = useRef(initialRef);
    const { errorMessage } = useUserLoginUpdateContextState();
    // const { userInfo, isFetchingUserInfo } = useUserInfo();
    const [isPasswordShow, setPasswordShow] = useState(false);
    const [captchaHtml, setCaptchaHtml] = useState('');
    const { activeIframeId } = useCartCountContext();
    const [fieldFocus, setfieldFocus] = useState('');

    const [nuSessionId] = useState(uuidv4());
    const [loginErrorMessage, setLoginErrorMessage] = useState(
        errorMessage || ''
    );
    const { setLoginCaptchaResData, loginCaptchaRes } = useUserUpdateContext();
    const { loginFailedDataToAdobe, loginSuccessDataToAdobe } =
        useAdobeAnalytics();

    const [attemptedAccountCredential, setAttemptedAccountCredential] =
        useState<any>(payload);

    const [showList, setShowList] = useState(false);
    const [query, setQuery] = useState('');
    const formikFieldValue = useFormik({
        initialValues: {
            email: userState?.email || '',
            password: '',
        },
        validationSchema: validationSchemaFields,
        // validateOnMount: false,
        onSubmit: (values) => {
            setAttemptedAccountCredential(payload);
            // eslint-disable-next-line
            login();
        },
    });
    const [userEmailId, setUserEmailId] = useState(
        formikFieldValue.values.email
    );
    const { handleSubmit } = formikFieldValue;
    const emailInputHasError =
        formikFieldValue.touched.email &&
        Boolean(formikFieldValue.errors.email);

    useEffect(() => {
        if (loginCaptchaRes?.email) {
            formikFieldValue.setValues({
                email: loginCaptchaRes?.email,
                password: loginCaptchaRes?.key,
            });
            setCaptchaHtml(loginCaptchaRes?.captcha);
            setLoginCaptchaResData({
                email: '',
                key: '',
                captcha: '',
            });
        }
    }, [loginCaptchaRes]);

    const handleForgotPassword = () => {
        const pathname = '/forgot-pwd';
        if (userState?.email) {
            localStorage.setItem('pwdEmail', userState?.email);
        }
        if (showInModal) {
            setNextScreen(pathname);
        } else {
            push(pathname);
        }
        analyticsCustomEvent(eventMetricsMap.sign_in_page, {
            click_text: 'forgot password',
            element_location: 'account sign-in',
        });
    };
    const handleOnBlur = (e: string) => {
        if (loginErrorMessage) {
            setLoginErrorMessage('');
        }
        if (e) {
            formikFieldValue.setTouched({
                ...formikFieldValue.touched,
                [e]: true,
            });
            setfieldFocus('')
        }
    };

    const handleOnFocus = (e: string) => {

        if (loginErrorMessage) {
            setLoginErrorMessage('');
        }
        if (e) {
            setfieldFocus(e)
        }

    };
    const refreshWebView = () => {
        const documentData = document?.getElementById?.(
            activeIframeId
        ) as HTMLIFrameElement;
        if (activeIframeId && documentData) {
            documentData?.contentWindow?.location?.reload();
        }
    };
    const login = async () => {
        const { email, password } = formikFieldValue.values;
        const stplSessionId = nuSessionId;
        const nPayload = (
            document?.getElementsByName('nds-pmd')[0] as HTMLInputElement
        )?.value;

        const nuCaptchaAnswer = (document.getElementById(
            'nucaptcha-answer'
        ) as HTMLInputElement)
            ? (document.getElementById('nucaptcha-answer') as HTMLInputElement)
                .value
            : '';
        const nuCaptchaToken = (document.getElementById(
            'nucaptcha-token'
        ) as HTMLInputElement)
            ? (document.getElementById('nucaptcha-token') as HTMLInputElement)
                .value
            : '';
        if (!email || !password) {
            return null;
        }
        setLoading(true);
        SessionManager.session?.killSession();

        const response: any = await loginUser({
            userName: email,
            key: password,
            rememberMe: true,
            payload: nPayload,
            nuCaptchaAnswer,
            nuCaptchaToken,
            stplSessionId,
        });
        setLoading(false);
        const captchaInRes =
            response?.data?.captchaResponse?.captchaResponseHtml;
        if (captchaInRes) {
            setLoading(false);
            return setCaptchaHtml(
                response?.data?.captchaResponse?.captchaResponseHtml
            );
        }
        if (!response.status) {
            if (response.message === 'User Not Migrated') {
                // router.history.push(ROUTES.passwordReset);
                return null;
            }
            analyticsCustomEvent(eventMetricsMap.sign_in_page, {
                click_text: 'Login - Failure',
                element_location: 'account sign-in',
            });
            if (response?.message?.includes('we are requiring you to reset your password')) {
                formikFieldValue.setFieldError('password', 'As part of ongoing security efforts, we are requiring you to reset your password to help us better protect your account information.')
            } else {
                setLoginErrorMessage(response.message || 'Unable to log in.Please try again');
            }
            loginFailedDataToAdobe(
                response?.error?.response?.status,
                response?.message
            );
        } else if (response.status) {
            analyticsCustomEvent(eventMetricsMap.sign_in_page, {
                click_text: 'Login - Success',
                element_location: 'account sign-in',
            });
            analyticsScreenCustomNames({
                logged_in: true,
            });
            analyticsScreenCustomNames({
                user_id: response?.data?.sub,
            });
            loginSuccessDataToAdobe();
            sessionStorage.removeItem('guest');
            if (showInModal) {
                await mergeCartDetails();
                if (postLoginBehavior === 'go-to-cart') {
                    hardReplace(ROUTES.cart);
                } else if (postLoginBehavior === 'onboarding') {
                    replace(COACH_SCREENS_ROUTES.location);
                } else if (activeIframeId) {
                    refreshWebView();
                }
                setNextScreen?.('closeInModal');
            } else {
                hardReplace(COACH_SCREENS_ROUTES.location);
                setNextScreen?.('closeInModal');
            }
        }
        return null;
    };
    const editEmail = () => {
        if (!showInModal) {
            replace(LOGIN_ROUTES.lookup);
        } else {
            setNextScreen(LOGIN_ROUTES.lookup);
        }
    };

    const TextMaskCustom = (props: any) => {
        const { ...other } = props;
        return (
            <p
                className={`${styles.maskValWidth} ${other?.value && styles.maskVal
                    }`}
            >
                {obfuscateUserEmail(other?.value)}
            </p>
        );
    };

    const handleNavigateToCreateAccount = () => {
        if (!showInModal) {
            push(LOGIN_ROUTES.create)
        } else {
            setNextScreen(LOGIN_ROUTES.create);
        }
    }
    const clearError = (e: any) => {
        const fieldName = e.type === 'focus' ? e.target.name : '';
        formikFieldValue.setFieldError(fieldName, '');
    };

    const handleSuggetion = (val: any) => {
        if (val?.includes('@')) {
            setShowList(true);
            const index = val.indexOf('@');
            const domainName = val.substring(index);
            setQuery(domainName);
        } else {
            setShowList(false);
        }
    };

    const getEmailData = customDomains
        .filter((email) =>
            email.toLowerCase().includes(query.toLowerCase())
        ) || [];

    const emailList = (
        <ul className={styles.emailAutoComplete}>
            {getEmailData?.map((email) => (
                // eslint-disable-next-line
                <li
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                    role="link"
                    className={styles.emailItem}
                    onClick={() => {
                        const emailFieldVal = formikFieldValue.values.email;
                        const index = emailFieldVal.indexOf('@');
                        const clearedEmail = emailFieldVal.substring(
                            0,
                            index
                        );
                        // eslint-disable-next-line
                        formikFieldValue.values.email = clearedEmail + email;
                        setShowList(false);
                    }}
                >
                    {formikFieldValue.values.email.substring(
                        0,
                        formikFieldValue.values.email.indexOf('@')
                    )}{email}
                </li>
            ))}
        </ul>
    );

    const isEmailFieldError = formikFieldValue.touched.email && Boolean(formikFieldValue.errors.email)
    const isPasswordFieldError = formikFieldValue.touched.password && Boolean(formikFieldValue.errors.password)

    return (
        <Box
            className={styles.screen}
            style={{
                justifyContent: 'flex-start',
                height: showInModal ? '85vh' : 'auto',
            }}
        >
            <img src={require('../../assets/icons/staplesLogoRed.svg')} alt='staples logo' className={styles.logoAlign} />

            <div className={styles.loginContainer}>
                <Heading
                    size={26}
                    weight="bold"
                    type="h2"
                    className={styles.title}
                >
                    Sign in
                </Heading>
                <form
                    id="signInForm"
                    onSubmit={handleSubmit}
                    className={styles.form}
                >
                    <div className={styles.fieldContainer}>
                        <label htmlFor="email" className={styles.labelAlign}>
                            Email address or username
                        </label>
                        <input
                            type="email"
                            id="email"
                            value={formikFieldValue.values.email}
                            onChange={(e) => {
                                formikFieldValue.handleChange(e);
                                emailAutoCompleteRef?.current?.change(e);
                                handleSuggetion(e.target.value);
                            }}
                            onBlur={() => handleOnBlur('email')}
                            onFocus={() => handleOnFocus('email')}
                            style={{
                                border: isEmailFieldError ? '1px solid red' : '1px solid #ccc',
                                boxShadow: isEmailFieldError ? '0px 0px 0px 4px #CC00001A' : 'none',
                            }}
                            className={fieldFocus === 'email' ? styles.inputFieldActive : styles.inputFieldAlign}
                            required
                        />
                        {showList && emailList}

                        {isEmailFieldError ? (
                            <Body className={styles.errorAlign} margin='5px 0 0 0' size={12} lineHeight={16} weight='light'>
                                {formikFieldValue.errors.email} </Body>
                        ) : null}
                    </div>

                    <div className={styles.fieldContainer} style={{ position: 'relative' }}>
                        <label htmlFor="password" className={styles.labelAlign}>
                            Password
                        </label>
                        <input
                            type={!isPasswordShow ? 'password' : 'text'}
                            id="password"
                            value={formikFieldValue.values.password}
                            onChange={(e) => {
                                formikFieldValue.handleChange(e);

                            }}
                            onBlur={() => handleOnBlur('password')}
                            onFocus={() => handleOnFocus('password')}
                            style={{
                                border: isPasswordFieldError ? '1px solid red' : '1px solid #ccc',
                                boxShadow: isPasswordFieldError ? '0px 0px 0px 4px #CC00001A' : 'none',

                            }}
                            className={fieldFocus === 'password' ? styles.inputFieldActive : styles.inputFieldAlign}
                            required
                        />

                        <Button
                            ariaLabel="Show password"
                            onClick={() =>
                                setPasswordShow(
                                    !isPasswordShow
                                )
                            }
                            variant="icon"
                            className={styles.alignPasswordShowHide}
                        >
                            {isPasswordShow ? (
                                <IconPasswordOpen />
                            ) : (
                                <IconPasswordClose />
                            )}

                        </Button>

                        {isPasswordFieldError ? (
                            <div style={{ gap: '5px', maxWidth: '299px', display: 'inline-block' }}>
                                <Body className={styles.errorAlign} margin='5px 0 0 0' size={12} lineHeight={16} weight='light'>
                                    {formikFieldValue.errors.password}

                                    {formikFieldValue.errors.password?.includes('we are requiring you to reset your password') &&
                                        <Button variant='text' fontSize={12} fontWeight='light' className={styles.linkAlign}
                                            onClick={handleForgotPassword}> Reset password now</Button>}
                                </Body>
                            </div>
                        ) : null}
                    </div>


                    <LoadingButton
                        id="loginButton"
                        variant="contained"
                        fullWidth
                        loading={isLoading}
                        classes={{
                            root: clsx(styles.loginButton),
                        }}
                        type="submit"
                        disabled={
                            formikFieldValue.errors &&
                            Object.keys(formikFieldValue.errors).length !==
                            0
                        }
                        ariaLabel="Sign in"
                    >
                        Sign in
                    </LoadingButton>
                </form>
                <div className={styles.btnContainer}>
                    <Button
                        className={styles.mt2}
                        align="alignSelfStart"
                        variant="text"
                        color="textLinkBlue"
                        onClick={handleForgotPassword}
                        ariaLabel="Don't know your password ?"
                    >
                        <Body size={14} weight="normal">
                            Don’t know your password?
                        </Body>
                    </Button>
                    <hr className={styles.dividerLine} />
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>

                        <Button
                            className={styles.guest}
                            variant="text"
                            color="textLinkBlue"
                            onClick={handleNavigateToCreateAccount}
                        >
                            <Body size={14} weight="normal" >
                                Create an account
                            </Body>
                        </Button>
                        {!showInModal && <div style={{ marginTop: '0px', color: '#585858' }}> or </div>}
                        {!showInModal && <ShopAsGuest referrer="Sign In" />}
                    </div>
                </div>


            </div >

            <Captcha
                capthaHtml={captchaHtml}
                sessionId={nuSessionId}
                onCaptchaSumbit={login}
                placement="LoginWebSC"
            />
            <CommonDialog
                open={!!loginErrorMessage}
                onClose={() => setLoginErrorMessage('')}
                title="We couldn't sign you in"
                message={loginErrorMessage}
                buttonText="Try again"
            />
        </Box >
    );
}

export default AccountValid;