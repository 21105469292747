/* eslint-disable max-len */
/* eslint-disable jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import IconStore from 'src/components/Icons/IconStore';
import { IconEasyRewardsHorizontal, IconInfo, IconClose, IconCalculator, IconStaplesRound, IconRecentPurchase, IconWeeklyAd, IconInfoWhite,
  IconScanner, IconDollarCoin, IconPeople, IconProfile} from 'src/components/Icons';
import { Body, Button, Heading } from 'src/components/UI';
import useStoreData from 'src/hooks/useStoreData';
import useCardCanvas from 'src/hooks/useCardCanvas';
import { storeCommunity } from 'src/lib/utils/store-utils';
import SearchForProduct from 'src/components/search-for-product-home';
import FullWidthCard from 'src/components/cards/full-width-card';
import Brcd from 'src/components/BarcodeScanner/Brcd';
import { useRouter, useUserInfo } from 'src/hooks';
import layout from 'src/stylesheets/layout_classes.module.scss';
import styleUtil from 'src/stylesheets/utilities.module.scss';
import colors from 'src/stylesheets/color_classes.module.scss';
import { mergeClasses } from 'src/stylesheets/utils';
import { rewardsPostLoyaltyAccountURL, rewardsGetCouponDataURL } from 'src/services/urls';
import { getLoyaltyDetails } from 'src/lib/api-client/loyalty-service';
import { numberWithCommas } from 'src/lib/utils/app-utils';
import { activateOffer, getCouponDetails, getFeaturedLoyaltyOffers } from 'src/services/easyRewardsServices';
import AppLink from 'src/components/link';
import ROUTES from 'src/lib/routes';
import { useAtom } from 'jotai';
import COLORS from 'src/lib/colors';
import BrandIframe from 'src/components/BrandIframe';
import Header from 'src/components/header';
import NavBottom from 'src/components/NavBottom';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { toastAtom } from '../EasyRewards/OfferList/OfferCard';
import styles from './style.module.scss';
import AlertMessage from '../alert-message';
import RecyclingCartridgeEarning from '../EasyRewards/RecyclingCartridgeEarning';

interface QuickLinkCardProps {
  text: string;
  onClickHandler: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  icon: React.ReactNode; 
}

const QuickLinkCard: React.FC<QuickLinkCardProps> = ({text, onClickHandler, icon }) =>(
    <FullWidthCard
      className={styles.guestModeQuickLinkCard}
      noPadding
      onClick={onClickHandler}
    >
      <div className={mergeClasses(layout.flex, layout.flexCol, layout.alignCenter)} style={{ width: '6.8rem' }}>
        {icon}
        <Body size={16} color={COLORS.darkGray} lineHeight={35}>
          {text}
        </Body>
      </div>
    </FullWidthCard>
  );

function StoreModeDashboard() {
  const { mainStore } = useStoreData();
  const { userInfo } = useUserInfo();
  const { canvasRef } = useCardCanvas(userInfo?.rewardsNumber);
  const [loyaltyData, setLoyaltyData] = useState<any>({});
  const [featuredOffer, setFeaturedOffer] = useState<any>({});
  const [couponsInfo, setCouponsInfo] = useState<any>({});
  const [offersToActivate, setOffersToActivate] = useState<any>([]);
  const { history, push } = useRouter();
  const { mutateAsync: handleOfferActivate } = activateOffer();
  const [toastMessage, setToastMessage] = useAtom(toastAtom);
  const [loadIframeUrl, setLoadIframeUrl] = useState<string>('');
  const [isBarcodeScannerOpen, setIsBarcodeScannerOpen] = useState(false);
  const { setGuestModal } = useUserUpdateContext();

  const handleStoreModeClose = () => {
    sessionStorage.setItem('storeModeDismissed', 'true');
      history.push(ROUTES.home);  
  };
  const getLoyaltyInfo = async () => {
    if (userInfo?.rewardsNumber) {
      const loyaltyPayload = {
        accountIdentifier: userInfo?.rewardsNumber,
        identifierType: 'REWARDS',
      };
      const loyaltyResponse = await getLoyaltyDetails(rewardsPostLoyaltyAccountURL, loyaltyPayload);
      if (loyaltyResponse) {
        setLoyaltyData(
          loyaltyResponse?.accounts[0].walletinfo?.balances
        );
      }

    }

  };

  const getCouponInfo = async () => {
    if (userInfo?.rewardsNumber) {
      const url = rewardsGetCouponDataURL.replace(
        '%rewardsnumber%',
        userInfo.rewardsNumber || '0');
      const couponsResponse = await getCouponDetails(url);
      if (couponsResponse?.couponDataList?.length > 0) {

        const activeCoupons = couponsResponse.couponDataList?.filter
          ((coupon: any) =>
            coupon.couponInfo.isRedeemed === false && coupon.couponInfo.validForRetail === true);

        if (activeCoupons.length > 0) {
          setCouponsInfo(activeCoupons);
        }
      }

    }
  };

  const {
    data: featuredOffers,
    refetch: refetchFeaturedOffers,
  } = getFeaturedLoyaltyOffers({
    config: {
      onSuccess: (res) => {
        if (res && res?.offers?.length > 0) {
          const offerrsToActivate = res.offers.filter((offer) => offer.activated === false);
          setOffersToActivate(offerrsToActivate);
          const filteredOffers = res.offers.filter((offer) => offer.channel === 'Retail' || offer.channel === 'Retail and Dotcom');
          if (filteredOffers.length > 0) {
            setFeaturedOffer(filteredOffers[0]);
          }

        }
      },
      refetchOnMount: false,
    },
  });


  useEffect(() => {
    getLoyaltyInfo();
    getCouponInfo();
  }, [userInfo?.rewardsNumber]);

  const navigateToTarget = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, url: any): void => {
    event.preventDefault();
    if (url.includes(`${process.env.REACT_APP_DOT_COM_URL}`)) {
      setLoadIframeUrl(url);
    }
    else {
      history.push(url);
    }

  }
  const activateAllOffers = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    event.preventDefault();
    event.stopPropagation();

    const targetedOfferIdsToActivate =
      offersToActivate?.map((item: any) => item.offerid) || [];

    handleOfferActivate(targetedOfferIdsToActivate)
      .then((res) => {

        if (
          res.data?.some((activateRes: any) => !activateRes?.success)
        ) {
          throw new Error(
            "All offers couldn't be activated, try again."
          );
        }
      })
      .catch(() => {
        setToastMessage("All offers couldn't be activated, try again.");
      }).finally(() => {
        refetchFeaturedOffers();
      });

  }
  const getActivateOfferText = () =>
    offersToActivate.length > 1 ? `Activate all ${offersToActivate.length} offers` : 'All offers activate ';

  const handleClickOnBack = () => {
    setLoadIframeUrl('');
  }

  if (loadIframeUrl) {
    return (
      <>
      <Header
        position="fixed"
        headerFull={false}
        backButton
        onClickHandler={handleClickOnBack}
      />
      <Box style={{ paddingTop : '0' }}>
                    <BrandIframe
                        src={loadIframeUrl}
                        title="Shopping-online-screen"
                        id="shopping-online-screen-iframe"
                        height={
                          
                                'calc(100vh - 65px)'
                               
                        }
                    />
                </Box>
                <NavBottom />
</>
    )
  }

  return (
    <div className={styles.storeModeContainer}>

      <div className={styles.headerContainer}>
        <div className={styles.actions}>
          <button type='button' id="store-mode-dismiss" className={styles.closeButton} aria-label='close store mode' onClick={handleStoreModeClose}>
            <IconClose />
          </button>
        </div>
        <div >
          <div className={styles.header}>
            <IconStore />
            <Heading size={26} type='h2'>Store mode</Heading>
            <IconInfoWhite />
          </div>
          <Body className={styles.yourStore} size={18}>Welcome to Staples {storeCommunity(mainStore) || 'Unknown'}</Body>

          <SearchForProduct id="store-mode-search" placeholder="Look up a product or service" decoy mainStore={mainStore} />

        </div>
      </div>
      {userInfo ?
        <>
          <FullWidthCard className={styles.card} noPadding>
            <div className={mergeClasses(layout.flex, layout.alignCenter, layout.justifySpaceBetween)}>
              <IconEasyRewardsHorizontal width={100} />
              <Body weight='bold' className={colors.textRed} >{userInfo?.rewardsNumber}</Body>
            </div>

            <canvas tabIndex={0} aria-label="Your personal Easy Rewards barcode is here to scan at checkout when you are in a Staples store"
              ref={canvasRef} className={styles.renderedCardNumber} />

            <div className={mergeClasses(layout.flex, layout.alignCenter, layout.justifySpaceBetween, layout.mt2)}>
              <div className={mergeClasses(layout.flex, layout.alignCenter, layout.justifySpaceBetween, layout.fullWidth)} aria-label={`Your available points is ${loyaltyData.usable} points - Thats like ${loyaltyData.usablevalue} dollars in savings`}>
                <Body>Available points</Body>
                <Body size={16} weight="bold">
                  <span
                    className={styles.coin}
                  />
                  {loyaltyData?.usable &&
                    numberWithCommas?.(
                      loyaltyData.usable
                    )}
                </Body>
              </div>

              <button type='button' className={mergeClasses(styleUtil.ariaButton, styles.calculator)} id="store-mode-calculator" aria-label='open calculator'><IconCalculator /></button>
            </div>
          </FullWidthCard>

          {featuredOffer && (
            <FullWidthCard className={styles.card} onClick={(event) => navigateToTarget(event, `${ROUTES.rewardsWallet}?tabIndex=1`)} id="store-mode-offer" noPadding>
              <div className={mergeClasses(styles.featuredCard)}>
                <img src={featuredOffer?.digitalviewimage} alt="featured offer" aria-hidden="true" />
                <div>
                  <Heading type='h4' size={18} weight='bold' >{featuredOffer.name}</Heading>
                  <Body weight='light'>{featuredOffer.offerdescription}</Body>
                  <AppLink className={mergeClasses(styles.actionLink)} href='#' id="store-mode-activate-offer" onClick={activateAllOffers} >{getActivateOfferText()}</AppLink>
                </div>
              </div>
            </FullWidthCard>
          )}

          {couponsInfo &&
            (<FullWidthCard className={styles.card} onClick={(event) => navigateToTarget(event, `${ROUTES.rewardsWallet}?tabIndex=2`)} id="store-mode-coupon" noPadding>
              <div className={mergeClasses(styles.featuredCard)}>
                <img src={couponsInfo[0]?.couponInfo?.imageURL} alt="coupon" aria-hidden="true" />
                <div>
                  <Heading type='h4' size={18} weight='bold' >{couponsInfo[0]?.couponInfo?.descriptionText1}</Heading>
                  <Body weight='light'>{couponsInfo[0]?.couponInfo?.descriptionText2}</Body>
                  <AppLink className={mergeClasses(styles.actionLink)} href='#' id="store-mode-see-coupons" >See all {couponsInfo.length} store coupons</AppLink>
                </div>
              </div>
            </FullWidthCard>
            )}

          <div className={mergeClasses(layout.flex, layout.alignCenter, styles.smallCardContainer)}>
            <FullWidthCard className={mergeClasses(styles.card, styles.smallCard)} noPadding onClick={(event) => navigateToTarget(event, `${process.env.REACT_APP_DOT_COM_URL}/ptd/myorders`)}>
              <div className={mergeClasses(
                layout.flex, layout.alignCenter, layout.colGap4
              )}>
                <IconRecentPurchase />
                <Body size={14} weight='light' color={COLORS.darkGray}>Recent purchases</Body>
              </div>
            </FullWidthCard>
            <FullWidthCard className={mergeClasses(styles.card, styles.smallCard)} noPadding onClick={(event) => navigateToTarget(event, `${process.env.REACT_APP_DOT_COM_URL}/lp/weekly-ad`)}>
              <div className={mergeClasses(
                layout.flex, layout.alignCenter, layout.colGap4
              )}>
                <IconWeeklyAd />
                <Body size={14} weight='light' color={COLORS.darkGray}>Weekly Ad</Body>
              </div>
            </FullWidthCard>
          </div>
          <RecyclingCartridgeEarning />
    

        </> : <>
          <div className={mergeClasses(layout.flex, layout.justifySpaceBetween, layout.px5, layout.mb4)} >
            <QuickLinkCard 
              icon={<IconScanner width={70} height={70} />} 
              text='Scan a product' 
              onClickHandler={()=> setIsBarcodeScannerOpen(!isBarcodeScannerOpen)}
            />
            <QuickLinkCard 
            icon={<IconWeeklyAd width={70} height={70} />} 
            text='Weekly Ad' 
            onClickHandler={(event) => navigateToTarget(event, `${process.env.REACT_APP_DOT_COM_URL}/lp/weekly-ad`)}
            />
          </div>
          <div className={mergeClasses(layout.flex, layout.justifySpaceBetween, layout.px5)} >
            <QuickLinkCard 
              icon={<IconDollarCoin width={70} height={70} />} 
              text='Hot Deals'
              onClickHandler={(event) => navigateToTarget(event, `${process.env.REACT_APP_DOT_COM_URL}/stores/weeklyad`)}
            />
            <QuickLinkCard 
              icon={<IconPeople width={70} height={70} />} 
              text='Store servces' 
              onClickHandler={(event) => push(ROUTES.stores)}
            />
          </div>

          <FullWidthCard style={{margin: '1.3rem 1.3rem 0'}}>
            <div className={mergeClasses(layout.flex, layout.alignCenter)}>
                <IconProfile width={30} height={30} />
                <Body size={18} lineHeight={24} padding='0 0.8rem' className={styles.offersText}> 
                  Looking for offers? 
                  <Button  variant='text' fontSize={18} color="textLinkBlue" className={styles.signInButton} onClick={()=> setGuestModal({ open: true })}>
                    Sign in
                  </Button>
                </Body>
            </div>
          </FullWidthCard>
          <Button onClick={() => handleStoreModeClose() }
            variant='text' fontSize={12}
            className={styles.exitStoreModeText}>
            Exit store mode for full app experience
          </Button>
          {isBarcodeScannerOpen && (
            <Brcd
                isScannerOpen={isBarcodeScannerOpen}
                onClose={() => setIsBarcodeScannerOpen(false)}
            />
          )}
        </>
      }
      <AlertMessage
        open={!!toastMessage}
        icon={<IconStaplesRound />}
        handleClose={() => setToastMessage('')}
        btnName={''}
        message={toastMessage}
        handleBtnClick={() => setToastMessage('')}
        status={''}
      />
    </div>
  );
}


export default StoreModeDashboard;