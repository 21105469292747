import { useLayoutEffect } from 'react';
import useLocalUserInfo from 'src/hooks/useLocalUserInfo';
import { guestMode } from 'src/lib/utils/app-utils';
import {
    SessionManager,
    useRefreshToken,
    getMinutes,
    getLoginDetails,
} from './sessionManager';

export const loadSession = () => {
    const loginInstance: any = getLoginDetails();
    if (loginInstance) {
        SessionManager.setTokens(loginInstance);
        if (loginInstance.userToken) {
            SessionManager.setUserToken(loginInstance.userToken);
        }
        SessionManager.isLoggedIn = Boolean(loginInstance?.access_token);
        SessionManager.setIsUserMigrated({
            isUserMigrated: loginInstance.isUserMigrated,
            userName: loginInstance.userName,
        });
    }
};

const LoadSession: React.FC = () => {
    const isGuestModeOn = guestMode();
    useLayoutEffect(() => {
        (async () => {
            const loginInstance: any = getLoginDetails();
            if (loginInstance && !isGuestModeOn) {
                if (loginInstance.expires_in) {
                    useRefreshToken(
                        getMinutes(loginInstance.expires_in) - 2,
                        loginInstance?.access_token_issued_at
                    );
                }
            }
        })();
    }, []);

    return null;
};

export default LoadSession;
