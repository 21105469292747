/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { optimoveEvents } from 'src/screens/optimove';
import ROUTES from 'src/lib/routes';
import {
    NavIconHome,
    NavIconStores,
    NavIconShop,
    NavIconUserProfile,
    IconEasyRewardsHorizontal,
} from 'src/components/Icons';
import Button from 'src/components/UI/Button';
import {
    eventMetricsMap,
    eventMetricsEventsType,
    useEventsReporter,
    useRouter,
} from 'src/hooks';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { mergeClasses } from 'src/stylesheets/utils';
import IconStaplesNavigation from './IconStaplesNavigation';
import { Body } from '../UI';
import styles from './styles.module.scss';
import {storeMode} from "../../lib/utils/app-utils";
import useStoreData from "../../hooks/useStoreData";

export const NAV_BAR_HEIGHT = '80px';

function NavPill({ tabName, fallBackPath }: { tabName: TabNames, fallBackPath: FallBackPathNames }) {
    const activeTab = tabName || fallBackPath?.substring(1);
    return (
        <motion.div
            layout
            key="navpill"
            className={mergeClasses(styles.navIndicator, styles[activeTab])}
        />
    );
}

type TabNames = 'home' | 'shop' | 'rewards' | 'stores' | 'account';
type FallBackPathNames = '/home' | '/shop' | '/rewards' | '/stores' | '/account';

function NavBottom() {
    const { customEvent, analyticsCustomEvent } = useEventsReporter();
    const { fromPath, setFromPath, end } = useProductBrowseContext();
    const { setNavBottomActiveTab } = useUserUpdateContext();
    const { location, push } = useRouter();
    const router = useRouter();
    const isStoreModeDismissed = storeMode();
    const { nearestStore } = useStoreData();

    const pathToTabnameObj = {
        [ROUTES.home]: 'home',
        [ROUTES.shop]: 'shop',
        [ROUTES.rewardsWallet]: 'rewards',
        [ROUTES.stores]: 'stores',
        [ROUTES.userProfile]: 'account',
        [ROUTES.otherbalance]: 'account',
        [ROUTES.previousOrders]: fromPath === '/user-profile' ? 'account' : 'home',
    };

    const isCurrentPath = (tabName: TabNames) =>
        pathToTabnameObj[location.pathname as keyof typeof pathToTabnameObj] === tabName;

    const getBodyProps = (tabName: TabNames) => {
        if (isCurrentPath(tabName)) {
            return {
                color: 'textRed',
                weight: 'bold' as 'bold' | 'normal',
            };
        }
        return {
            color: 'textGray',
            weight: 'normal' as 'bold' | 'normal',
        };
    };

    const handleChange = (path: string) => {
        setNavBottomActiveTab(path);
        setFromPath(path);
    };

    const trackButtonTapEvent = (
        eventType: eventMetricsEventsType,
        path: string
    ) => {
        handleChange(path);
        push(path);
        customEvent(eventType);
        analyticsCustomEvent(eventMetricsMap.navigation, {
            click_text: eventType,
            element_location: 'bottom navigation',
        });
        optimoveEvents(eventMetricsMap.scm_navigation, {
            click_text: eventType,
            element_location: 'bottom navigation',
        });
    };

    const handleShopTabClick = () => {
        end();
        if (ROUTES.shop !== fromPath) setFromPath(ROUTES.shop);
        const searchedQuery = localStorage.getItem('searchedQuery');
        if (searchedQuery && searchedQuery !== '') {
            push(`${ROUTES.productDetailsPage}?searchString=${searchedQuery}`, { from: ROUTES.shop });
        } else {
            trackButtonTapEvent(eventMetricsMap.shop, ROUTES.shop);
        }
    };

    return (
        <nav className={styles.navbar}>
            <div className={styles.gridContainer}>
                <Button
                    onClick={() => {
                        end();
                        trackButtonTapEvent(eventMetricsMap.home, ROUTES.home);
                        if (ROUTES.home !== fromPath) setFromPath(ROUTES.home);
                        push(nearestStore?.length > 0 && !isStoreModeDismissed ? ROUTES.storeMode : ROUTES.home);
                    }}
                    variant="text"
                    className={styles.navItem}
                    tabIndex={1}
                    aria-label='Home'
                    aria-selected={isCurrentPath('home')}
                >
                    <div className={styles.iconContainer}>
                        <NavIconHome
                            fillColor={isCurrentPath('home') ? '#CC0000' : '#757575'}
                        />
                        <Body {...getBodyProps('home')} size={10}>
                            Home
                        </Body>
                    </div>
                </Button>
                <Button
                    onClick={handleShopTabClick}
                    variant="text"
                    className={styles.navItem}
                    aria-label='Shop'
                    tabIndex={2}
                    aria-selected={isCurrentPath('shop')}
                >
                    <div className={styles.iconContainer}>
                        <NavIconShop
                            fillColor={isCurrentPath('shop') ? '#CC0000' : '#757575'}
                        />
                        <Body {...getBodyProps('shop')} size={10}>
                            Shop
                        </Body>
                    </div>
                </Button>
                <Button
                    onClick={() => {
                        end();
                        trackButtonTapEvent(
                            eventMetricsMap.wallet_tapped,
                            ROUTES.rewardsWallet
                        );
                        if (ROUTES.rewardsWallet !== fromPath) setFromPath(ROUTES.rewardsWallet);
                    }}
                    variant="text"
                    className={styles.navItem}
                    aria-label='Easy Rewards'
                    tabIndex={3}
                    aria-selected={isCurrentPath('rewards')}
                >
                    <span className={styles.navItemMiddle}>
                        <IconStaplesNavigation className={styles.navStaplesLogo} />
                        <IconEasyRewardsHorizontal width={70} height={10} />
                    </span>
                </Button>
                <Button
                    aria-label='Store Service'
                    tabIndex={4}
                    onClick={() => {
                        end();
                        trackButtonTapEvent(
                            eventMetricsMap.stores_tapped,
                            ROUTES.stores
                        );
                        if (ROUTES.stores !== fromPath) setFromPath(ROUTES.stores);
                    }}
                    variant="text"
                    className={styles.navItem}
                    aria-selected={isCurrentPath('stores')}
                >
                    <div className={styles.iconContainer}>
                        <NavIconStores
                            fillColor={isCurrentPath('stores') ? '#CC0000' : '#757575'}
                        />
                        <Body {...getBodyProps('stores')} size={10}>
                            Store Services
                        </Body>
                    </div>
                </Button>
                <Button
                    aria-label='Account'
                    tabIndex={5}
                    onClick={() => {
                        end();
                        trackButtonTapEvent(
                            eventMetricsMap.profile_tapped,
                            ROUTES.userProfile
                        );
                        if (ROUTES.userProfile !== fromPath) setFromPath(ROUTES.userProfile);
                    }}
                    variant="text"
                    className={styles.navItem}
                    aria-selected={isCurrentPath('account')}
                >
                    <div className={styles.iconContainer}>
                        <NavIconUserProfile
                            fillColor={isCurrentPath('account') ? '#CC0000' : '#757575'}
                        />
                        <Body {...getBodyProps('account')} size={10}>
                            Account
                        </Body>
                    </div>
                </Button>
                <NavPill
                    fallBackPath={fromPath as FallBackPathNames}
                    tabName={pathToTabnameObj[location.pathname as keyof typeof pathToTabnameObj] as TabNames}
                />
            </div>
        </nav>
    );
}

export default NavBottom;
