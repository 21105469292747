import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import ROUTES from 'src/lib/routes';
import useRouter from 'src/hooks/useRouter';
import NavTop from 'src/components/nav-top';
import ScreenWidth from 'src/components/screen-width';
import SearchForProduct from 'src/components/search-for-product-home';
import COLORS from 'src/lib/colors';
import { CommonPageProps } from 'src/screens/product-browse/common-page';
import RecentlySearched from 'src/components/recently-searched';
import RecentlyViewedItems from 'src/components/recently-viewed-items';
import useStoreData from 'src/hooks/useStoreData';
import AutoCompeleteList from 'src/components/AutoCompeleteList';
import { setPreviousPath } from 'src/lib/utils/setPreviousPath';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';

const useStyles = makeStyles({
    form: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        gap: '15px',
    },
    searchWrapper: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    cancelBtn: {
        color: COLORS.primaryWhite,
        fontWeight: 400,
        fontSize: '0.875rem',
        display: 'flex',
        flexShrink: 1,
        padding: 0,
        minWidth: 47,
    },
    navContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '17px 18px',
        height: '72px',
    },
});

const FIVE_MINUTES = 300000;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ProductSearchLandingScreen(props: CommonPageProps) {
    const classes = useStyles();
    const { mainStore } = useStoreData();
    const { push, location, pathname } = useRouter();
    const { fromPath, setFromPath, goBack, end } = useProductBrowseContext();

    function handleSubmit(searchString: string) {
        const path =
            ROUTES.productDetailsPage === location.pathname
                ? fromPath
                : location.pathname;
        setFromPath(path);
        if (pathname && !pathname.includes(ROUTES.lists)) {
            end();
            push(
                `${ROUTES.productDetailsPage}?searchString=${searchString}`,
                setPreviousPath(location, path)
            );
        }
    }
    function onClickCancel() {
        if (pathname && pathname.includes(ROUTES.lists)) {
            goBack();
        } else {
            push(fromPath);
            end();
        }
    }

    const isFromLists = location?.pathname.includes('/lists');

    return (
        <ScreenWidth>
            <NavTop
                noBorderRadius
                bgColor={COLORS.brandRed}
                bgImageSrc={require('../../../assets/bgs/header-bg.svg')}
                minHeight="72px"
                className={classes.navContainer}
            >
                <div className={classes.form}>
                    <div className={classes.searchWrapper}>
                        <SearchForProduct
                            handleSubmit={handleSubmit}
                            mainStore={mainStore}
                            autoFocus
                        />
                    </div>
                    <Button
                        className={classes.cancelBtn}
                        onClick={onClickCancel}
                        aria-label='Cancel'
                        aria-selected='true'
                        aria-roledescription='Cancel your search'
                    >
                        Cancel
                    </Button>
                </div>
            </NavTop>
            <AutoCompeleteList />
            <>
                {isFromLists ? null : (
                    <>
                        <RecentlyViewedItems />
                        <RecentlySearched />
                    </>
                )}
            </>
        </ScreenWidth>
    );
}

export default React.memo(ProductSearchLandingScreen, () => true);
