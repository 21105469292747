// known routes and route patterns
const ROUTES = {
  onboarding: '/onboarding', // NOTE: logged in users are redirected to the home page
  home: '/home',
  lists: '/lists',
  rewards: '/rewards',
  rewardEnroll: '/reward-enroll',
  stores: '/stores',
  userProfile: '/user-profile',
  userManageProfile: '/user-profile/manage-profile',
  authToken: '/auth/token',
  rewardsWallet: '/wallet',
  rewardsWallet_digitalCoupons: '/wallet/digitalcoupons',
  rewardsWallet_classroomRewards: '/wallet/classroomrewards',
  rewardsWallet_staplesRewards: '/wallet/staplesrewards',
  purchaseHistory: '/home/purchasehistory',
  coupons: '/coupons',
  login: '/login',
  personalData: '/user-profile/manage-profile/personal-data',
  accountSecurity: '/user-profile/manage-profile/account-security',
  confirm: '/confirm',
  consent: '/user-profile/manage-profile/consent',
  shippingServices: '/stores/shippingServices',
  shippingServicesLabelShipment: '/stores/shippingServices/labelShipment',
  createShippingInformation: '/stores/shippingServices/createShippingInformation',
  getQuoteShippingInformation:
    '/stores/shippingServices/quoteShippingInformation',
  passwordResetEmail: '/pwd-reset-email',
  passwordReset: '/pwd-reset',
  forgotPassword: '/forgot-pwd',
  productDetailsPage: '/productDetailsPage',
  shop: '/shop',
  cart: '/cart',
  emptyCart: '/emptyCart',
  previousOrders: '/previousOrders',
  otherbalance: '/otherbalance',
  storeMode:'/store-mode',
} as const;

export const DEEPLINK_ROUTES = {
  '/home': ROUTES.home,
  '/lists': ROUTES.lists,
  '/stores': ROUTES.stores,
  '/user-profile': ROUTES.userProfile,
  '/wallet': ROUTES.rewardsWallet,
  '/easyrewards': ROUTES.rewardsWallet,
  '/wallet-digital-coupons': ROUTES.rewardsWallet_digitalCoupons,
  '/wallet-classroom-rewards': ROUTES.rewardsWallet_classroomRewards,
  '/wallet-staples-rewards': ROUTES.rewardsWallet_staplesRewards,
  '/coupons': ROUTES.coupons,
  '/ereceipt': ROUTES.previousOrders,
  '/home/online': ROUTES.home,
  '/home/retail': ROUTES.home,
  '/shop': ROUTES.shop,
  '/activateOffer': ROUTES.rewardsWallet,
  '/viewcoupon': ROUTES.rewardsWallet,
  '/account': ROUTES.userProfile,
  '/shippingServices': ROUTES.shippingServices,
} as const;

export type Routes = (typeof ROUTES)[keyof typeof ROUTES];

export const LANDING_SCREENS = [
  ROUTES.home,
  ROUTES.lists,
  ROUTES.rewards,
  ROUTES.stores,
  ROUTES.userProfile,
  ROUTES.rewardsWallet,
  ROUTES.coupons,
  ROUTES.login,
  ROUTES.shop,
  ROUTES.emptyCart,
  ROUTES.previousOrders,
  ROUTES.storeMode,
];

export const withoutRequiredAuthRoutes = [
  '/pwd-reset',
  '/forgot-pwd',
  '/pwd-reset-email',
  '/loginCreateNewUser',
  ROUTES.login,
]

export const loginRouteWithAuthRequired = [
  '/login/loginSrEnrollScreenConfirmation',
  '/login/loginSrEnrollScreen',
  '/login/loginLookItUp',
  '/login/userFound',
  '/login/userFoundConfirm',
]
export default ROUTES;
