import { createContext, useState, useContext } from 'react';
import useRouter from 'src/hooks/useRouter';

const pageNameToTabName = {
    digitalcoupons: 'digital',
    classroomrewards: 'classroom',
    staplesrewards: 'staples',
};

type LinkObjType = {
    src: string;
    show: boolean;
};

type LinkObjTypeShop = {
    src: string;
    show: boolean;
    route: string;
}

const TabsUIStateContext = createContext({
    itemPage: false,
    ocpPage: false,
    cartPage: false,
    printPage: false,
    homePage: false,
    pointHistoryPage: false,
    isIframeLoading: true,
    storeServicesLink: '',
    dotComUrl: '',
    storeLocatorUrl: '',
    storeRecycleLink: '',
    homeLinkInNative: {
        src: '',
        show: false,
    },
    openPrintDrawer: {
        src: '',
        show: false, 
    },
    storeLinkInNative: {
        src: '',
        show: false,
    },
    activeTab: 'digital',
    linkInNative: {
        src: '',
        show: false,
    },
    profileLinkInNative: {
        src: '',
        show: false,
    },
    navigationText: {
        navigationText: [],
        navigationTextTitle: '',
    },
    navigationTextSubOption: {
        navigationTextSubOption: [],
        navigationTextSubOptionTitle: '',
    },
    shopLinkInNative: {
        src: '',
        show: false,
        route: '',
    },
    searchSeeAllLink: {
        src: '',
        show: false,
    },
    navigationUrl: "",
    openService: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setItemPage: (item: boolean) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setOcpPage: (ocpPage: boolean) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleSetCartPage: (cartPage: boolean) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setStoreServicesLink: (link: string) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setDotComUrl: (link: string) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setStoreLocatorUrl: (url: string) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setHomeLinkInNative: (linkObJ: LinkObjType) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setOpenPrintDrawer:(linkObJ: LinkObjType) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setStoreLinkInNative: (linkObJ: LinkObjType) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setActiveTab: (activeTab: string) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setLinkInNative: (linkObJ: LinkObjType) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setProfileLinkInNative: (linkObJ: LinkObjType) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setNavigationText: (navTextObj: any) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setNavigationTextSubOption: (navTextSubObj: any) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setShopLinkInNative: (linkObJ: LinkObjTypeShop) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setSearchSeeAllLink: (linkObJ: LinkObjType) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setNavigationUrl: (url: string) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setOpenServices: (service: boolean) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setPointHistoryPage: (pointHistoryPage: boolean) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setIframeLoading: (loader: boolean) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setStoreRecycleLink: (url: string) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleSetHomePage: (homePage: boolean) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setPrintPage: (printPage: boolean) => { },
});

export function useTabsUIStateContextState() {
    // shopOnline item page
    const [itemPage, setItemPage] = useState(false);
    // cart
    const [ocpPage, setOcpPage] = useState(false);
    const [pointHistoryPage, setPointHistoryPage] = useState(false);
    const [cartPage, setCartPage] = useState(false);
    const [printPage, setPrintPage] = useState(false);
    const [homePage, setHomePage] = useState(false);
    const [isIframeLoading, setIframeLoading] = useState(true)
    // home
    const { query } = useRouter();
    const [storeServicesLink, setStoreServicesLink] = useState('');
    const [dotComUrl, setDotComUrl] = useState('');
    const [storeLocatorUrl, setStoreLocatorUrl] = useState('');
    const [storeRecycleLink, setStoreRecycleLink] = useState('');
    const [homeLinkInNative, setHomeLinkInNative] = useState({
        src: '',
        show: false,
    });
    const [openPrintDrawer, setOpenPrintDrawer] = useState({
        src: '',
        show: false, 
    });
    // shop
    const [navigationText, setNavigationText] = useState({
        navigationText: [],
        navigationTextTitle: '',
    });
    const [navigationTextSubOption, setNavigationTextSubOption] = useState({
        navigationTextSubOption: [],
        navigationTextSubOptionTitle: '',
    });
    const [shopLinkInNative, setShopLinkInNative] = useState<LinkObjTypeShop>({
        src: '',
        show: false,
        route: '',
    });
    const [searchSeeAllLink, setSearchSeeAllLink] = useState<LinkObjType>({
        src: '',
        show: false,
    });
    const [navigationUrl, setNavigationUrl] = useState('');
    const [openService, setOpenServices] = useState(false);
    // coupons & rewards
    const [activeTab, setActiveTab] = useState(
        pageNameToTabName[query.pageName as keyof typeof pageNameToTabName] ||
        'digital'
    );
    // stores
    const [storeLinkInNative, setStoreLinkInNative] = useState({
        src: '',
        show: false,
    });
    // account
    const [linkInNative, setLinkInNative] = useState<LinkObjType>({
        src: '',
        show: false,
    });
    const [profileLinkInNative, setProfileLinkInNative] = useState<LinkObjType>({
        src: '',
        show: false,
    })

    const handleSetCartPage = (data: boolean) => {
        console.log("🚀 ~ file: tabs-ui-state-context.tsx:126 ~ handleSetCartPage ~ data:", data)
        setCartPage(data)
    }
    const handleSetHomePage = (data: boolean) => {
        console.log("🚀 ~ file: tabs-ui-state-context.tsx:126 ~ handleSetCartPage ~ data:", data)
        setHomePage(data)
    }
    return {
        itemPage,
        ocpPage,
        cartPage,
        printPage,
        homePage,
        storeServicesLink,
        dotComUrl,
        storeLocatorUrl,
        storeRecycleLink,
        homeLinkInNative,
        openPrintDrawer,
        activeTab,
        storeLinkInNative,
        linkInNative,
        profileLinkInNative,
        navigationText,
        navigationTextSubOption,
        shopLinkInNative,
        searchSeeAllLink,
        navigationUrl,
        openService,
        pointHistoryPage,
        isIframeLoading,
        setOcpPage,
        setPointHistoryPage,
        setItemPage,
        handleSetCartPage,
        setPrintPage,
        handleSetHomePage,
        setStoreServicesLink,
        setDotComUrl,
        setStoreLocatorUrl,
        setHomeLinkInNative,
        setOpenPrintDrawer,
        setActiveTab,
        setStoreLinkInNative,
        setLinkInNative,
        setProfileLinkInNative,
        setNavigationText,
        setNavigationTextSubOption,
        setShopLinkInNative,
        setSearchSeeAllLink,
        setNavigationUrl,
        setOpenServices,
        setIframeLoading,
        setStoreRecycleLink,
    };
}

export function useTabsUIStateContext() {
    return useContext(TabsUIStateContext);
}

export default TabsUIStateContext;
