/* eslint-disable jsx-a11y/aria-role */
import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    makeStyles,
    Typography,
} from '@material-ui/core';
import ManageFavoritesCoachScreen from 'src/screens/EasyRewards/ManageFavorites/ManageFavoritesCoachScreen';
import { Body, Heading, Button } from 'src/components/UI';
import IconDown from 'src/components/Icons/IconDown';
import IconDownCoachMark from 'src/components/Icons/IconDownCoachMark';
import { eventMetricsMap, useEventsReporter, useUserInfo } from 'src/hooks';
import { getLoyaltyAccountDetails } from 'src/services/easyRewardsServices';
import { numberWithCommas } from 'src/lib/utils/app-utils';
import { Player } from '@lottiefiles/react-lottie-player';
import layout_classes from '../../../stylesheets/layout_classes.module.scss';
import styles from './styles.module.scss';
import '../../../assets/animate/animate.css';

const YourPointsScreen = ({ handleSkipAll, handleNext }: any) => {
    const { userInfo } = useUserInfo();
    const [availablePoints, setAvailablePoints] = useState<number>(0);
    const animationClass =
        'animate__animated animate__fadeIn animate__delay-0.8s';

    getLoyaltyAccountDetails({
        config: {
            enabled: !!userInfo?.rewardsNumber,
            onSuccess: (res) => {
                setAvailablePoints(
                    res?.accounts[0]?.walletinfo?.balances?.usable
                );
            },
        },
        rewardsNumber: userInfo?.rewardsNumber,
    });

    const { analyticsCustomEvent } = useEventsReporter();
    const handleNextScreen = () => {
        handleNext(2)
        analyticsCustomEvent(eventMetricsMap.coachmarks, {
            click_text: `next`,
        });
    }
    const handleSkip = () => {
        handleSkipAll();
        analyticsCustomEvent(eventMetricsMap.coachmarks, {
            click_text: `skip all`,
        });
    }
    return (
        // <Dialog
        //     open
        //     aria-labelledby="categories-dialog-title"
        //     classes={{
        //         paper: styles.paper,
        //         paperScrollPaper: styles.paperScrollPaper,
        //         scrollPaper: styles.scrollPaper,
        //     }}
        // >
        <>
            <div className={`${styles.iconBox} ${animationClass}`}>
                <span className={styles.arrowIcon}>
                    <img
                        src={require('../../Icons/arrow-icon.svg')}
                        alt="img"
                    />
                </span>

                {userInfo?.rewardsNumber && (
                    <>
                        <div className={styles.pointButton}>
                            <Body weight="bold">
                                {availablePoints &&
                                    numberWithCommas?.(
                                        availablePoints?.toString()
                                    )}{' '}
                                pts
                            </Body>
                            <Player
                            aria-label="Your points and points history is towards the top of the page"
                                autoplay
                                loop
                                src={require('../../../assets/lotties/header_icon_coin_animated.json')}
                                className={styles.coinIcon}
                            />
                        </div>
                    </>
                )}
            </div>
            <div className={`${styles.mainConatiner} ${animationClass}`}>
                <div className={styles.boxConatiner}>
                    <Body
                        size={12}
                        className={styles.alignCount}
                        weight="light"
                        lineHeight={16}
                        ariaLabel='One out of five'
                    >
                        1/5
                    </Body>
                    <Heading
                        type="h2"
                        weight="bold"
                        size={26}
                        lineHeight={32}
                        className={styles.alignheaderDsc}
                        ariaLabel='Your Points'
                    >
                        Your Points
                    </Heading>
                    <Body size={16} lineHeight={20} weight="light" ariaLabel="See how much you've earned and tap to see your points history">
                        {`See how much you've earned and tap to see your points history.`}
                    </Body>
                    <div className={styles.btnConatiner}>
                        <Button
                            variant="text"
                            onClick={handleSkip}
                            className={styles.Link}
                            fontWeight="bold"
                            ariaLabel='Skip all'
                        >
                            Skip all
                        </Button>
                        <Button
                            onClick={handleNextScreen}
                            bgColor="bgRed"
                            className={styles.btnAlign}
                            ariaLabel='Next'
                            role='Button'
                        >
                            Next
                        </Button>
                    </div>
                </div>
                <div className={styles.alignImg}>
                    <img
                        src={
                            'https://de-app.staplesconnect.com/images/CoachMarks/cm_img_your_points.png'
                        }
                        alt="img"
                    />
                </div>
            </div>
        </>
        // </Dialog>
    );
};

export default YourPointsScreen;
