/* eslint-disable import/order */
/* eslint-disable import/no-cycle */
/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
/**
 * The functions and api calls in this file are not considered final.
 * They are mostly for exploration into what we expect data to eventually look like.
 */

import Log from 'src/lib/logging';
import axiosInstance from 'src/lib/api-client/custom-axios';
import { getCoordinates } from './google/geolocation';
import { Store, StoreInventory, User } from 'src/services/types';
import { patchUserInfo } from 'src/lib/api-client/user-info';
import { locationZipCodeValidation } from 'src/services/urls';
import { buildPatchOperations } from '../utils/patch-utils';
import { guestMode } from '../utils/app-utils';

/**
 * Sometimes we don't call out to the middleware and instead call resources directly.
 * This may change as the middleware becomes more built-out.
 */
const OTHER_ENDPOINTS = {
    STAPLES_CONNECT_API:
        process.env.REACT_APP_PUBLIC_STAPLES_CONNECT_API ||
        'https://www.staplesconnect.com/api',
};

/**
 * Gets the current store of the user
 * or attempts to infer it and update their user profile
 * with the most likely Store ID.
 */
export async function getOrUpdateCurrentStore(
    zipCode?: string,
    preferredStoreId?: string,
    userData?: User,
    setStoreFindErrorMessage?: any
): Promise<Store | null> {
    if (!zipCode && !preferredStoreId) {
        return null;
    }
    // ignore zip code if a user has set a preferred store ID
    if (preferredStoreId) {
        const store = await axiosInstance.get(
            `${OTHER_ENDPOINTS.STAPLES_CONNECT_API}/store/${preferredStoreId}`
        );
        if (store.data) {
            return store.data;
        }
        Log.axios(
            `received an unexpected response from ${OTHER_ENDPOINTS.STAPLES_CONNECT_API}`
        );
        Log.axios(store);
        // if we cannot retrieve the store data, continue to try with zipcode
    }
    if (zipCode) {
        const stores = await axiosInstance.get(
            `${OTHER_ENDPOINTS.STAPLES_CONNECT_API}/store?address=${zipCode}&radius=250&limit=1`
        );

        // currently storeInfo is returning an array, return the first element
        if (stores.data && stores.data.length > 0) {
            const firstStore = stores.data[0] as Store; // first element is closest
            if (
                firstStore &&
                userData?.preferredStoreId !== firstStore.storeNumber
            ) {
                await patchUserInfo(
                    buildPatchOperations([
                        {
                            field: 'preferredStoreId',
                            value: firstStore.storeNumber,
                        },
                    ])
                );
                Log.axios(
                    `attempted to patch user info to preferredStoreId:${firstStore.storeNumber}`
                );
            }
            return firstStore;
        }
        Log.axios(
            `received an unexpected response from ${OTHER_ENDPOINTS.STAPLES_CONNECT_API}`
        );
        Log.axios(stores);
        if (stores?.data?.error) {
            return stores.data.error;
        }
    }
    return null;
}

export async function getStores(zipCode?: string): Promise<Store[]> {
    if (!zipCode) {
        return [];
    }
    const storeInfo = await axiosInstance.get(
        `${OTHER_ENDPOINTS.STAPLES_CONNECT_API}/store?address=${zipCode}&radius=250`
    );
    if (storeInfo.data && storeInfo.data.length > 0) {
        return storeInfo.data;
    }
    Log.axios(
        `received an unexpected response from ${OTHER_ENDPOINTS.STAPLES_CONNECT_API}`
    );
    Log.axios(storeInfo);
    return [];
}

export async function getStoresNearby(zipCode?: string): Promise<Store[]> {
    const coordinates: any = await getCoordinates();
    if (!zipCode) {
        return [];
    }
    const storeInfo = await axiosInstance.get(
        `${OTHER_ENDPOINTS.STAPLES_CONNECT_API}/store?latitude=${coordinates.latitude}&longitude=${coordinates.longitude}&radius=0.4`
    );
    if (storeInfo.data && storeInfo.data.length > 0) {
        return storeInfo.data;
    }
    Log.axios(
        `received an unexpected response from ${OTHER_ENDPOINTS.STAPLES_CONNECT_API}`
    );
    Log.axios(storeInfo);
    return [];
}

export async function getStoresTsa(zipCode?: string): Promise<Store[]> {
    if (!zipCode) {
        return [];
    }
    const storeInfo = await axiosInstance.get(
        `${OTHER_ENDPOINTS.STAPLES_CONNECT_API}/store?storeServiceId=tsa_precheck&address=${zipCode}&radius=100&limit=9`
    );
    if (storeInfo.data && storeInfo.data.length > 0) {
        return storeInfo.data;
    }
    Log.axios(
        `received an unexpected response from ${OTHER_ENDPOINTS.STAPLES_CONNECT_API}`
    );
    Log.axios(storeInfo);
    return [];
}

export async function searchStores(paritalAddress?: string): Promise<Store[]> {
    if (!paritalAddress) {
        return [];
    }
    const storeInfo = await axiosInstance.get(
        `${OTHER_ENDPOINTS.STAPLES_CONNECT_API}/store?address=${paritalAddress}&radius=250&limit=20`
    );
    if (storeInfo.data && storeInfo.data.length > 0) {
        return storeInfo.data;
    }
    Log.axios(
        `received an unexpected response from ${OTHER_ENDPOINTS.STAPLES_CONNECT_API}`
    );
    Log.axios(storeInfo);
    return [];
}

export async function getStoreInventory(
    itemNumber: string,
    storeIds: string[]
): Promise<StoreInventory[]> {
    if (storeIds.length === 0 && itemNumber === '') {
        return [];
    }
    const storeInventory = await axiosInstance.post(
        `${process.env.REACT_APP_STORE_INVENTORY}`,
        { itemNumber, storeIds }
    );

    if (storeInventory.data && storeInventory.data.length > 0) {
        return storeInventory.data;
    }
    Log.axios(
        `received an unexpected response from ${process.env.REACT_APP_STORE_INVENTORY}`
    );
    Log.axios(storeInventory);
    return [];
}

export async function validateZipCode(zipcode: string) {
    const isGuestModeOn = guestMode();
    if (!zipcode) {
        return null;
    }
    if (isGuestModeOn) {
        const data = {
            isValidZipCode: true,
        }
        return data;
    }
    const result = await axiosInstance.get(
        `${locationZipCodeValidation.replace('%zipCode%', zipcode)}`
    );
    if (result.data) {
        return result.data;
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when fetching similar products ${process.env.REACT_APP_USER_API_ZIPCODE_VALIDATION}`
    );
    Log.axios(result);
    return null;
}
