import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogProps,
    Button,
    Typography,
    makeStyles,
} from '@material-ui/core';
import {
    usePWAInstall,
    usePWAUpdate,
    useIOSAppInstall,
    precache,
} from 'src/lib/utils/app-utils';
import COLORS from 'src/lib/colors';
import { SCREEN_NAMES } from 'src/controllers/config';
import { useIsActiveScreen } from 'src/lib/contexts/screen-order-context';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { useQuery } from 'react-query';
import { getStaplesRewardsData } from 'src/lib/api-client/wallet-activities';
import useUserInfo from 'src/hooks/useUserInfo';
import { CACHE_ONLY, NO_REFETCH } from 'src/lib/utils/react-query-utils';
import platform from 'platform-detect';
import FirstVisitRecycleCoupon from 'src/screens/rewards-wallet/first-visit-recycle-coupon';
import {
    getApplicationPopUpMessages,
    getApplicationVersion,
    updateApplicationMessagesPopUp,
} from 'src/lib/api-client/application-activities';
import StandardizingPopUpMessages from 'src/components/standardizing-pop-up-messages';
import useRouter from 'src/hooks/useRouter';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { SessionManager } from 'src/lib/api-client/sessionManager';
import packageJsonData from '../../package.json';
import useEventsReporter from '../hooks/useEventsReporter';
import eventMetricsMap from '../hooks/useEventsReporter/event-types';


const useInstallAlertStyles = makeStyles((theme) => ({
    dialog: {
        maxWidth: '375px',
        borderRadius: '1rem',
    },
    contentWrapper: {
        padding: '.75rem 1.5rem',
    },
    title: {
        fontWeight: 'bold',
        fontSize: '1.1rem',
        letterSpacing: '-0.39pt',
    },
    desc: {
        fontWeight: 500,
        fontSize: '.85rem',
    },
    buttons: {
        padding: '1rem 0 .75rem',
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'row',
        [theme.breakpoints.down(420)]: {
            flexDirection: 'column',
        },
    },
    confirmBtn: {
        height: '2.5rem',
        padding: '.5rem 1.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
        [theme.breakpoints.down(420)]: {
            marginBottom: '.5rem',
        },
    },
    closeBtn: {
        fontWeight: 'bold',
        textTransform: 'none',
        color: COLORS.brandCyan,
        fontSize: '1rem',
    },
}));

type InstallAlertProps = DialogProps & {
    title?: string;
    desc?: string;
    confirmText?: string;
    onConfirmClick: () => void;
    closeText?: string;
    onCloseClick: () => void;
};
function InstallAlert(props: InstallAlertProps) {
    const classes = useInstallAlertStyles();
    const {
        title = '',
        desc = '',
        confirmText = '',
        onConfirmClick,
        closeText = '',
        onCloseClick,
        ...rest
    } = props;
    return (
        <Dialog classes={{ paper: classes.dialog }} {...rest}>
            <div className={classes.contentWrapper}>
                <Typography
                    className={classes.title}
                    id="install-dialog-title-text"
                >
                    {title}
                </Typography>
                <Typography
                    className={classes.desc}
                    id="install-dialog-desc-text"
                >
                    {desc}
                </Typography>
                <div className={classes.buttons}>
                    <Button
                        className={classes.confirmBtn}
                        onClick={onConfirmClick}
                        id="install-dialog-confirm-button"
                    >
                        {confirmText}
                    </Button>
                    <Button
                        className={classes.closeBtn}
                        onClick={onCloseClick}
                        id="install-dialog-close-button"
                    >
                        {closeText}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}

type Prompt = {
    id: 'ios-install' | 'pwa-install' | 'pwa-update';
    title: string;
    desc: string;
    confirmText: string;
    closeText: string;
};

const IOSInstallAlert: Prompt = {
    id: 'ios-install',
    title: 'Open your App',
    desc: 'Use the app for a better experience.',
    confirmText: 'Go to app',
    closeText: 'Stay in browser',
};
const PWAInstallAlert: Prompt = {
    id: 'pwa-install',
    title: 'Open your app',
    desc: 'Use the app for a better experience.',
    confirmText: 'Go to app',
    closeText: 'Stay in browser',
};
const PWAUpdateAlert: Prompt = {
    id: 'pwa-update',
    title: 'Update',
    desc: "To be sure you're getting the best experience please update your app.",
    confirmText: 'Update Now',
    closeText: 'No Thanks',
};

function isRunningAsAPK() {
    // Check if the app is running in standalone mode (PWA or APK)
    if (window.matchMedia('(display-mode: standalone)').matches) {
        return true;
    }

    // Check for iOS standalone mode without using navigator.standalone
    if ('standalone' in window.navigator && (window.navigator as any).standalone === true) {
        return true;
    }

    // Additional check for Android APK
    if (/Android/i.test(window.navigator.userAgent) && window.navigator.userAgent.indexOf('wv') > -1) {
        return true;
    }

    return false;
}

/** Controller for displaying installation prompts and updates */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function InstallabilityController() {
    const {
        isIOSInstallPromptAvailable,
        onAcceptIOSInstall,
        onDenyIOSInstall,
    } = useIOSAppInstall();
    const {
        isAlreadyInstalled,
        isPromptAvailable,
        onAcceptInstall,
        onDenyInstall,
    } = usePWAInstall();
    const isIos = platform.ios;

    const { onAcceptReload, showReloadPrompt, onDenyReload } = usePWAUpdate();
    const { isRecyclePopUpShowed, RecyclePopUpUpdate } = useUserUpdateContext();
    const { userInfo } = useUserInfo();
    const rewardsNumber = userInfo?.rewardsNumber || '0';
    const [firstVisitRecycleCoupon, setFirstVisitRecycleCoupon] = useState({});
    const [prompt, setPrompt] = useState<null | Prompt>(null);
    const [promptDismissed, setPromptDismissed] = useState<boolean>(true);
    const [isApplicationPopUpMessagesOpen, setApplicationPopUpMessages] =
        useState<boolean>(false);
    const [applicationPopUpMessagesData, setApplicationPopUpMessagesData] =
        useState<any>({});
    const [applicationPopUpData, setApplicationPopUpData] = useState<any>([]);
    const router = useRouter();
    const { analyticsCustomEvent } =
        useEventsReporter();

    const { data: applicationVersionData } = useQuery(
        ['applicationVersionData'],
        () => getApplicationVersion(),
        {
            ...NO_REFETCH,
        }
    );

    const { data: staplesRewardsDetail } = useQuery(
        ['details', rewardsNumber],
        () => getStaplesRewardsData(rewardsNumber),
        {
            ...CACHE_ONLY,
        }
    );

    const { data: appData } = useQuery(
        ['applicationPopUpData'],
        () => getApplicationPopUpMessages(),
        {
            enabled: SessionManager.isLoggedIn,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: Infinity,
            cacheTime: Infinity,
        }
    );
    const setAppMessInToLocalStorage = (appDataMess: any) => {
        const stringifiedValue = JSON.stringify(appDataMess);
        window.localStorage.setItem('aaplicationPopUpData', stringifiedValue);
    };
    const appAppMessagesFromLocalStorage = () => {
        const getDataFromLocalStorage: any = window.localStorage.getItem(
            'aaplicationPopUpData'
        );
        if (
            getDataFromLocalStorage &&
            getDataFromLocalStorage !== 'undefined' &&
            getDataFromLocalStorage !== 'null'
        ) {
            const parsedValue = JSON.parse(getDataFromLocalStorage);
            return parsedValue;
        }
        return getDataFromLocalStorage;
    };
    const updateAppMessagesBasesOnRes = (
        dataApp: any,
        getDataFromLocalStorage: any
    ) => {
        const appMsgesData = dataApp?.map((appMsgData: any) => {
            const findAppMsgData =
                getDataFromLocalStorage?.find(
                    (x: any) => x.appMessageId === appMsgData.appMessageId
                ) || {};
            if (
                findAppMsgData &&
                Object.keys(findAppMsgData)?.length > 0 &&
                findAppMsgData?.showPopUpDateTime
            ) {
                return {
                    ...appMsgData,
                    showPopUpDateTime: findAppMsgData.showPopUpDateTime,
                };
            }
            return appMsgData;
        });
        setApplicationPopUpData(appMsgesData);
        setAppMessInToLocalStorage(appMsgesData);
    };
    useEffect(() => {
        const getDataFromLocalStorage = appAppMessagesFromLocalStorage();
        if (appData && appData?.length > 0) {
            if (
                getDataFromLocalStorage &&
                getDataFromLocalStorage !== 'undefined' &&
                getDataFromLocalStorage !== 'null'
            ) {
                updateAppMessagesBasesOnRes(appData, getDataFromLocalStorage);
            } else {
                setApplicationPopUpData(appData);
                setAppMessInToLocalStorage(appData);
            }
        }
    }, [appData]);
    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setPromptDismissed(true);
    };
    const StandardizingPopUpMessagesData = () => {
        const currentPathName = router.pathname?.replace('/', '');
        const currentTime = moment.utc().format('YYYY-MM-DD HH:mm');
        const currentESTTime = momentTz().tz("America/New_York").format('YYYY-MM-DD HH:mm');
        const getPopDataOnCurrentTab: any = applicationPopUpData.filter(
            (aPData: any) => {
                const endDateTimeFormate = moment(aPData?.enddatetime).format('YYYY-MM-DD HH:mm');
                const isPopUpTimeExpier = moment(currentESTTime).isAfter(endDateTimeFormate);
                if (aPData.tabName.toLowerCase() === currentPathName.toLowerCase() && !isPopUpTimeExpier) {
                    if (aPData?.showPopUpDateTime) {
                        const { duration, showPopUpDateTime } = aPData;
                        const getNextDate = moment(showPopUpDateTime)
                            .add(duration, 'hours')
                            .format('YYYY-MM-DD HH:mm');
                        if (moment(currentTime).isAfter(getNextDate)) {
                            return true;
                        }
                        return false;
                    }
                    return true;
                }
                return false;
            }
        );
        const getPopDataOnCurrentTabByPriority =
            getPopDataOnCurrentTab?.sort((a: any, b: any) =>
                a.priority > b.priority ? 1 : -1
            ) || [];
        setApplicationPopUpMessages(true);
        setApplicationPopUpMessagesData(getPopDataOnCurrentTabByPriority[0]);
    };

    const openApplication = () => {
        analyticsCustomEvent(eventMetricsMap.app_update_modal, {
            click_text: prompt?.confirmText?.toLowerCase(),
            element_location: 'update',
        });
        if (window.location.origin === 'https://qe101.staples.com') {
            return window.open(
                'https://play.google.com/store/apps/details?id=com.staplesconnect.qe_app.twa',
                '_blank'
            );
        }
        return window.open(
            'https://play.google.com/store/apps/details?id=com.staplesconnect.app.twa',
            '_blank'
        );
    }

    const onDeny = () => {
        switch (prompt?.id) {
            case 'pwa-update':
                analyticsCustomEvent(eventMetricsMap.app_update_modal, {
                    click_text: prompt?.closeText?.toLowerCase(),
                    element_location: 'update',
                });
                onDenyReload();
                break;
            case 'pwa-install':
                onDenyInstall();
                break;
            case 'ios-install':
                onDenyIOSInstall();
                break;
            default:
                break;
        }
        handleClose();
    };

    const onAccept = () => {
        switch (prompt?.id) {
            case 'pwa-update':
                openApplication();
                break;
            case 'pwa-install':
                onAcceptInstall();
                break;
            case 'ios-install':
                onAcceptIOSInstall();
                break;
            default:
                break;
        }
        handleClose();
    };
    console.log({ isIOSInstallPromptAvailable });
    useEffect(() => {
        const latestVesion = applicationVersionData?.version?.replace(
            /\./g,
            ''
        );
        const installedVersion = packageJsonData.version?.replace(/\./g, '');
        const isAlreadyShowPopUp = sessionStorage.getItem('pwaUpdateApp');
        const isAlreadyIOSPopUp = sessionStorage.getItem('iosInstallApp');
        const isAlreadyAppInstallesPopUp = sessionStorage.getItem('pwaInstallApp');
        if (!prompt) {
            if (isIOSInstallPromptAvailable && isAlreadyIOSPopUp !== 'true') {
                sessionStorage.setItem('iosInstallApp', 'true');
                setPrompt(IOSInstallAlert);
                setPromptDismissed(false);
            }
            // else if (!isAlreadyInstalled && isAlreadyAppInstallesPopUp !== 'true' &&
            //     !isIos && !isRunningAsAPK()) {  // Add the check here
            //     sessionStorage.setItem('pwaInstallApp', 'true');
            //     setPrompt(PWAInstallAlert);
            //     setPromptDismissed(false);
            // } 
            else if (
                !isIos &&
                isAlreadyShowPopUp !== 'true' &&
                latestVesion &&
                installedVersion &&
                Number(latestVesion) !== Number(installedVersion)
            ) {
                sessionStorage.setItem('pwaUpdateApp', 'true');
                setPrompt(PWAUpdateAlert);
                setPromptDismissed(false);
            }
        }
    }, [
        prompt,
        isIOSInstallPromptAvailable,
        isPromptAvailable,
        showReloadPrompt,
        applicationVersionData?.version,
    ]);
    useEffect(() => {
        if (
            !isApplicationPopUpMessagesOpen &&
            !isRecyclePopUpShowed &&
            !isIOSInstallPromptAvailable &&
            !isPromptAvailable &&
            !isRecyclePopUpShowed &&
            staplesRewardsDetail?.data
        ) {
            const findRecycleCoupon =
                staplesRewardsDetail?.data?.couponDataList.find(
                    (couponData: any) =>
                        couponData?.couponInfo?.couponClass === 'ReCycle'
                ) || {};
            if (Object.keys(findRecycleCoupon)?.length > 0) {
                setFirstVisitRecycleCoupon(findRecycleCoupon);
                RecyclePopUpUpdate();
            }
        }
    }, [
        prompt,
        staplesRewardsDetail?.data,
        isIOSInstallPromptAvailable,
        isPromptAvailable,
        showReloadPrompt,
        isApplicationPopUpMessagesOpen,
    ]);

    useEffect(() => {
        if (
            !isIOSInstallPromptAvailable &&
            !isPromptAvailable &&
            applicationPopUpData?.length > 0
        ) {
            StandardizingPopUpMessagesData();
        }
    }, [
        prompt,
        isIOSInstallPromptAvailable,
        isPromptAvailable,
        showReloadPrompt,
        applicationPopUpData?.length,
        router.pathname,
    ]);
    const isOpen = !!prompt && !promptDismissed;
    const isActiveScreen = useIsActiveScreen(
        SCREEN_NAMES.INSTALL_POPUP,
        isOpen
    );
    const handleFirstVisitRecycleCoupon = () => {
        setFirstVisitRecycleCoupon({});
    };

    const addedDuretionToPopUpMessages = () => {
        const showPopUpDateTime = moment.utc().format('YYYY-MM-DD HH:mm');
        const modifyAppMessagesData = applicationPopUpData?.map((data: any) => {
            if (
                data.appMessageId === applicationPopUpMessagesData.appMessageId
            ) {
                return { ...data, showPopUpDateTime };
            }
            return data;
        });
        setApplicationPopUpData(modifyAppMessagesData);
        setAppMessInToLocalStorage(modifyAppMessagesData);
    };

    const handleClosePopUpMessages = () => {
        addedDuretionToPopUpMessages();
        setApplicationPopUpMessages(false);
        setApplicationPopUpMessagesData({});
    };
    const handleStandardizingPopUpMessagesCTA = () => {
        const { appMessageId } = applicationPopUpMessagesData;
        const getDataFromLocalStorage = appAppMessagesFromLocalStorage();
        handleClosePopUpMessages();
        updateApplicationMessagesPopUp(appMessageId, true).then((res: any) => {
            updateAppMessagesBasesOnRes(res, getDataFromLocalStorage);
        });
    };
    if (
        isApplicationPopUpMessagesOpen &&
        applicationPopUpMessagesData &&
        Object.keys(applicationPopUpMessagesData)?.length > 0
    ) {
        return (
            <StandardizingPopUpMessages
                open={isApplicationPopUpMessagesOpen}
                handleClose={handleClosePopUpMessages}
                cliclOnCTA={handleStandardizingPopUpMessagesCTA}
                popUpMessagesData={applicationPopUpMessagesData}
            />
        );
    }

    if (
        firstVisitRecycleCoupon &&
        Object.keys(firstVisitRecycleCoupon).length > 0
    ) {
        return (
            <FirstVisitRecycleCoupon
                open={Object.keys(firstVisitRecycleCoupon).length > 0}
                handleClose={handleFirstVisitRecycleCoupon}
                firstVisitRecycleCoupon={firstVisitRecycleCoupon}
            />
        );
    }
    return (
        <InstallAlert
            open={isActiveScreen}
            onCloseClick={onDeny}
            onBackdropClick={onDeny}
            onConfirmClick={onAccept}
            title={prompt?.title}
            desc={prompt?.desc}
            confirmText={prompt?.confirmText}
            closeText={prompt?.closeText}
        />
    );
}

// The combination of react-oidc and our routes is somehow throwing an error
// saying that we are not in a function component and trying to declare hooks.
// Wrapping in one more functional component appears to fix it.
const WrappedInstallabilityController = () => <InstallabilityController />;
export default WrappedInstallabilityController;
