/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useEffect, useRef } from 'react';
import IconBackToTop from 'src/components/Icons/IconBackToTop';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { motion } from 'framer-motion';
import { Heading } from 'src/components/UI';
import CouponList from './CouponList';
import styles from './styles.module.scss';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} className={styles.tablePanelBox}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `offer-tab-${index}`,
        'aria-controls': `offer-tabpanel-${index}`,
    };
}

function CouponTab(props: any) {
    const {
        available,
        saved,
        redeemed,
        offerActivate,
        isCouponsFetchingError,
        showBackToTop,
        setShowBackToTop,
    } = props;
    const offerRef = useRef<any>(null);
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
        setValue(newValue);
    };
    const handleRedirection = () => {
        setValue(0);
    };

    const executeScroll = () => {
        setShowBackToTop(false)
    };

    return (
        <div ref={offerRef}>
            {showBackToTop ? (
                <motion.div
                    className={styles.topBtn}
                    initial={{ top: 0, opacity: 0 }}
                    animate={{ top: '4.5rem', opacity: 1 }}
                    transition={{ duration: 0.5 }}
                >
                    <div onClick={executeScroll}>
                        <IconBackToTop />
                    </div>
                </motion.div>
            ) : null}
            <Box sx={{ borderBottom: 4, borderColor: 'divider' }}>
                <Tabs
                    className={styles.tabs}
                    value={value}
                    onChange={handleChange}
                    aria-label="offer tab"
                >
                    <Tab
                        className={styles.tab}
                        label={`AVAILABLE ${
                            available?.length ? `(${available?.length})` : ''
                        }`}
                        {...a11yProps(0)}
                    />
                    <Tab
                        className={styles.tab}
                        label={`SAVED ${
                            saved?.length ? `(${saved?.length})` : ''
                        }`}
                        {...a11yProps(1)}
                    />
                    <Tab
                        className={styles.tab}
                        label={`REDEEMED ${
                            redeemed?.length ? `(${redeemed?.length})` : ''
                        }`}
                        {...a11yProps(2)}
                    />
                </Tabs>
                <>
                    <TabPanel value={value} index={0}>
                        <CouponList
                            tabName="available"
                            offers={available}
                            offerActivate={(data: any) => offerActivate(data)}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {isCouponsFetchingError ? (
                            <div className={styles.noCouponsContainer}>
                                <Heading
                                    type="h3"
                                    size={18}
                                    weight="bold"
                                    className={styles.headerSubTitle}
                                    textAlign="textCenter"
                                >
                                    You have no available coupons. Please check
                                    back later.
                                </Heading>
                            </div>
                        ) : (
                            <CouponList
                                tabName="saved"
                                offers={saved}
                                offerActivate={(data: any) =>
                                    offerActivate(data)
                                }
                                redirection={handleRedirection}
                            />
                        )}
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        {isCouponsFetchingError ? (
                            <div className={styles.noCouponsContainer}>
                                <Heading
                                    type="h3"
                                    size={18}
                                    weight="bold"
                                    className={styles.headerSubTitle}
                                    textAlign="textCenter"
                                >
                                    You have no available coupons. Please check
                                    back later.
                                </Heading>
                            </div>
                        ) : (
                            <CouponList
                                tabName="redeemed"
                                offers={redeemed}
                                offerActivate={(data: any) =>
                                    offerActivate(data)
                                }
                                redirection={handleRedirection}
                            />
                        )}
                    </TabPanel>
                </>
            </Box>
        </div>
    );
}
export default CouponTab;
