import React, { useEffect, useState } from 'react';
import { SwipeableDrawer } from '@material-ui/core';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import AccountLookupScreen from '../AccountLookupScreen';
import LoginScreen from '../LoginScreen';
import CreateAccountScreen from '../CreateAccountScreen';
import ForgotPassword from '../../../components/login-flow/forgot-password';
import PasswordResetEmail from '../../../components/login-flow/pwd-reset-email';
import styles from '../styles.module.scss';

function GuestLoginDrawer() {
    const [showAccountLookupScreen, setShowAccountLookupScreen] =
        useState(true);
    const [showLoginScreen, setShowLoginScreen] = useState(false);
    const [showCreateScreen, setShowCreateScreen] = useState(false);
    const [showForgotPasswordScreen, setShowForgotPasswordScreen] =
        useState(false);
    const [showPasswordResetEmailScreen, setShowPasswordResetEmailScreen] =
        useState(false);
    const { guestModal, setGuestModal } = useUserUpdateContext();

    const [nextScreen, setNextScreen] = useState('');

    const handleDrawerClose = () => {
        setGuestModal({
            ...guestModal,
            open: false,
        });
    };

    useEffect(() => {
        setShowLoginScreen(false);
        setShowAccountLookupScreen(false);
        setShowForgotPasswordScreen(false);
        setShowPasswordResetEmailScreen(false);
        setShowCreateScreen(false);

        switch (nextScreen) {
            case '/login':
                setShowLoginScreen(true);
                break;
            case '/forgot-pwd':
                setShowForgotPasswordScreen(true);
                break;
            case '/pwd-reset-email':
                setShowPasswordResetEmailScreen(true);
                break;
            case '/lookup':
                setShowAccountLookupScreen(true);
                break;
            case '/create':
                setShowCreateScreen(true);
                break;
            case 'closeInModal':
                handleDrawerClose();
                break;
            default:
                setShowLoginScreen(true);;
        }
    }, [nextScreen]);

    return (
        <>
            {guestModal.open && (
                <SwipeableDrawer
                    anchor="bottom"
                    open={guestModal.open}
                    onClose={handleDrawerClose}
                    onOpen={() => setGuestModal({ ...guestModal })}
                    className={styles.swipDrawer}
                >
                    <div className={styles.dragger} />
                    {showAccountLookupScreen && (
                        <AccountLookupScreen
                            showInModal
                            setNextScreen={setNextScreen}
                        />
                    )}
                    {showLoginScreen && (
                        <LoginScreen
                            showInModal
                            setNextScreen={setNextScreen}
                            postLoginBehavior={guestModal.closeBehavior}
                        />
                    )}
                    {showForgotPasswordScreen && (
                        <ForgotPassword
                            showInModal
                            setNextScreen={setNextScreen}
                        />
                    )}
                    {showPasswordResetEmailScreen && (
                        <PasswordResetEmail
                            showInModal
                            setNextScreen={setNextScreen}
                        />
                    )}
                    {showCreateScreen && (
                        <CreateAccountScreen
                            showInModal
                            setNextScreen={setNextScreen}
                            postLoginBehavior={guestModal.closeBehavior}
                        />
                    )}
                </SwipeableDrawer>
            )}
        </>
    );
}
export default GuestLoginDrawer;
