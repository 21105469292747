import React, { useEffect, useState, useLayoutEffect } from 'react';
import { ThemeProvider, CssBaseline, ButtonBase } from '@material-ui/core';
import {
    FirebaseAppProvider,
    useFirebaseApp,
    preloadAnalytics,
    preloadRemoteConfig,
} from 'reactfire';
import { QueryClientProvider, QueryClient } from 'react-query';
import { makeAuthenticator, makeUserManager, Callback } from 'react-oidc';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { IntlProvider } from 'react-intl';
import { Redirect, useLocation } from 'react-router';
// import { LoadScript } from '@react-google-maps/api';
import Log from 'src/lib/logging';
import MainRouter from 'src/routers/MainRouter';
import TokenHandlerLoadingScreen from 'src/screens/token-handler-loading-screen';
import userManagerConfig from 'src/auth-config';
import { en } from 'src/locales/en';
import theme from 'src/theme';
import useRouter from 'src/hooks/useRouter';
import UserUpdateContext, {
    useUserUpdateContext,
    useUserUpdateContextState,
} from 'src/lib/contexts/user-update-context';

import ScreenOrderContext, {
    useScreenOrderContextState,
} from 'src/lib/contexts/screen-order-context';
import FirebaseMessagingContext, {
    useFirebaseMessagingContextState,
} from 'src/lib/contexts/firebase-messaging-context';
import { User } from 'oidc-client';
import {
    getItemFromLocalStorage,
    removeFromLocalStorage,
    LOCAL_STORAGE_KEYS,
} from 'src/hooks/useLocalStorage';
import useUserInfo from 'src/hooks/useUserInfo';
import useLocalUserInfo from 'src/hooks/useLocalUserInfo';
import {
    SetSomniAppCookies,
    clearInstallPrompt,
    removeSomniAppCookies,
} from 'src/lib/utils/app-utils';
import firebaseConfig from 'src/lib/firebase/config';
import ROUTES, { DEEPLINK_ROUTES } from 'src/lib/routes';
// import FacebookPixelScript from 'src/components/facebook-pixel-script';
import useAdobeAnalytics from 'src/hooks/use-adobe-analytics';
import useFullStoryEvents from 'src/hooks/useFullStoryEvents';
import Cookies from 'js-cookie';
import InstallabilityController from 'src/controllers/installability-controller';
import platform from 'platform-detect';
import { deviceType, MobileView, isMobile as mobileDevice } from 'react-device-detect';
import OptimalDeviceController from 'src/controllers/optimal-device-controller';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
} from '@material-ui/core';
import QRCode from 'react-qr-code';
import CommonDialog from 'src/components/common-dialog';
import LoginRouter from './routers/LoginRouter';
import {
    COACH_SCREENS_ROUTES,
    LOGIN_ROUTES,
    baseRoute,
    REWARDS_ENROLLMENT_ROUTES,
    APP_PRE_LOGIN_ROUTES,
} from './routers/routes';
import CartCountContext, {
    useCartCountContextState,
} from './lib/contexts/cart-count-context';
import { StickyNotificationContextProvider } from './hooks/useStickyNotification';
import LoadSession, { loadSession } from './lib/api-client/LoadSession';
import {
    SessionManager,
    getLoginDetails,
} from './lib/api-client/sessionManager';

import ShippingInfoContext, {
    useShippingInfoContextState,
} from './lib/contexts/shipping-info-context';
import PageViewHandler from './hooks/usePageViewHandler';
import TabsUIStateContext, {
    useTabsUIStateContextState,
} from './lib/contexts/tabs-ui-state-context';
import { eventMetricsMap, useEventsReporter, useStoreData } from './hooks';
import LoadingButton from './components/loading-button';
import { Body, Button } from './components/UI';
import { openInNewTab } from './lib/utils/nav-utils';
import queryClient from './services/queryClient';
import { guestMode } from './lib/utils/app-utils';
import LoadingOverlay from './components/loading-overlay';
import NoInternet from './components/NoInternet';
import { getLoyaltyAccountDetails } from './services/easyRewardsServices';
import StoreModeNotificationHeader from './components/store-mode-notification-header';

const userManager = makeUserManager(userManagerConfig);
userManager.events.addUserLoaded((u: User) => {
    Log.userManager('user loaded');
    Log.userManager(u);
    const lastUser = getItemFromLocalStorage(
        LOCAL_STORAGE_KEYS.USER_INFO,
        {} as User
    );
    if (
        // mismatched email means we're logging in as someone else
        (lastUser?.email && lastUser?.email !== u?.profile?.email) ||
        u?.access_token === null // null access token means we've logged out
    ) {
        Log.userManager('clearing user from local storage');
        removeFromLocalStorage(LOCAL_STORAGE_KEYS.USER_INFO);
    }
});

type CallbackState = {
    type: 'error' | 'success';
    email?: string;
    error?: string;
};

const disableContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    const evt = event.target as HTMLElement;
    if (evt?.tagName === 'IMG') {
        event.preventDefault();
    }
};

function App() {
    localStorage.debug = process.env.REACT_APP_DEBUG;
    const userUpdateContextState = useUserUpdateContextState();
    const tabsUIStateContext = useTabsUIStateContextState();

    const screenOrderContextState = useScreenOrderContextState();
    const firebaseMessagingContextState = useFirebaseMessagingContextState();
    const cartCountContextState = useCartCountContextState();
    const shippingInfoContextState = useShippingInfoContextState();
    const firebaseApp = useFirebaseApp();
    const isGuestModeOn = guestMode();
    preloadAnalytics({ firebaseApp });
    preloadRemoteConfig({ firebaseApp });
    const { userInfo } = useLocalUserInfo();
    const { pathname, history } = useRouter();
    const router = useRouter();
    const { userInfo: userData } = useUserInfo();
    const { nearestStore } = useStoreData();
    const optimovePushNotificationData: any = JSON.parse(
        localStorage.getItem('optimovePushNotificationData') || '{}'
    );
    const isLoginRoute =
        APP_PRE_LOGIN_ROUTES?.includes(pathname) ||
        pathname === '/' ||
        pathname?.includes('/login/') ||
        pathname?.includes('/create/');

    console.log("deviceType", deviceType)


    const [isMobile, setIsMobile] = useState(mobileDevice);
    const [isMobileDevice, setIsMobileDevice] = useState(platform?.phone);

    const [isSetCookies, setIsSetCookies] = useState(true);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [openOnlineDialog, setOpenOnlineDialog] = useState(false);
    const { analyticsScreenCustomNames, analyticsCustomEvent, pageView } =
        useEventsReporter();

    const { updateUserOnPathChange, updateGenericLink } = useAdobeAnalytics();

    const { updateFSUserAgent, updateFsUserDetailsEvent } =
        useFullStoryEvents();

    const handleNavigation = () => {
        analyticsCustomEvent(eventMetricsMap.qrcode, {
            click_text: 'staples.com',
            element_location: 'app.staples.com',
        });
        openInNewTab(`${process.env.REACT_APP_DOT_COM_URL}`);
    };

    useEffect(() => {
        const deviceTypeMobile = deviceType === 'mobile';
        setIsMobile(mobileDevice || deviceTypeMobile || isMobileDevice)
    }, [mobileDevice, deviceType, isMobileDevice])

    const DoNotAlloWPopUp = () => {
        console.log('🚀 ~ App ~ Hi:', isMobile);

        return (
            <div className="mainContainerNotAllowApp">
                <Dialog
                    open={!(isMobile || isMobileDevice)}
                    aria-labelledby="error-dialog-title"
                    aria-describedby="error-dialog-description"
                    style={{
                        borderRadius: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <DialogTitle
                        disableTypography
                        id="error-dialog-title"
                        style={{}}
                    >
                        <Typography
                            style={{
                                fontSize: '1rem',
                                fontWeight: 700,
                                letterSpacing: '-0.5pt',
                                alignSelf: 'center',
                                marginBottom: '1rem',
                                padding: '0 1rem',
                                textAlign: 'center',
                            }}
                        >
                            Scan the QR code below to continue to the Staples
                            mobile app.
                        </Typography>
                    </DialogTitle>
                    <DialogContent style={{ margin: '0 auto' }}>
                        <QRCode value={'https://l.staplesconnect.com/app'} />
                    </DialogContent>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '15px 0px 25px',
                        }}
                    >
                        <Body weight="bold" color="textBlack" size={16}>
                            To view on a desktop, continue to &nbsp;
                        </Body>
                        <ButtonBase
                            onClick={handleNavigation}
                            className="gotItBtnAlign"
                        >
                            staples.com
                        </ButtonBase>
                    </div>
                </Dialog>
            </div>
        );
    };



    useEffect(() => {
        if (window !== undefined) {
            updateUserOnPathChange();
            updateGenericLink(`Footer:${pathname?.replace('/', '')}`);
        }
        pageView(router.location?.pathname);
    }, [pathname]);
    useEffect(() => {
        if (router.location?.pathname && !sessionStorage.getItem('route')) {
            let route;
            if (
                Object.prototype.hasOwnProperty.call(
                    DEEPLINK_ROUTES,
                    router.location.pathname
                )
            ) {
                console.log("🚀 ~ useEffect ~ router.location.pathname:", router.location.pathname, router.location)

                if (router.location?.pathname === '/home/online') {
                    route = '/home/online'
                } else if (router.location?.pathname === '/home/retail') {
                    route = '/home/retail'
                } else {
                    route = router.location.search
                        ? `${DEEPLINK_ROUTES[
                        router.location.pathname as keyof typeof DEEPLINK_ROUTES
                        ]
                        }${router.location.search}`
                        : DEEPLINK_ROUTES[
                        router.location.pathname as keyof typeof DEEPLINK_ROUTES
                        ];
                }

                if (router.location?.pathname === '/activateOffer') {
                    localStorage.setItem("isActivateOffer", 'false');
                }
                if (router.location?.pathname === '/viewcoupon') {
                    localStorage.setItem("viewcoupon", 'true');
                }

            } else if (router.location?.pathname.includes('/coupons/')) {
                route = router.location.search
                    ? `${router.location?.pathname}${router.location.search}`
                    : router.location.pathname;
            }
            if (optimovePushNotificationData?.url) {
                route = ROUTES.shop;
            }
            if (route) {
                sessionStorage.setItem('route', route);
            }
        }
    }, []);

    useEffect(() => {
        console.log('sessionManager', { pathname, sessionActive: SessionManager.isLoggedIn });
        if (!isGuestModeOn &&
            !SessionManager?.isLoggedIn &&
            !Object.values(LOGIN_ROUTES).includes(pathname) &&
            !pathname.includes('/login/') &&
            !pathname.includes('/create/') &&
            pathname !== '/'
        ) {
            history.push(COACH_SCREENS_ROUTES.initial);
        } else if (!isGuestModeOn &&
            SessionManager?.isLoggedIn &&
            (Object.values(LOGIN_ROUTES).includes(pathname) ||
                pathname.includes('/login/') ||
                pathname.includes('/create/') ||
                pathname === '/')
        ) {
            history.push(COACH_SCREENS_ROUTES.location);
        }

        analyticsCustomEvent(eventMetricsMap.user_info, {
            user_info: userInfo,
            route: pathname,
        });
    }, [userInfo, pathname]);

    useEffect(() => {
        if (userData?.rewardsNumber) {
            analyticsScreenCustomNames({
                rewards_number: userData?.rewardsNumber,
            });
        }
    }, [userData?.rewardsNumber]);

    const refresSession = async (e: any) => {
        e.preventDefault();
        if (document.visibilityState === 'visible') {
            SetSomniAppCookies();
        } else if (document.visibilityState === 'hidden') {
            removeSomniAppCookies();
        }
    };

    useEffect(() => {
        const deviceTypeMobile = deviceType === 'mobile';
        setIsMobile(mobileDevice || deviceTypeMobile)
        platform.on('phone', (phone: any) => setIsMobileDevice(phone));
        SetSomniAppCookies();
        updateFSUserAgent();
        Cookies.set(
            'OcpFeedbackSurvey',
            `1`,
            {
                domain: process.env.REACT_APP_Domain,
                expires: 54 * 60 * 60 * 24 * 7,
            }
        );
        Cookies.set(
            'slp',
            `${process.env.REACT_APP_LOYALTY_APP_SLP_COOKIES_VALUE}`,
            {
                domain: process.env.REACT_APP_Domain,
                expires: 54 * 60 * 60 * 24 * 7,
            }
        );

        document.addEventListener('visibilitychange', refresSession);
        setIsSetCookies(false)

        return () => {
            document.removeEventListener('visibilitychange', refresSession);
        };
    }, []);

    useEffect(() => {
        if (document.visibilityState === 'visible') {
            SetSomniAppCookies();
        }
        if (document.visibilityState === 'hidden') {
            removeSomniAppCookies();
        }
    }, [document?.visibilityState]);

    useEffect(() => {
        if (userData) {
            updateFsUserDetailsEvent(userData);
        }
    }, [userData]);

    const updateNetwork = () => setIsOnline(navigator.onLine);

    const [rewardstiercode, setRewardstiercode] = useState('');
    getLoyaltyAccountDetails({
        config: {
            enabled: !!userData?.rewardsNumber,
            onSuccess: (res) => {
                setRewardstiercode(res?.accounts[0]?.rewardstiercode);
            },
        },
        rewardsNumber: userData?.rewardsNumber,
    });
    /* useEffect(() => {
        getLoyaltyAccountDetails
    
      return () => {
        second
      }
    }, [third]) */
    
    useEffect(() => {
        window.addEventListener('online', updateNetwork);
        window.addEventListener('offline', updateNetwork);
        if (!isOnline) {
            setOpenOnlineDialog(true)
        } else {
            setOpenOnlineDialog(false)
        }
        return () => {
            window.removeEventListener('online', updateNetwork);
            window.removeEventListener('offline', updateNetwork);
        };
    }, [isOnline]);
    const handleBtnClick = () => {
        setOpenOnlineDialog(false);
        setTimeout(() => {
            if (!isOnline) {
                setOpenOnlineDialog(true)
            }
        }, 1000)
    }

    if (openOnlineDialog) {
        return (
            <NoInternet rewardstiercode={rewardstiercode} btnClick={() => handleBtnClick()} />
        )
    }

    if (!(isMobile || isMobileDevice)) {
        return <DoNotAlloWPopUp />;
    }
    if (isSetCookies) {
        return (
            <LoadingOverlay
                variant="wordmark"
                theme="transparent-light"
                open={isSetCookies}
            />
        );
    }
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <UserUpdateContext.Provider value={userUpdateContextState}>
                <CartCountContext.Provider value={cartCountContextState}>
                    <ScreenOrderContext.Provider
                        value={screenOrderContextState}
                    >
                        <FirebaseMessagingContext.Provider
                            value={firebaseMessagingContextState}
                        >
                            <InstallabilityController />
                            <IntlProvider locale="en" messages={{ en }}>
                                <TabsUIStateContext.Provider value={tabsUIStateContext}>
                                    <StickyNotificationContextProvider>
                                        <ShippingInfoContext.Provider
                                            value={shippingInfoContextState}
                                        >
                                            <TabsUIStateContext.Provider
                                                value={tabsUIStateContext}
                                            >
                                                {nearestStore?.length > 0 && 
                                                <StoreModeNotificationHeader/>} 
                                                {isLoginRoute ? (
                                                    <LoginRouter />
                                                ) : (
                                                    <MainRouter />
                                                )}
                                            </TabsUIStateContext.Provider>
                                        </ShippingInfoContext.Provider>
                                    </StickyNotificationContextProvider>
                                </TabsUIStateContext.Provider>
                            </IntlProvider>
                        </FirebaseMessagingContext.Provider>
                    </ScreenOrderContext.Provider>
                </CartCountContext.Provider>
            </UserUpdateContext.Provider>
        </ThemeProvider>
        // </LoadScript>
    );
}

loadSession();
const AppWithFirebase = () => (
    <div onContextMenu={disableContextMenu}>
        <QueryClientProvider client={queryClient}>
            <FirebaseAppProvider firebaseConfig={firebaseConfig}>
                <LoadSession />
                <Router basename={baseRoute}>
                    <App />
                </Router>
            </FirebaseAppProvider>
        </QueryClientProvider>
    </div>
);
export default AppWithFirebase;
