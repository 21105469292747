/* eslint-disable jsx-a11y/aria-role */
import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    makeStyles,
    Typography,
} from '@material-ui/core';
import ManageFavoritesCoachScreen from 'src/screens/EasyRewards/ManageFavorites/ManageFavoritesCoachScreen';
import { Body, Heading, Button } from 'src/components/UI';
import IconDown from 'src/components/Icons/IconDown';
import { eventMetricsMap, useEventsReporter } from 'src/hooks';
import IconDownCoachMark from 'src/components/Icons/IconDownCoachMark';
import styles from './styles.module.scss';
import '../../../assets/animate/animate.css';

const CategoriesScreen = ({ handleSkipAll, handleNext, flagForFavoriteList, setFlagForFavoriteList }: any) => {
    const [open, setOpen] = useState(true);
    const animationClass =
        'animate__animated animate__fadeIn animate__delay-0.8s';

    const { analyticsCustomEvent } = useEventsReporter();
    const handleNextScreen = () => {
        handleNext(5)
        analyticsCustomEvent(eventMetricsMap.coachmarks, {
            click_text: `next`,
        });
    }
    const handleSkip = () => {
        handleSkipAll();
        analyticsCustomEvent(eventMetricsMap.coachmarks, {
            click_text: `skip all`,
        });
    }
    return (
        <>
            <div className={`${styles.wrapperConatiner} ${animationClass}`}>
                <ManageFavoritesCoachScreen
                    setFlagForFavoriteList={setFlagForFavoriteList}
                />

                <div className={styles.mainConatiner}>
                    <div className={styles.boxConatiner}>
                        <span className={styles.arrowIcon}>
                            <img
                                src={require('../../Icons/right-arrow-icon.svg')}
                                alt="img"
                            />
                        </span>
                        <Body
                            size={12}
                            className={styles.alignCount}
                            weight="light"
                            lineHeight={16}
                            ariaLabel='Four out of five'
                        >
                            4/5
                        </Body>
                        <Heading
                            type="h2"
                            weight="bold"
                            size={26}
                            className={styles.alignheaderDsc}
                            ariaLabel='Bonus points categories'
                        >
                            Bonus points categories
                        </Heading>
                        <Body
                            size={16}
                            weight="light"
                            className={styles.txtContainer}
                            ariaLabel={flagForFavoriteList
                                ? 'Earn double or more points on what you buy most. Select your top three to get started.'
                                : ' Earn 2x or more points on what you buy most.'}
                        >
                            {flagForFavoriteList
                                ? 'Earn double or more points on what you buy most. Select your top three to get started.'
                                : ' Earn 2x or more points on what you buy most.'}
                        </Body>
                        <div className={styles.btnConatiner}>
                            <Button
                                variant="text"
                                onClick={handleSkip}
                                className={styles.Link}
                                fontWeight="bold"
                                ariaLabel='Skip all'
                            >
                                Skip all
                            </Button>
                            <Button
                                onClick={handleNextScreen}
                                bgColor="bgRed"
                                className={styles.NextBtn}
                                ariaLabel='Next'
                                role='Button'
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.alignImg}>
                <img
                    src={
                        'https://de-app.staplesconnect.com/images/CoachMarks/cm_image_associate_bpc_sm.png'
                    }
                    alt="img"
                    className={`${styles.alignImg} animationClass`}
                />
            </div>
        </>
    );
};

export default CategoriesScreen;
