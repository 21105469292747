import React from 'react';
import { useQuery } from 'react-query';
import { SwipeableDrawer, Typography } from '@material-ui/core';
import useUserInfo from 'src/hooks/useUserInfo';
import useCardCanvas from 'src/hooks/useCardCanvas';
import { getCustomerPriceTypes } from 'src/lib/api-client/wallet-activities';
import { Body } from 'src/components/UI';
import { NO_REFETCH } from 'src/lib/utils/react-query-utils';
import { Player } from '@lottiefiles/react-lottie-player';
import { IconEasyRewardsHorizontal } from 'src/components/Icons';
import styles from './styles.module.scss';

interface UserRewardsDigitalCardDrawerProps {
    open: boolean;
    toggleOpen: (value: boolean) => void;
    rewardstiercode: string;
    useDrawer?: boolean; // New prop to control drawer usage
}

function UserRewardsDigitalCardDrawer({
    open,
    toggleOpen,
    rewardstiercode,
    useDrawer = true, // Default to true if not provided
}: UserRewardsDigitalCardDrawerProps) {
    const { userInfo } = useUserInfo();
    const { canvasRef } = useCardCanvas(userInfo?.rewardsNumber);
    const { data: customerPriceTypeData } = useQuery(
        ['CustomerPriceTypes', userInfo?.rewardsNumber],
        () => getCustomerPriceTypes(userInfo?.rewardsNumber),
        {
            ...NO_REFETCH,
            enabled: !!userInfo?.rewardsNumber,
        }
    );

    const cardContent = (
        <div className={styles.contentWrapper}>
            {useDrawer ? <div className={styles.indicator} /> : null}
            <Body className={styles.title}>
                <span style={{ textAlign: 'center', margin: "13px 0px -9px 0px" }}>
                    <IconEasyRewardsHorizontal
                        width={110}
                        height={25}
                        fillColor="fillRed"
                    />
                </span>
                <b className={styles.rewardsNumber}>
                    {userInfo?.rewardsNumber}
                </b>
            </Body>
            <canvas ref={canvasRef} className={styles.renderedCardNumber} />

            <div className={styles.container}>
                <Body className={styles.memberName} size={26} weight='bold' margin='30px 0px 15px 0'>
                    {userInfo?.firstName} {userInfo?.lastName}
                </Body>
                {customerPriceTypeData?.data?.customerPriceType === 'Chamber' ? (
                    <Body size={16} weight='bold' margin="0 0 38px 0" className={styles.memberTypeContainer}>
                        CHAMBER MEMBER
                    </Body>
                ) : (
                    <>
                        {userInfo?.rewardsMemberType === 'Associate' || rewardstiercode === 'ARW' ? (
                            <Body size={16} weight='bold' margin="0 0 38px 0" className={styles.memberTypeContainer}
                                ariaLabel="You are a VALUED ASSOCIATE"
                            >
                                <img
                                    src={require("../../../assets/icons/memberStaplesLogo.svg")}
                                    alt="member icon" aria-hidden="true"
                                    className={styles.memberNameIcon}
                                />
                                VALUED ASSOCIATE
                            </Body>
                        ) : 
                        <Body size={16} weight='bold' margin="0 0 38px 0" 
                        className={styles.memberTypeContainer} ariaLabel="You are a MEMBER" >
                            MEMBER
                        </Body>}
                    </>
                )}
                {useDrawer ? (
                    <Body className={styles.subtitle} size={13} margin='12px 0 25px 0'>
                        <p aria-label='How do I use my card?' style={{margin: '0px'}}>How do I use my card?</p>
                        <a
                            tabIndex={0}
                            aria-label="Learn more link"
                            href="https://www.staples.com/grs/rewards/programoverview"
                            className={styles.learnMore}
                        >
                            Learn more
                        </a>
                    </Body>
                ) : null}

                <Player autoplay loop style={{ marginRight: '-4px' }}
                    className={styles.cardCornerPill} src={require('../../../assets/lotties/rewards-enrollment/sm_digital_card_corner_pills.json')} />
            </div>
            {userInfo?.rewardsMemberType === 'Associate' && (
                <Body className={styles.benefitWrapper}>
                    <a
                        href="https://associateconnection.staples.com/psp/psext/EXTRANET2/ASSOCIATE/c/STP_EXTR_LINK_MENU.STP_RWD_ASSOC_RWRD.GBL?Page=STP_RWD_API_EMPLOY&Action=U"
                        className={styles.benefitText}
                    >
                        View my associate benefits
                    </a>
                </Body>
            )}
        </div>
    );

    return useDrawer ? (
        <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={() => toggleOpen(false)}
            onOpen={() => toggleOpen(true)}
        >
            {cardContent}
        </SwipeableDrawer>
    ) : (
        <div>{cardContent}</div>
    );
}

export default UserRewardsDigitalCardDrawer;
