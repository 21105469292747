/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useState } from 'react';
import {
    ButtonBase,
    makeStyles,
    Typography,
    ListItem,
    ListItemText,
    List,
    Drawer,
} from '@material-ui/core';
import COLORS from 'src/lib/colors';
import ROUTES from 'src/lib/routes';
import useRouter from 'src/hooks/useRouter';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import LoadingButton from 'src/components/loading-button';
import { SessionManager } from 'src/lib/api-client/sessionManager';
import { hideEmail } from 'src/lib/utils/hide-email';
import { LOGIN_ROUTES } from 'src/routers/routes';
import { useUserState } from 'src/hooks';
import AppLink from '../link';
import { sendEmailToUser } from './pwd-reset';
import LoadingOverlay from '../loading-overlay';
import FullWidthCard from '../cards/full-width-card';
import NavTop from '../nav-top';
import CommonDialog from '../common-dialog';
import { Body, Heading, Button } from '../UI';
import styles from './styles.module.scss';


const useStyles = makeStyles({
    screenload: {
        flexGrow: 1,
        background: COLORS.whiteSmoke,
        '& #pwd-screen': {
            display: 'flex',
            alignItems: 'baseline',
            borderRadius: 0,
            height: '17px',
        },
    },
    container: {
        minHeight: '16.5rem',
        borderRadius: '15px',
        padding: '24px',
        margin: '-5rem 1.5rem 0px',
    },
    headerSubTitleFound: {
        color: COLORS.textBlack,
        display: 'flex',
    },
    headerTitle: {
        color: COLORS.textBlack,
        lineHeight: '32px',
        marginBottom: '40px',
    },
    contentContainer: {
        width: '100%',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
    },
    backToLogin: {
        fontSize: '14px',
        margin: '3rem 0 1rem 0',
        color: COLORS.brandCyan,
    },
    ctaBtn: {
        height: '50px',
        borderRadius: '100px',
        marginBottom: '20px',
        background: COLORS.brandRed,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.brandRed,
        },
        '&:active': {
            backgroundColor: COLORS.brandRed,
        },
        fontWeight: 500,
    },
    helperText: {
        color: '#585858',
        fontSize: '14px',
        background: '#fff',
        borderRadius: '15px 15px 0 0',
        textAlign: 'center',
        fontWeight: 'bold',
        marginTop: '15px',
        padding: '15px 0 15px 0',
        borderBottom: '1px solid #d9d9d6',
    },
    emailLink: {
        width: '100%',
        color: COLORS.brandCyan,
        '&:hover': {
            textDecoration: 'none',
        },
    },
});

const PasswordResetEmail = ({ showInModal, setNextScreen }: any) => {
    const classes = useStyles();
    const { history, query, push } = useRouter();
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [userState, setUserState] = useUserState();

    const { analyticsCustomEvent } = useEventsReporter();
    const sendEmail = async () => {
        setIsLoading(true);
        analyticsCustomEvent(eventMetricsMap.password_reset, {
            click_text: 'Re-send',
            element_location: 'password reset request',
        });
        const res = await sendEmailToUser(SessionManager.userName);
        if (res?.message?.message === 'received user details') {
            setShowConfirmation(true);
        }
        setIsLoading(false);
    };
    const [drawerState, setDrawerState] = useState({
        bottom: false,
    });
    const toggleDrawer = (anchor: any, open: boolean) => () => {
        setDrawerState({ ...drawerState, [anchor]: open });
    };
    const backToLogin = () => {
        if (userState?.email) {
            setUserState({
                ...userState,
                email: userState?.email,
            });
            if (!showInModal) {
                push(LOGIN_ROUTES.login);
            }
            else {
                setNextScreen(LOGIN_ROUTES.login)
            }

        } else if (!showInModal) {
            push(LOGIN_ROUTES.login);
        }
        else {
            setNextScreen(LOGIN_ROUTES.login)
        }
        analyticsCustomEvent(eventMetricsMap.password_reset, {
            click_text: 'Back to Sign in',
            element_location: 'password reset request',
        });
    };
    const openEmail = () => {
        setDrawerState({ ...drawerState, bottom: true });
    };
    const goBack = () => {
        if (SessionManager.isForgotPassword) {
            history.push(ROUTES.forgotPassword);
        } else {
            history.push(ROUTES.passwordReset);
        }
    };

    const emailList = [
        {
            displayText: 'Gmail',
            target: 'https://gmail.com',
        },
        {
            displayText: 'Yahoo',
            target: 'https://yahoomail.com',
        },
        {
            displayText: 'Outlook',
            target: 'https://outlook.com',
        },
        {
            displayText: 'iCloud',
            target: 'https://icloud.com',
        },
        {
            displayText: 'Aol',
            target: 'https://login.aol.com',
        },
    ];
    const list = (anchor: any) => (
        <div
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Typography className={classes.helperText}>Choose email</Typography>

            <List style={{ background: '#fff', borderRadius: '0 0 15px 15px' }}>
                {emailList.map(({ displayText, target }, index) => (
                    <ListItem
                        button
                        key={displayText}
                        style={{
                            borderBottom:
                                index === emailList.length - 1
                                    ? '0px none'
                                    : '1px solid #d9d9d6',
                            paddingTop: index === 0 ? '0px' : '',
                            paddingBottom:
                                index === emailList.length - 1 ? '0px' : '',
                            textAlign: 'center',
                        }}
                    >
                        <AppLink
                            id="email-button"
                            href={target}
                            className={classes.emailLink}
                            target="_blank"
                        >
                            <ListItemText primary={displayText} />
                        </AppLink>
                    </ListItem>
                ))}
            </List>
            <List
                style={{
                    background: '#fff',
                    borderRadius: '15px',
                    marginTop: '25px',
                    marginBottom: '15px',
                }}
            >
                <ListItem
                    style={{
                        textAlign: 'center',
                        paddingBottom: '0px',
                        paddingTop: '0px',
                    }}
                >
                    <AppLink
                        id="cancel-button"
                        href="#"
                        className={classes.emailLink}
                    >
                        <ListItemText primary="Cancel" />
                    </AppLink>
                </ListItem>
            </List>
        </div>
    );

    return (
        <div style={{ height: showInModal ? '85vh' : '100vh' }} className={styles.container}>
            {isLoading ? (
                <LoadingOverlay
                    variant="wordmark"
                    theme="transparent-light"
                    open={isLoading}
                />
            ) : null}

            <ButtonBase
                tabIndex={7}
                role='button'
                aria-label='Go back'
                onClick={() => goBack()}
            >
                <img
                    className={styles.backIcon}
                    src={require('../../assets/icons/iconBackArrow.svg')}
                    alt="staples connect logo"
                />
            </ButtonBase>

            <Heading type="h2" weight='bold' size={26} lineHeight={32} className={classes.headerTitle}>
                Check your inbox
            </Heading>

            <div
                className={classes.contentContainer}
                id="content-container"
            >
                <Body
                    size={16}
                    weight='light'
                    lineHeight={20}
                >
                    If you have an account, you will receive  instructions to reset your password.
                </Body>

                <LoadingButton
                    tabIndex={5}
                    aria-selected
                    aria-label="Open email"
                    fullWidth
                    id="step2-next-button"
                    variant="contained"
                    classes={{
                        root: classes.ctaBtn,
                    }}
                    type="submit"
                    loading={false}
                    onClick={openEmail}
                    style={{ marginTop: '20px' }}
                >
                    Open your email
                </LoadingButton>
                <Body
                    tabIndex={3}
                    aria-label="Didn’t receive an email?"
                    className={classes.headerSubTitleFound}
                    size={14}
                    lineHeight={18}
                    weight='light'
                >
                    Didn’t receive an email?&nbsp;
                    <Button
                        tabIndex={4}
                        aria-label="Resend"
                        aria-selected
                        onClick={sendEmail}
                        variant="text"
                        fontSize={14}
                        fontWeight='normal'
                        className={styles.linkReSendalign}
                    >
                        Resend
                    </Button>
                </Body>
            </div>
            <div id="drawer-container" style={{ position: 'relative' }}>
                <Drawer
                    PaperProps={{
                        style: {
                            position: 'absolute',
                            margin: '0 24px',
                            borderRadius: '15px',
                            background: 'transparent',
                            boxShadow: 'none',
                        },
                    }}
                    ModalProps={{
                        container:
                            document.getElementById('drawer-container'),
                        keepMounted: true,
                    }}
                    anchor="bottom"
                    open={drawerState.bottom}
                    onClose={toggleDrawer('bottom', false)}
                >
                    {list('bottom')}
                </Drawer>
            </div>
            {showConfirmation ? (
                <CommonDialog
                    open={showConfirmation}
                    onClose={() => setShowConfirmation(false)}
                    message={
                        'Please check your email for the password reset link'
                    }
                    title={'Success'}
                />
            ) : null}
        </div>
    );
};
export default PasswordResetEmail;